import React, { useState } from 'react'
import {
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import formatCurrency from 'modules/common/utils/currency-formatter'
import classNames from 'classnames'
import { splitEvery } from 'ramda'

const PER_PAGE = 10

function InstallmentTable({ installments, onClose }) {
  const [page, setPage] = useState(1)

  const pages = splitEvery(PER_PAGE, installments)

  const getTacValue = ({ tac, dfi, mip }) => tac + dfi + mip

  return (
    <div className="position-relative">
      <Table className="align-items-center table-flush" striped size='sm'>
        <thead className="thead-light">
          <tr>
            <th scope="col">Parcela</th>
            <th scope="col">Juros</th>
            <th scope="col">Amortização</th>
            <th scope="col">Seguros + TAC</th>
            <th scope="col">Prestação</th>
            <th scope="col">Saldo Devedor</th>
          </tr>
        </thead>
        <tbody>
          {pages[page - 1].map(installment => (
            <tr key={installment.installmentNumber}>
              <td>{installment.installmentNumber}</td>
              <td>{formatCurrency(installment.interest, 'BRL')}</td>
              <td>{formatCurrency(installment.amortization, 'BRL')}</td>
              <td>{formatCurrency(getTacValue(installment), 'BRL')}</td>
              <td>{formatCurrency(installment.installment, 'BRL')}</td>
              <td>{formatCurrency(installment.balanceDue, 'BRL')}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="pagination justify-content-center mb-2">
        <PaginationItem
          className={classNames({ disabled: page === 1 })}
          disabled={page === 1}
        >
          <PaginationLink
            onClick={() => setPage(1)}
          >
            <i className="far fa-arrow-to-left"></i>
          </PaginationLink>
        </PaginationItem>
        <PaginationItem
          className={classNames({ disabled: page === 1 })}
          disabled={page === 1}
        >
          <PaginationLink
            onClick={() => setPage(page - 1)}
          >
            <i className="fas fa-angle-left" />
          </PaginationLink>
        </PaginationItem>

        <PaginationItem
          className={classNames({ disabled: page === pages.length })}
          disabled={page === pages.length}
        >
          <PaginationLink
            onClick={() => setPage(page + 1)}
          >
            <i className="fas fa-angle-right" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem
          className={classNames({ disabled: page === pages.length })}
          disabled={page === pages.length}
        >
          <PaginationLink
            onClick={() => setPage(pages.length)}
          >
            <i className="far fa-arrow-to-right"></i>
          </PaginationLink>
        </PaginationItem>
      </Pagination>

      <Button
        size="sm"
        color="primary"
        className="position-absolute bottom-4 right-3"
        onClick={onClose}
      >
        Voltar
      </Button>
    </div>
  )
}

export default InstallmentTable
