import React, { useEffect, useState } from 'react'
import {Button, Col, Form, FormFeedback, FormGroup, Input, Row} from 'reactstrap'
import {useFormik} from "formik";
import useAddressType from 'modules/common/hooks/use-address-type'
import mapIdNameToSelect from 'modules/common/utils/id-name-mapper'
import Select from 'modules/common/components/Select/Select'
import CepInput from 'modules/common/components/CepInput/CepInput'
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import homeAddressSchema from "modules/processes/validators/home-address";
import { useDetectChange } from 'modules/common/context';

export function HomeAddress({ residenceTimeUnits, onSubmit, customer }) {
  const { setChange } = useDetectChange();
  const { addressTypes } = useAddressType()
  const [address, setAddress] = useState()

  const formik = useFormik({
    initialValues: {
      addressTypeId: String(customer?.address?.addressType || ''),
      street: customer?.address?.street,
      number: customer?.address?.number,
      complement: customer?.address?.complement,
      neighborhood: customer?.address?.neighborhood,
      city: customer?.address?.city,
      state: customer?.address?.state,
      zipCode: customer?.address?.zipCode,
      residenceTime: customer?.address?.residenceTime,
      residenceTimeUnit: customer?.address?.residenceTimeUnit,
      customerId: customer?.pkHash,
      addressId: customer?.address?.pkHash,
    },
    onSubmit,
    validationSchema: homeAddressSchema,
  });

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  useEffect(() => {
    if (address) {
      formik.setValues({
        ...formik.values,
        street: address?.street || '',
        state: address?.state || '',
        city: address?.city || '',
        neighborhood: address?.neighborhood || '',
      })
    }
  }, [customer, address]);

  const resetAddress = () => {
    setAddress(null)
    formik.setValues({
      ...formik.values,
      street: '',
      state: '',
      city: '',
      number: '',
      neighborhood: '',
      complement: '',
    })
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input readOnly hidden name="customerId" value={customer?.pkHash} />
      <Input readOnly hidden name="addressId" value={customer?.address?.pkHash} />

      <FormGroup>
        <label htmlFor="addressTypeId">Tipo de Moradia</label>
        <Select
          id="addressTypeId"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(addressTypes)}
          value={formik.values.addressTypeId}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'addressTypeId')}>
          {formik.errors.addressTypeId}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="zipCode">CEP</label>
        <CepInput
          id="zipCode"
          placeholder="12345678"
          name='zipCode'
          onChangeAddress={address => setAddress(address)}
          onError={resetAddress}
          value={formik.values.zipCode}
          onChange={e => e.target.value && formik.handleChange(e)}
          invalid={formikValidateInput(formik, 'zipCode')}
        />
        <FormFeedback>
          {formik.errors.zipCode}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="street">Rua</label>
        <Input
          id="street"
          placeholder="Digite aqui" 
          disabled={
            (address?.neighborhood) || (!address && customer?.address?.street)
          }
          defaultValue={formik.values.street}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'street')}
        />
        <FormFeedback>
          {formik.errors.street}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="number">Número</label>
        <Input
          id="number"
          placeholder="Digite aqui"
          name='number'
          defaultValue={formik.values.number}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'number')}
        />
        <FormFeedback>
          {formik.errors.number}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="complement">Complemento</label>
        <Input
          id="complement"
          placeholder="Digite aqui"
          name='complement'
          defaultValue={formik.values.complement}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'complement')}
        />
        <FormFeedback>
          {formik.errors.complement}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="neighborhood">Bairro</label>
        <Input
          id="neighborhood"
          placeholder="Digite aqui"
          disabled={
            (address?.neighborhood) || (!address && customer?.address?.neighborhood)
          }
          name='neighborhood'
          defaultValue={formik.values.neighborhood}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'neighborhood')}
        />
        <FormFeedback>
          {formik.errors.neighborhood}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="state">Estado</label>
        <Input
          id="state"
          name='state'
          defaultValue={formik.values.state}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'state')}
          disabled
        />
        <FormFeedback>
          {formik.errors.state}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="city">Cidade</label>
        <Input
          id="city"
          disabled
          name='city'
          defaultValue={formik.values.city}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'city')}
        />
        <FormFeedback>
          {formik.errors.city}
        </FormFeedback>
      </FormGroup>

      <FormGroup className="mb-5">
        <label htmlFor="residenceTime">Tempo neste endereço</label>

        <Row>
          <Col lg="8">
            <Input
              id="residenceTime"
              type="number"
              name='residenceTime'
              defaultValue={formik.values.residenceTime}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'residenceTime')}
            />
            <FormFeedback>
              {formik.errors.residenceTime}
            </FormFeedback>
          </Col>
          <Col lg="4">
            <Select
              id="residenceTimeUnit"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              data={mapIdNameToSelect(residenceTimeUnits)}
              defaultValue={formik.values.residenceTimeUnit}
              onChange={e => e.target.value && formik.handleChange(e)}
              name='residenceTimeUnit'
            />
            <FormFeedbackSelect invalid={formikValidateInput(formik, 'residenceTimeUnit')}>
              {formik.errors.residenceTimeUnit}
            </FormFeedbackSelect>
          </Col>
        </Row>
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Salvar e continuar</Button>
    </Form>
  )
}
