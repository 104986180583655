import React, { useEffect } from 'react'
import {Button, Form, FormFeedback, FormGroup, Input} from 'reactstrap'
import {useFormik} from "formik";

import CpfInput from 'modules/common/components/CpfInput/CpfInput'
import Select from 'modules/common/components/Select/Select'
import {registerProposalSchema} from "modules/processes/validators/register-proposal";
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import { useDetectChange } from 'modules/common/context';
import CurrencyInput from 'modules/common/components/CurrencyInput/CurrencyInput'

export function RegisterDirectLogin({ onSubmit, proposals, analysis }) {
  const { setChange } = useDetectChange();

  const formik = useFormik({
    initialValues: {
      productId: analysis?.proposalFollowed?.product?.id || '',
      name: analysis?.customer?.fullName || '',
      cpf: analysis?.customer?.cpf || '',
      mortgage: analysis?.mortgage || '',
    },
    onSubmit,
    validationSchema: registerProposalSchema,
  });

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <label htmlFor="productId">Produto e banco</label>
        <Select
          id="productId"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={proposals?.map(({ product: { id, name, bank } }) => ({ id, text: name + ' ' + bank }))}
          name="productId"
          value={formik.values.productId}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'productId')}>
          {formik.errors.productId}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="name">Nome completo do cliente</label>
        <Input
          id="name"
          placeholder="Digite aqui"
          name="name"
          value={formik.values.motherName}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'name')}
        />
        <FormFeedback>
          {formik.errors.motherName}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="cpf">CPF</label>
        <CpfInput
          id="cpf"
          placeholder="Digite aqui"
          name="cpf"
          onChange={formik.handleChange}
          value={formik.values.cpf}
          invalid={formikValidateInput(formik, 'cpf')}
        />
        <FormFeedback>
          {formik.errors.cpf}
        </FormFeedback>
      </FormGroup>

      <FormGroup>
        <label htmlFor="mortgage">Valor financiado</label>
        <CurrencyInput
          id="mortgage"
          placeholder="R$ digite aqui"
          name="mortgage"
          onChange={formik.handleChange}
          value={formik.values.mortgage}
          invalid={formikValidateInput(formik, 'mortgage')}
          required
        />
        <FormFeedback>
          {formik.errors.mortgage}
        </FormFeedback>
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Cadastrar processo</Button>
    </Form>
  )
}
