import { useHistory } from "react-router";

export const AnnouncementComponent = ({ announcement }) => {
  const { replace } = useHistory();
  const {dateadded} = announcement
  const dateFormat = `${dateadded.slice(8,10)}/${dateadded.slice(5,7)}/${dateadded.slice(0,4)}`
  return (
    <tr onClick={() => replace(`announcements/${announcement.id}`)} style={{cursor: "pointer"}}>
      <td>{announcement.name}</td>
      <td><span className="text-light">{dateFormat}</span></td>
    </tr>
  );
};
