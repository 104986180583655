import React, { useContext, useEffect, useState } from 'react';
import { zipObject } from "lodash";
import { useParams, useHistory } from 'react-router';
import Swal from 'sweetalert2'

import { Row, Col } from 'reactstrap';
import { AnalysisContext } from 'modules/processes/contexts/AnalysisContext';
import { loadProposalsDirectLogin } from 'modules/common/hooks/use-analyses-basic-data';
import { ListeSteps } from './client/ListeSteps';
import { RegisterDirectLogin } from './client/wizard/RegisterDirectLogin';


function DirectLogin({ analysis, onFinished }) {
  const { id } = useParams();
  const { replace } = useHistory();

  const currentWizard = 0;
  const setCurrentWizard = () => {};
  const finishedWizards = [];

  const { 
    submitAnalysisDirectLogin,
  } = useContext(AnalysisContext);

  const wizardSteps = [
    { 
      index: 0,
      title: 'Dados da proposta',
      component: (props) => <RegisterDirectLogin
        analysis={analysis}
        proposals={analysisBasicData.proposals}
        {...props}
      />,
      onSubmit: async (values, analysis, additionalData = {}) => {
        return await submitAnalysisDirectLogin(values, analysis, additionalData);
      },
      loadData: () => ({
        proposals: loadProposalsDirectLogin(),
      }),
    },
  ];

  const [analysisBasicData, setAnalysisBasicData] = useState({
    proposals: [],
  });

  const submitStep = async (event) => {
    event.preventDefault?.();
    const ret = await step.onSubmit?.(event, analysis);

    if (id  === "new") {
      Swal.fire({
        title: 'Sucesso!',
        html: `Proposta cadastrada com sucesso`,
        icon: 'success',
        focusConfirm: true,
        returnFocus: false,
      }).then(() => replace(`/direct/${ret}/process`));
    }
  };

  const step = wizardSteps[0];

  useEffect(() => {
    (async () => {
      const step = wizardSteps.find(step => step.index === currentWizard);
      if (step && step.loadData) {
        const data = step.loadData();
        const dataDone = await Promise.all(Object.values(data));

        setAnalysisBasicData(({
          ...analysisBasicData,
          ...zipObject(Object.keys(data), dataDone),
        }));
      }
    })();
  }, [currentWizard]);

  return (
    <section>
      <Row>
        <ListeSteps
          wizardSteps={wizardSteps}
          finishedWizards={finishedWizards}
          currentWizard={currentWizard}
          setCurrentWizard={setCurrentWizard}
        />
        <Col lg="4" md="12">
          {step && <step.component onSubmit={submitStep} />}
        </Col>
      </Row>
    </section>
  );
}

export default DirectLogin;
