import React, { useEffect, useState, useRef } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Notification, useNotification } from 'modules/common/hooks/use-notification';

import { loadConsultants } from 'modules/common/hooks/use-consultant';
import { ChangeConsultantModal } from 'modules/consultant/components/ChangeConsultant/ChangeConsultantModal';


const Consultant = ({}) => {
    const [ consultants, setConsultants ] = useState([]);
    const [ consultant, setConsultant ] = useState();
    const [ dropdownOpen, setDropdownOpen ] = useState({});
    const ref = useRef();
    const { notify } = useNotification({ref});

    const toggle = (i) => {
        setDropdownOpen((prevState) => {
            if (typeof prevState[i] === undefined) {
                prevState[i] = false;
            }
            prevState[i] = !prevState[i];
            return {...prevState};
        });
    };

    const changeConsultant = (consultant) => {
        setConsultant(consultant)
    };

    const onConfirm = () => {
        setConsultant(undefined);
        notify({type: 'success', title: '', message: 'Solicitação recebida com sucesso! Você pode acompanhar pelo menu Chamados.'})
    };

    const onReject = () => {
        setConsultant(undefined);
    };

    useEffect(() => {
        loadConsultants().then((data) => setConsultants(data.consultants));
    }, [loadConsultants]);
  
    return (
        <>
            <ChangeConsultantModal consultant={consultant} open={!!consultant} onConfirm={onConfirm} onReject={onReject} />
            <Notification innerRef={ref} />

            <Container fluid className="pt-5 consultants-wrapper">
                <h1 className="font-weight-bolder display-4 mb-4">Meus consultores</h1>
                <Row>
                    {consultants.map((consultant, i) => (
                        <Col
                            md="6"
                            lg="4"
                            xl="3"
                            className="d-flex align-items-stretch"
                            key={i}
                        >
                            <Card
                                className={"flex-fill"}
                            >
                                <CardBody>
                                    {consultant.allow_switch && (
                                        <div className='text-right w-100'>
                                            {consultant.social_url && (
                                                <a
                                                className="btn btn-linkedin btn-icon-only rounded-circle"
                                                color="twitter"
                                                href={consultant.social_url}
                                                target='_blank'
                                                >
                                                <i className="fab fa-linkedin" />
                                                </a>
                                            )}
                                            <Dropdown isOpen={dropdownOpen[i]} toggle={() => toggle(i)} direction='left'>
                                                <DropdownToggle className='btn-icon-only text-light' size='sm' color='' >
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => changeConsultant(consultant)}>Trocar discretamente</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    )}
                                    <div>
                                        <img src={consultant.avatar} className="rounded-circle img-center img-fluid shadow shadow-lg--hover consultant-thumb" />
                                    </div>
                                    <div className="pt-4 text-center">
                                    <h5 className="h3 title">
                                        <span className="d-block mb-1">{consultant?.fullname}</span>
                                        <small className="h4 font-weight-light text-muted">
                                            {consultant.phonenumber}
                                        </small>
                                    </h5>
                                    </div>
                                    <div>
                                        <span className="font-weight-bold d-block">
                                            
                                        </span>
                                        <span className="text-muted text-xs"></span>
                                    </div>

                                    <Col xs={12} lg={10} className='mx-auto mt-4 p-0'>
                                        <h3 className='h5 title text-center'>Bancos/Produtos</h3>
                                        {consultant.products.map((product, i) => (
                                            <Row className="my-3 py-3 rounded bg-lighter" key={i}>
                                                <Col xs={6} className='text-right' >
                                                    <img src={product.small_logo_url} className='bank-logo' />
                                                </Col>

                                                <Col xs={6} className='pl-0'>
                                                    <span className="font-weight-bold d-block">
                                                        {product.bank_name}
                                                    </span>
                                                    <span className="text-muted text-xs">
                                                        {product.product_name}
                                                    </span>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>            
            </Container>
        </>
    );
};

export default Consultant;
