import React, { useEffect, useState } from "react";

import { loadProposals } from "modules/common/hooks/use-analyses-proposal";
import { useRouteProcess } from "modules/common/route/route";
import { Row, Col } from "reactstrap";
import { CreditAnalysisCard, Details } from "./credit-analysis";

function CreditAnalysis({
  forceReload,
  openTabProcess,
  createProcess,
  createProposalAnalysis,
  proposalProductDetails,
  analysis,
  loadMessages,
  saveDocuments,
  loadDocuments,
  deleteDocument,
  sendMessage,
  readOnly,
  setForceReload
}) {
  const {
    push,
    params: { step: selectedAnalysis },
  } = useRouteProcess();
  const setSelectedAnalysis = (value, stepTab) => {
    push({ step: value, stepTab });
  };
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    if (analysis) {
      loadProposals(analysis.hash).then(setProposals);
    }
  }, [analysis]);

  return (
    <div className="processes-analysis-step">
      <h1 className="font-weight-bolder mt-5">Análise de crédito</h1>
      <Row className="d-flex align-items-stretch">
        {!selectedAnalysis &&
          proposals.map((item, i) => (
            <Col
              md="6"
              lg="4"
              xl="3"
              className="d-flex align-items-stretch"
              key={i}
            >
              <CreditAnalysisCard
                data={item}
                disableActions={!!analysis.proposalFollowed}
                createProposalAnalysis={createProposalAnalysis}
                proposalProductDetails={proposalProductDetails}
                openDetails={() => setSelectedAnalysis(item.pkHash, "chat")}
                createProcess={createProcess}
                openTabProcess={openTabProcess}
                readOnly={readOnly}
                setForceReload={setForceReload}
                forceReload={forceReload}
              />
            </Col>
          ))}
      </Row>
      {selectedAnalysis && (
        <Details
          forceReload={forceReload}
          analysis={analysis}
          proposal={proposals.find((p) => p.pkHash === selectedAnalysis)}
          loadMessages={loadMessages}
          sendMessage={sendMessage}
          saveDocuments={saveDocuments}
          loadDocuments={loadDocuments}
          deleteDocument={deleteDocument}
          onClose={() => setSelectedAnalysis(undefined, undefined)}
        />
      )}
    </div>
  );
}

export default CreditAnalysis;
