import moment from 'moment';

export const dateFormat = (isoDate, format) => {
  if (!isoDate) {
    return isoDate;
  }

  if (!format) {
    format = 'DD/MM/YYYY';
  }

  return moment(isoDate).format(format);
};


export const dateTimeFormat = (isoDate, format) => {
  if (!isoDate) {
    return isoDate;
  }

  if (!format) {
    format = 'DD/MM/YYYY HH:mm';
  }

  return moment(isoDate).format(format);
};
