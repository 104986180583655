import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { css } from '@emotion/css';
import classnames from "classnames";
import { 
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap"

import ProcessesTable from "modules/common/components/ProcessesTable/ProcessesTable"
import { loadAnalysisDashboard } from "modules/common/hooks/use-analyses-basic-data";
import { loadCampaign } from "modules/common/hooks/use-campaigns";
import { formatNumber } from "modules/common/utils/currency-formatter";

import BigNumber from "./BigNumber";
import BigNumberBank from "./BigNumberBank";
import { loadAnalyses } from "modules/common/hooks/use-analyses";
import api from "modules/common/utils/api";
import Swal from "sweetalert2";

function DashboardHome() {
  const { replace } = useHistory();
  const [ campaign, setCampaign ] = useState();
  const [ analyses, setAnalyses ] = useState({ loading: false, directLogin: false, list: [], summary: [] });
  const [ dashboarData, setDashboardData ] = useState({
    proposalsCreate: 0,
    proposalsInProgress: 0,
    proporsalsAproved: 0,
    proposalsConvert: 0,
    meantTicket:0 ,
    banks: [],
  });

  const [ page, setPage ] = useState(1);

  const getAnnouncements = async() =>{
    try {
      const response = await api.get("legacy/announcements/random-announcement/");
      if (response.status !== 200) {
        return false;
      }
      return response
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    loadAnalysisDashboard().then(setDashboardData);
    loadCampaign().then(setCampaign);

    setAnalyses({ ...analyses, loading: true });
    loadAnalyses({search: '', step: '', status: 3, directLogin: '', page: 1}).then((data) => setAnalyses({loading: false, ...data }))
    getAnnouncements().then(({data}) =>{
      if (data.name) {
        Swal.fire({
          title: `${data.name}`,
          html: `${data.message}`,
          focusConfirm: true,
          returnFocus: false,
          showCancelButton: true,
          cancelButtonText: "Fechar",
          confirmButtonText: 'Marcar como lido',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-primary order-2 mx-0',
            cancelButton: 'btn btn-secondary mr-auto',
            actions: 'd-flex justify-content-end px-4',
            popup: 'w-75'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            return api.post(`legacy/announcements/${data.id}/dismiss/`);
          }
      });
      }
    })
  }, []);

  const changePage = (page) => {
    setPage(page);
    loadAnalyses({search: '', step: '', status: 3, directLogin: '', page}).then(setAnalyses);
  };

  const showCampaign = campaign?.title && campaign.title !== "";
  const pointer = css`cursor: pointer;`;

  return (
    <Container fluid className="py-4">
      <div className="mb-4">
        <Button
          size="lg"
          color="primary"
          onClick={() => replace('/processes')}
        >
          <span className="d-none d-sm-inline">Ir para</span> Meus Processos
        </Button>
        <Button
          outline
          size="lg"
          color="dark"
          onClick={() => replace('/simulations')}
        >
          Nova simulação
        </Button>
      </div>

      <Row>
        <Col lg={showCampaign ? 9 : 12}>
          <Row>
            <Col className="d-flex align-self-stretch">
              <BigNumber number={dashboarData.proposalsCreate} title="Propostas" />
            </Col>
            <Col className="d-flex align-self-stretch">
              <BigNumber number={dashboarData.proposalsInProgress} title="Propostas em andamento" />
            </Col>
            <Col className="d-flex align-self-stretch">
              <BigNumber number={`${formatNumber(dashboarData.proporsalsAproved.toFixed(2))}%`} title="Tx de aprovação de crédito" />
            </Col>
            <Col className="d-flex align-self-stretch">
              <BigNumber number={`${formatNumber(dashboarData.proposalsConvert.toFixed(2))}%`} title="Tx de conversão" />
            </Col>
            <Col className="d-flex align-self-stretch">
              <BigNumber number={`${dashboarData.meantTicket}K`} title="Ticket médio" />
            </Col>
          </Row>

          {dashboarData.banks.length > 0 && (
            <Row className="big_numbers">
              {dashboarData.banks.slice(0, 4).map((bank, i) => (
                <Col key={i}>
                  <BigNumberBank
                    number={bank.count}
                    title={bank.name}
                    iconSrc={bank.logo}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Col>

        {showCampaign && (
          <Col xs="9" lg="3">
            <Card>
              <CardImg
                alt="Campanha"
                src={campaign.image_path}
                className={pointer}
                onClick={() => window.open(campaign.landing_page_url, "_blank")}
                top
              />
              <CardBody>
                <CardTitle 
                  className={classnames("font-weight-bolder", pointer)}
                  onClick={() => window.open(campaign.landing_page_url, "_blank")}
                >
                  {campaign.title}
                </CardTitle>
                <CardText>
                  {campaign.description}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>

      <ProcessesTable
        header={
          <div className="d-flex align-items-center">
            <i className="fal fa-bell mr-2 text-primary fa-lg"></i>
            <span className="text-primary h4 m-0">Processos com pendência</span>
            <Button
              size="sm"
              className="ml-auto"
              color="primary"
              onClick={() => replace('/processes')}
            >
              Meus Processos <i className="far ml-1 fa-chevron-right"></i>
            </Button>
          </div>
        }
        loading={analyses.loading}
        data={analyses.list}
        pagination={analyses.pagination}
        page={page}
        onChangePage={changePage}
      />
    </Container>
  )
}

export default DashboardHome


