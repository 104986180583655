import React, { useState, useEffect } from "react";
import { useLocation, Switch, Redirect } from "react-router-dom";

import { routes } from "modules/common/route/route";
import AdminNavbar from "modules/admin/components/AdminNavbar/AdminNavbar";
import AdminFooter from "modules/admin/components/AdminFooter/AdminFooter";
import Sidebar from "modules/admin/components/Sidebar/Sidebar";
import useLoggedUser from "modules/auth/hooks/use-logged-user";

import AuthRoute from "modules/common/components/AuthRoute/AuthRoute";
import { ROUTE_ACCESS_ADMIN, CONTACT_TYPE_ONLY_YOURS, CONTACT_ANALYSIS_READ_ONLY } from "modules/common/route/route";
// import { useLoading } from "modules/common/context/Loading";

function Admin() {
  const [ sidenavOpen, setSidenavOpen ] = useState(false);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const { user } = useLoggedUser();
  const routesAccess = routes.filter((r) => ROUTE_ACCESS_ADMIN[user?.contactType]?.includes(r.path));
  // const { setEnable } = useLoading();

  const defaultRedirect = user?.contactType === CONTACT_TYPE_ONLY_YOURS ? "/processes" : "/dashboard";
  const readOnlyAnalysis = !(user?.contactType === CONTACT_ANALYSIS_READ_ONLY);

  // setEnable(true);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.views?.length > 0) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "") {
        let paths = [];
        if (typeof(prop.path) === "string") {
          paths = [prop.path];
        } else {
          paths = prop.path;
        }
        

        return (
          <AuthRoute
            path={paths.map((path) => prop.layout + path)}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "CrediHabitar";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  useEffect(() => {
    if (routesAccess.length === 0) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    if (routesAccess.length === 0) {
      return;
    }

    if (user?.contactType === CONTACT_TYPE_ONLY_YOURS) {
      const p = routesAccess.find((r) => r.path === '/processes')
      if (p) {
        p.name = 'Meus processos';
      }
    }
  }, [user])

  if (routesAccess.length === 0) {
    return <></>;
  }

  return (
    <>
      {readOnlyAnalysis && (
        <Sidebar
          routes={routesAccess}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/credihabitar-1.svg").default,
            imgAlt: "...",
          }}
        />
      )}
      <div className="main-content" ref={mainContentRef}>
        {readOnlyAnalysis && (
          <AdminNavbar
            theme={"light"}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
          />
        )}
        <Switch>
          {getRoutes(routesAccess)}
          <Redirect from="*" to={defaultRedirect} />
        </Switch>
        {readOnlyAnalysis && (
          <AdminFooter />
        )}
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-md-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default Admin;
