import React from 'react'
import { Button } from 'reactstrap'
import {useHistory} from "react-router";

function EmptyProcesses() {
  const { replace } = useHistory()

  return (
    <div 
      className="rounded w-100 h-50 d-flex flex-column align-items-center justify-content-center"
      style={{ background: '#FFF9FB', minHeight: '400px' }}
    >
      <img
        className="ml-3"
        width="70px"
        height="70px"
        alt="Credihabitar"
        src={require("assets/img/icons/processes.png").default}
        />
      <p className="font-weight-bolder mt-3 mb-0">Você não tem processos ainda.</p>
      <p className="font-weight-normal text-muted m-0 mb-4 text-center">
        Vamos começar?! Crie e envie a sua <br/> primeira proposta de crédito.
      </p>
      <Button
        outline
        color="primary"
        onClick={() => replace('/processes/new')}
      >
        Novo processo
      </Button>
    </div>
  )
}

export default EmptyProcesses

