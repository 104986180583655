import React from "react";
import { Card, CardBody } from "reactstrap"

function BigNumber({ number, title, iconSrc }) {
  return (
    <Card className="flex-fill">
      <CardBody>
        <h1 className="display-4 font-weight-bolder d-flex align-items-center">
          {number}

          {iconSrc && (
            <img
              className="ml-4 icon"
              src={iconSrc}
            />
          )}
        </h1>
        <div className="font-weight-light small text-muted">{title}</div>
      </CardBody>
    </Card>
  )
}

export default BigNumber
