import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import classnames from 'classnames'

import { useRouteProcess } from 'modules/common/route/route';
import StatusBadge from 'modules/common/components/StatusBadge/StatusBadge';

import { PropertyEvaluation } from './processes';

function ProcessCreated({
  forceReload,
  proposalFollowed,
  saveDocuments: saveDocumentsAlias,
  loadDocuments,
  deleteDocument,
  loadMessages,
  sendMessage,
}) {
  const tasks = proposalFollowed?.project?.tasks || [];
  const currentTaskId = proposalFollowed?.project?.currentTaskId;
  const { push, params: { step: currentTask, stepTab } } = useRouteProcess();
  const setCurrentTask = (value) => push({ step: value, stepTab: undefined });
  const [ _, setOverrideStatus ] = useState({
    id: undefined,
    message_content: false,
    message_type: false,
    project_status_id: false,
    task_status_id: false,
  });
  

  const saveDocuments = (pkHash, id, files) => {
    const data = saveDocumentsAlias(pkHash, id, files);
    if (data) {
      setOverrideStatus({
        id,
        message_content: data.message_content,
        message_type: data.message_type,
        project_status_id: data.project_status_id,
        task_status_id: data.task_status_id,
      })
    }
    return data;
  }

  useEffect(() => {
    if (currentTask) {
      task = tasks?.find((t) => t.id === Number(currentTask));
      if (task) {
        return;
      }
    }

    let task = currentTaskId;
    if (!currentTaskId) {
      task = tasks.find((t) => t.id === currentTaskId)?.id;
      if (!task && tasks.length > 0) {
        task = tasks[0].id;
      }
    }
    setCurrentTask(task);

  }, []);

  if (!currentTask) {
    return <></>;
  }

  return (
    <section className="mt-4">
      <Row>
        <Col lg="3">
          {tasks.map((task, i) => {
            // const canOverride = overrideStatus.id === task.id;
            const selected = task.id === Number(currentTask);
            return (
              <div
                onClick={() => setCurrentTask(task.id)}
                key={i}
                className={classnames(
                  "rounded px-4 mb-3 font-weight-bold btn-wizard-gray",
                  "d-flex justify-content-between align-items-center",
                  {
                    "border btn-wizard-gray--selected": selected,
                  }
                )}
                role="button"
              >
                <div className='py-4'>
                  {task.name}
                  <div className='text-xs'>
                    <StatusBadge
                      statusBgColor={task.statusBgColor}
                      statusTextColor={task.statusTextColor}
                      statusName={task.statusName}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </Col>

        <Col lg="9">
          {currentTask && (
            <PropertyEvaluation
              forceReload={forceReload}
              currentTab={stepTab}
              setCurrentTab={(value) => push({ stepTab: value })}
              loadMessages={loadMessages}
              sendMessage={sendMessage}
              saveDocuments={saveDocuments}
              loadDocuments={loadDocuments}
              deleteDocument={deleteDocument}
              task={tasks.find((t) => t.id === Number(currentTask))}
              proposal={proposalFollowed}
            />
          )}
        </Col>
      </Row>
    </section>
  )
}

export default ProcessCreated
