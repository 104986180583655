import React from 'react'
import { Input } from 'reactstrap';

function DateInput({ 
  id,
  initialValue,
  name,
  required,
  value,
  onChange,
  invalid,
  min,
  max
}) {
  return (
    <Input
      id={id}
      required={required}
      name={name}
      placeholder="Digite aqui..."
      defaultValue={initialValue}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      type='date'
      invalid={invalid}
    />
  )
}

export default DateInput
