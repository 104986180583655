import Swal from 'sweetalert2';

import api from './api';

export const blobToDataUrl = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve({ name: blob.name, data: reader.result});
    reader.onerror = reject;
    reader.readAsDataURL(blob);
});

export const browserDownload = async (path, file_name, mimetype) => {
    if (!file_name) {
        file_name = 'download';
    }

    if (!mimetype) {
        mimetype = 'application/octet-stream';
    }

    const response = await api.get(path);    
    if (response.status !== 200) {
        Swal.fire({
            title: 'Não foi possível fazer o download.',
            icon: 'error',
        });
        return;
    }

    const a = document.createElement("a"); // Create <a>
    a.href = `data:${mimetype};base64,${response.data}`;
    a.download = file_name; // File name Here
    a.click(); // Downloaded file
};
