import moment from "moment";
import { loadData } from "./common";
import formatCurrency from "modules/common/utils/currency-formatter";

const formatAnalysis = (data) => {
  data.directLogin = data.direct_login
  delete data.direct_login

  data.list = data.list.map((analysis) => ({
    hash: analysis.pk_hash,
    name: analysis.customer?.full_name ?? '-',
    cpf: analysis.customer?.cpf,
    mortgage: formatCurrency(analysis.mortgage, 'BRL'),
    createdAt: moment(analysis.created_at).format('DD/MM/yyyy HH:mm'),
    proposalFollowed: analysis.proposal_followed,
    proposals: analysis.proposals,
    // origin: 'TO-DO',
    status: analysis.status,
    multipleStatus: analysis.multiple_status,
    product: analysis.product,
    step:  analysis.step,
    customer: analysis.customer,
  }));

  return data;
};

export const loadAnalyses = async ({search='', step='', status='', directLogin='', sort='', page=1}) => {
  const data = await loadData(`/analysis/?query=${search}&step=${step}&status=${status}&direct_login=${directLogin}&page=${page}&order_by=${sort}`);
  return formatAnalysis(data);
};
  
