import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap";

import { DIRECT_LOGIN, CUSTOMER_SOURCE_CONVERSION } from "modules/processes/constants";

import PagePagination from "../Pagination/pagination";

import StatusBadge from "../StatusBadge/StatusBadge";

import EmptyProcesses from "modules/processes/pages/processes/EmptyProcesses";

function ProcessesTable({ header, loading, data, pagination, page, onChangePage, sort, onSort: handleSort }) {
  const { push } = useHistory();
  const onRowClick = (project) => {
    if (project?.proposalFollowed?.product?.access_type_id === DIRECT_LOGIN || project?.customer?.source?.id === CUSTOMER_SOURCE_CONVERSION) {
      push(`/direct/${project.hash.toString()}/process`);
    } else {
      push(`/processes/${project.hash.toString()}`);
    }
  };

  const column = sort?.substring(1, sort.length);
  const onSort = (newSort) => {
    if (column === newSort) {
      const oldColumn = sort.substring(1, sort.length);
      if (oldColumn === newSort) {
        if (sort[0] === '-') {
          newSort = '+' + newSort;
        } else if (sort[0] === '+') {
          newSort = '-' + newSort;
        }
      }
    } else {
      newSort = '+' + newSort;
    }

    handleSort(newSort);
  };

  return (
    <Card className="main-processes-table">
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <Col>
            {header}
          </Col>
        </Row>
      </CardHeader>

      {loading && (
        <div style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          paddingBottom: "100px",
          margin: "0 auto",
        }}>
          <Spinner animation="border" role="status">
            Loading...
          </Spinner>
        </div>
      )}

      {!loading && data.length === 0 && (
        <EmptyProcesses />
      )}

      {!loading && data.length > 0 && (<Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col" className="text-center">
              Banco
            </th>
            <th
              scope="col"
              onClick={() => onSort('client')}
            >
              Cliente
              <i className={handleSort && classNames("fa", "ml-1", "sort", {
                "fa-sort": column !== "client",
                "fa-sort-down": sort === "-client",
                "fa-sort-up": sort === "+client",
              })}
              ></i>
            </th>
            <th
              scope="col"
              onClick={() => onSort('process')}
            >
              # Processo
              <i className={handleSort && classNames("fa", "ml-1", "sort", {
                "fa-sort": column !== "process",
                "fa-sort-down": sort === "-process",
                "fa-sort-up": sort === "+process",
              })}></i>
            </th>
            <th scope="col">
              Produto
            </th>
            <th scope="col">
              Origem
            </th>
            <th
              scope="col"
              onClick={() => onSort('credit')}
            >
              Crédito
              <i className={handleSort && classNames("fa", "ml-1", "sort", {
                "fa-sort": column !== "credit",
                "fa-sort-down": sort === "-credit",
                "fa-sort-up": sort === "+credit",
              })}></i>
            </th>
            <th
              scope="col"
              onClick={() => onSort('createat')}
            >
              Cadastro em
              <i className={handleSort && classNames("fa", "ml-1", "sort", {
                "fa-sort": column !== "createat",
                "fa-sort-down": sort === "-createat",
                "fa-sort-up": sort === "+createat",
              })}></i>
            </th>
            <th scope="col">
              Etapa
            </th>
            <th scope="col">
              Status
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((value, i) => {
            const proposalsSend = (value.proposals.filter((p) => p.status.value !== 0) || []); // 0 = Não enviada
            return (
              <tr
                key={"processtable" + value.hash}
                onClick={() => onRowClick(value)}
                role="button"
              >
                <td className="text-center">
                  <div className="avatar-group">
                      {value.proposalFollowed && (
                        <span className="avatar rounded-circle">
                          <img src={value.proposalFollowed?.product?.logo} />
                        </span>
                      )}
                      {!value.proposalFollowed && proposalsSend.length > 0 && (
                        proposalsSend.map((proposal, i) => (
                          <span key={i} className="avatar rounded-circle">
                            <img src={proposal.product?.logo} />
                          </span>
                        ))
                      )}
                      {proposalsSend.length === 0 && (
                        <span className="fa-stack fa-lg">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <i className="fal fa-pencil fa-stack-1x fa-inverse"></i>
                        </span>
                      )}
                  </div>
                </td>
                <td>
                  <div className="text-truncate">{value.name}</div>
                  <p className="m-0 text-muted h5">{value.cpf}</p>
                </td>
                <td scope="row">
                  {value.proposalFollowed && value.proposalFollowed?.project_id}
                  {!value.proposalFollowed && "--"}
                </td>
                <td>{value.product?.label}</td>
                <td>{value.customer?.source?.name}</td>
                <td>{value.mortgage}</td>
                <td>{value.createdAt}</td>
                <td>
                  <Badge className="text-xs">{value?.step?.label}</Badge>
                </td>
                <td className={classNames({td_status_group: value?.multipleStatus && value?.multipleStatus.length > 1 })}>
                  {value?.multipleStatus && value?.multipleStatus.map((status, i) => (
                    <StatusBadge
                      key={i}
                      statusTextColor={status.textColor}
                      statusBgColor={status.bgColor}
                      statusName={status.label}
                    />
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>)}
      
      <CardFooter className="py-4">
        <nav aria-label="...">
          <PagePagination pagination={pagination} page={page} onChange={onChangePage} />
        </nav>
      </CardFooter>
    </Card>
  );
}

export default ProcessesTable
