import useSWR from "swr";
import api from "modules/common/utils/api";
import { defaultSwrOptions } from "modules/common/utils/swr-options";

const fetcher = (...args) => api.get(...args).then(res => res.data.map(state =>({
  ...state,
  id: state.pk_hash,
  pkId: state.id,
})))

function useStates(countryId) {
  const url = countryId ? `/customers/countries/${countryId}/states` : null
  const { data, error } = useSWR(url, fetcher, defaultSwrOptions)

  return {
    states: data || [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useStates

