import React from 'react'
import { Card, CardBody } from 'reactstrap'
import formatCurrency from 'modules/common/utils/currency-formatter'
import StatusBadge from 'modules/common/components/StatusBadge/StatusBadge';

function ProcessHeaderFollowed({ proposalFollowed, analysis }) {
  return (
    <Card>
      <CardBody className="d-lg-flex align-items-lg-center justify-content-between flex-md-column flex-lg-row align-items-md-start">
        <div className="d-flex mb-3 mb-lg-0">
          <div
            className="rounded d-flex mr-3"
            style={{ width: 50, height: 50, backgroundColor: '#FFF8F5' }}
          >
            {proposalFollowed ? <img src={proposalFollowed.product.logo} /> : <i className="fab fa-buffer m-auto text-primary" />}
          </div>

          <div>
            <span className="font-weight-bold d-block">
              {analysis?.customer?.fullName || '--'}
            </span>
            <span className="text-muted text-sm">
              {analysis?.customer?.cpf || '--'}
            </span>
          </div>
        </div>

        <div className="d-lg-flex align-items-center my-md-3 my-lg-0">
        <div className="mr-5 mb-2 mb-lg-0">
            <span className="text-xs text-muted font-weight-bold d-block">PRODUTO</span>
            <span className="font-weight-bold">
            {!!analysis?.product?.label ? analysis?.product?.label : '--'}
            </span>
          </div>

          <div className="mr-5 mb-2 mb-lg-0">
            <span className="text-xs text-muted font-weight-bold d-block">VALOR SOLICITADO</span>
            <span className="font-weight-bold">
              {!!proposalFollowed?.mortgageRequested ? formatCurrency(proposalFollowed.mortgageRequested, 'BRL') : '--'}
            </span>
          </div>

          <div className="mr-5 mb-2 mb-lg-0">
            <span className="text-xs text-muted font-weight-bold d-block">VALOR APROVADO</span>
            <span className="font-weight-bold">
              {!!proposalFollowed?.mortgageApproved && proposalFollowed.mortgageApproved > 0 ? formatCurrency(proposalFollowed.mortgageApproved, 'BRL') : '--'}
            </span>
          </div>

          <div className="mr-5 mb-2 mb-lg-0">
            <span className="text-xs text-muted font-weight-bold d-block">PRAZO APROVADO</span>
            <span className="font-weight-bold">
              {!!proposalFollowed?.installmentsApproved ? `${proposalFollowed.installmentsApproved} meses` : '--'}
            </span>
          </div>

          <div className="mr-5 mb-2 mb-lg-0">
            <span className="text-xs text-muted font-weight-bold d-block">ETAPA</span>
            <span className="font-weight-bold">
              {!!analysis?.step?.label && proposalFollowed ? analysis?.step?.label : '--'}
            </span>
          </div>

          <div>
            <span className="text-xs text-muted font-weight-bold d-block">STATUS</span>
            <span className="font-weight-bold">
              {analysis?.status?.label ? <StatusBadge statusBgColor={analysis.status?.bgColor} statusTextColor={analysis.status?.textColor} statusName={analysis.status?.label} /> : '--'}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ProcessHeaderFollowed
