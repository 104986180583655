
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { zipObject } from "lodash";
import { useParams, useHistory } from 'react-router'

import { loadProducts } from 'modules/common/hooks/use-products';
import {
  loadJobTitles,
  loadJobTypes,
  loadResidenceTimeUnit,
  loadCarsBrand,
  loadGenders,
  loadMarriageStatus,
  loadMarriageSystems,
  loadContractTypes,
  loadMotorcyclesBrand,
  loadTrucksBrand,
  loadVehiclesType
} from 'modules/common/hooks/use-analyses-basic-data';

import { AnalysisContext } from "modules/processes/contexts/AnalysisContext";

import {
  FinanceAmountCGV,
  HomeAddress,
  Income,
  LeadInfo,
  RegisterData,
  Documents,
} from './wizard';
import { ListeSteps } from "./ListeSteps";


export const ClientCarAssuranceCustomer = ({ currentWizard, setCurrentWizard, analysis, productSelected,onFinished }) => {
  const { replace } = useHistory();
  const { id } = useParams();

  const { 
    submitAnalysis,
    submitCustomer,
    submitMarriageData,
    submitAddress,
    submitIncome,
    submitDocuments,
  } = useContext(AnalysisContext);
  
  const wizardSteps = [
    
    { 
      index: 0,
      title: 'Dados do empréstimo',
      component: (props) => <FinanceAmountCGV
        analysis={analysis}
        products={analysisBasicData.products}
        productSelected={productSelected}
        carsBrand={analysisBasicData.carsBrand}
        trucksBrand={analysisBasicData.trucksBrand}
        motorcyclesBrand={analysisBasicData.motorcyclesBrand}
        customer={analysis?.customer}
        vehicleType={analysisBasicData.vehicleType}
        {...props}
      />,
      onSubmit: async (values, analysis, additionalData = {}) => {
        return await submitAnalysis(values, analysis, additionalData);
      },
      loadData: () => ({
        carsBrand: loadCarsBrand(),
        motorcyclesBrand: loadMotorcyclesBrand(),
        trucksBrand: loadTrucksBrand(),
        products: loadProducts(),
        vehicleType: loadVehiclesType()
      }),
    },
    
    {
      index: 1,
      title: 'Dados pessoais 1/2',
      component: (props) => (
        <LeadInfo
          simulationBirthDate={analysis?.simulation?.birthDate}
          customer={analysis?.customer}
          genders={analysisBasicData.genders}
          isMain
          {...props}
        />
      ),
      onSubmit: submitCustomer,
      loadData: () => ({
        genders: loadGenders(),
      }),
    },
    { 
      index: 2,
      title: 'Dados pessoais 2/2',
      component: (props) => <RegisterData
        marriageStatus={analysisBasicData.marriageStatus}
        marriageSystems={analysisBasicData.marriageSystems}
        genders={analysisBasicData.genders}
        customer={analysis?.customer}
        {...props}
      />,
      onSubmit: submitMarriageData,
      loadData: () => ({
        marriageStatus: loadMarriageStatus(),
        marriageSystems: loadMarriageSystems(),
        genders: loadGenders(),
      }),
    },
    { 
      index: 3,
      title: 'Documentos',
      component: (props) => <Documents customer={analysis?.customer} {...props} />,
      onSubmit: submitDocuments
    },
    
    {
      index: 4,
      title: 'Endereço residencial',
      component: (props) => <HomeAddress
        residenceTimeUnits={analysisBasicData.residenceTimeUnits}
        customer={analysis?.customer}
        {...props}
      />,
      onSubmit: submitAddress,
      loadData: () => ({
        residenceTimeUnits: loadResidenceTimeUnit(),
      }),
    },
    {
      index: 5,
      title: 'Renda',
      component: (props) => <Income
        jobTitles={analysisBasicData.jobTitles}
        jobTypes={analysisBasicData.jobTypes}
        contractTypes={analysisBasicData.contractTypes}
        customer={analysis?.customer} {...props}
      />,
      onSubmit: async (e) => {
        await submitIncome(e, analysis)
        return onFinished()
      },
      loadData: () => ({
        jobTitles: loadJobTitles(),
        jobTypes: loadJobTypes(),
        contractTypes: loadContractTypes(),
      }),
    }
  ];

  
  

  const [ finishedWizards, setFinishedWizards ] = useState([]);

  const step = wizardSteps.find(step => step.index === currentWizard);

  const submitStep = async (event) => {
    event.preventDefault?.();
    const ret = await step.onSubmit?.(event, analysis);
    nextStep(ret);

    if (id  === "new") {
      replace(`/processes/${ret}`);
    }
  };

  const nextStep = (ret) => {
    const currentStep = step.index;
    setFinishedWizards(finished => [...finished, currentStep]);
    setCurrentWizard(current => {
      if (wizardSteps.length - 1 === current) {
        return current;
      }
      return currentStep + 1;
    });
  };
  const [analysisBasicData, setAnalysisBasicData] = useState({
    products: [],
    genders: [],
    marriageStatus: [],
    marriageSystems: [],
    carsBrand: [],
    motorcyclesBrand: [],
    vehicleType: [],
    trucksBrand: [],
    residenceTimeUnits: [],
    jobTitles: [],
    jobTypes: [],
    contractTypes: [],
    propertyTypes: [],
    propertyUseTypes: [],
    countries: [],
  });

  const loadCustomerState = (analysis) => {
    const finishedWizards = []
  
    if (analysis.customer?.income) finishedWizards.push(5);
    if (analysis.customer?.address) finishedWizards.push(4);
    if (analysis.customer?.documentNumber) finishedWizards.push(3);
    if (analysis.customer?.marriageStatus) finishedWizards.push(2);
    if (analysis.customer) finishedWizards.push(1);
    if (analysis.hash) finishedWizards.push(0);
  
    const currentWizard = Math.min(Math.max(...finishedWizards, 0) + 1, 5);

    return { finishedWizards, currentWizard };
  };

  useEffect(() => {
    if (analysis) {
      const { finishedWizards, currentWizard } = loadCustomerState(analysis);
      setFinishedWizards(finishedWizards);
      setCurrentWizard(currentWizard);
    }
  }, []);

  useEffect(() => {
    if (!analysis) {
      setFinishedWizards([]);
      return;
    }

    const { finishedWizards } = loadCustomerState(analysis);
    setFinishedWizards(finishedWizards);
  }, [analysis]);

  useEffect(() => {
    (async () => {
      const step = wizardSteps.find(step => step.index === currentWizard);
      if (step && step.loadData) {
        const data = step.loadData();
        const dataDone = await Promise.all(Object.values(data));

        setAnalysisBasicData(({
          ...analysisBasicData,
          ...zipObject(Object.keys(data), dataDone),
        }));
      }
    })();
  }, [currentWizard]);

  return (
    <Row>
      <ListeSteps
        wizardSteps={wizardSteps}
        finishedWizards={finishedWizards}
        currentWizard={currentWizard}
        setCurrentWizard={setCurrentWizard}
      />

      <Col lg="4" md="12">
        {step && <step.component onSubmit={submitStep} />}
      </Col>
    </Row>
  );
};