import { useState } from "react";
import { Button, Modal } from "reactstrap";

function useConfirmationDialog({
  title,
  content,
  skip,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  }

  const getConfirmationDialog = () => (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {title}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {content}
      </div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          Voltar
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  )

  const confirmationDialog = isOpen && getConfirmationDialog();

  const withConfirmation = (callback) => {
    if (skip) return (...params) => callback(...params)

    return (e) => {
      setIsOpen(true);
      setOnConfirm(() => () => callback(e));
    }
  }

  return {
    confirmationDialog,
    withConfirmation
  }

}

export default useConfirmationDialog;
