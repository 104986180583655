import classNames from 'classnames'
import React from 'react'
import { Card, CardBody } from 'reactstrap'

function ProcessFilterSpotlight({ 
  label,
  value,
  onClick,
  selected
}) {
  return (
    <Card 
      role='button'
      onClick={onClick}
      className={classNames({ 'bg-primary-light': selected })}
      style={{ transition: '0.5s' }}
    >
      <CardBody>
        <p className="font-weight-bolder m-0">{value}</p>
        <p className="font-weight-bold text-xs text-uppercase m-0">{label}</p>
      </CardBody>
    </Card>
  )
}

export default ProcessFilterSpotlight

