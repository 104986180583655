import React from "react";
import sanitizeHtml from 'sanitize-html';


const defaultOptions = {
  allowedTags: [
    "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
    "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
    "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
    "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
    "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
    "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
    "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
  ],
  allowedAttributes: {
    div: [ 'style', 'class' ],
    a: [ 'href', 'name', 'target', 'class' ],
    // We don't currently allow img itself by default, but
    // these attributes would make sense if we did.
    img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading', 'class' ]
  },
  selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
  allowedSchemes: [ 'http', 'https' ],
  allowedSchemesAppliedToAttributes: [ 'href', 'src' ],
  allowedIframeHostnames: []
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(
      dirty,
      { ...defaultOptions, ...options },
  )
});

const SanitizeHTML = ({ html, options }) => {
  const sanitizedHtml = sanitize(html, options);
  return <div dangerouslySetInnerHTML={sanitizedHtml} />
};

export function General({ task }) {
  return (
    <SanitizeHTML html={task?.description} />
  );
}
