import React, {  useEffect,  useState } from 'react';
import { Col, Row } from 'reactstrap';
import ReactMarkdown from 'react-markdown'
import { formatCurrency } from "modules/common/utils/currency-formatter";
import { dateFormat } from 'modules/common/utils/date-format';
import rehypeRaw from "rehype-raw";

import {
  loadJobTitles,
  loadJobTypes,
  loadResidenceTimeUnit,
  loadPurchaseEstimates,
  loadGenders,
  loadMarriageStatus,
  loadMarriageSystems,
  loadContractTypes,
  loadPropertyTypes,
  loadPropertyUseTypes,
  loadAddressType,
  loadCountries,
} from 'modules/common/hooks/use-analyses-basic-data';
import { CUSTOMER_SOURCE_CONVERSION } from 'modules/processes/constants';

function ClientReadCgi({ analysis }) {
  const [ data, setData ] = useState({
    jobTitles: [],
    jobTypes: [],
    residenceTimeUnit: [],
    purchaseEstimates: [],
    genders: [],
    marriageStatus: [],
    marriageSystems: [],
    contractTypes: [],
    propertyTypes: [],
    propertyUseTypes: [],
    addresstype: [],
    countries: [],
  });
  
  const isConversion = analysis?.customer?.customersSource?.id === CUSTOMER_SOURCE_CONVERSION;

  useEffect(() => {
    const loadData = Promise.all([
      loadJobTitles(),
      loadJobTypes(),
      loadResidenceTimeUnit(),
      loadPurchaseEstimates(),
      loadGenders(),
      loadMarriageStatus(),
      loadMarriageSystems(),
      loadContractTypes(),
      loadPropertyTypes(),
      loadPropertyUseTypes(),
      loadAddressType(),
      loadCountries(),
    ]);

    loadData.then((data) => {
      setData({
        jobTitles: data[0],
        jobTypes: data[1],
        residenceTimeUnit: data[2],
        purchaseEstimates: data[3],
        genders: data[4],
        marriageStatus: data[5],
        marriageSystems: data[6],
        contractTypes: data[7],
        propertyTypes: data[8],
        propertyUseTypes: data[9],
        addresstype: data[10],
        countries: data[11],
      });
    });
  }, []);

  return (
    <section>
      {/* <div className='d-flex align-items-center my-4 bidder-menu'> */}
    <div className='my-4'>
        <Row>
          <Col>
            <ReactMarkdown
              children={`${financeAmount(analysis, isConversion)}\n${isConversion ? '' : documents(analysis?.customer, data)}`}
              rehypePlugins={[rehypeRaw]}
            />
          </Col>
          <Col>
            <ReactMarkdown 
            children={`${leadInfo(analysis?.customer, data, isConversion)}\n${isConversion ? '' : registerData(analysis?.customer, data)}`}
            rehypePlugins={[rehypeRaw]}
            />
          </Col>
          <Col>
            {!isConversion && <ReactMarkdown 
            children={`${buyingDate(analysis, data)}\n${homeAddress(analysis.customer?.address, data)}\n${income(analysis.customer?.income, data)}\n${propertyInfo(analysis?.property, data)}`}
            rehypePlugins={[rehypeRaw]}
            />}
          </Col>
        </Row>

        {analysis?.bidders?.length > 0 && (
          <Row className='my-4'>
            <h2>Proponentes</h2>
          </Row>
        )}
        {analysis?.bidders?.map((bidder, i) => (
          <div key={i}>
            <Row>
              <Col>
              <ReactMarkdown>{`# ${bidder.fullName}`}</ReactMarkdown>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactMarkdown>{`${leadInfo(bidder, data)}\n${registerData(bidder, data)}`}</ReactMarkdown>
              </Col>
              <Col>
                <ReactMarkdown>{`${documents(bidder)}\n${homeAddress(bidder?.address, data)}`}</ReactMarkdown>
              </Col>
              <Col>
                <ReactMarkdown>{income(bidder?.income, data)}</ReactMarkdown>
              </Col>
            </Row>
            <Row>
              <ReactMarkdown>{`---`}</ReactMarkdown>
            </Row>
          </div>
        ))}
      </div>
    </section>
  )
}

const financeAmount = (analysis, isConversion) => `
## Valores do financiamento
**Produto**: ${analysis.product.label || ''}\\
**Valor do imóvel**: ${formatCurrency(analysis.propertyPrice, 'BRL') || ''}\\
**Valor financiado**: ${formatCurrency(analysis.mortgage, 'BRL') || ''}${isConversion ? '' : '\\'}
${isConversion ? '' : `**Prazo**: ${analysis.installments || ''} meses`}

${analysis.isfinanceAnalysisAndRatings ? '<i class="fas fa-check-circle text-success"></i> Pretende financiar a análise e avaliação.\\': ''}
${analysis.isFinanceDocumentation ? '<i class="fas fa-check-circle text-success"></i> Pretende financiar ITBI/Cartório/IOF.\\': ' '}
${analysis.isUsingFgts ? '<i class="fas fa-check-circle text-success"></i> Pretende usar o FGTS.': ''}
`;

const financeAmountCGV = (analysis, isConversion) => `
## Valores do imprestimo
**Produto**: ${analysis.product.label || ''}\\
**Valor do imóvel**: ${formatCurrency(analysis.propertyPrice, 'BRL') || ''}\\
**Valor financiado**: ${formatCurrency(analysis.mortgage, 'BRL') || ''}${isConversion ? '' : '\\'}
${isConversion ? '' : `**Prazo**: ${analysis.installments || ''} meses`}

${analysis.isfinanceAnalysisAndRatings ? '<i class="fas fa-check-circle text-success"></i> Pretende financiar a análise e avaliação.\\': ''}
${analysis.isFinanceDocumentation ? '<i class="fas fa-check-circle text-success"></i> Pretende financiar ITBI/Cartório/IOF.\\': ' '}
${analysis.isUsingFgts ? '<i class="fas fa-check-circle text-success"></i> Pretende usar o FGTS.': ''}
`

const buyingDate = (analysis, data) => `
## Prazo estimado para a compra
${data.purchaseEstimates.find((opt, index) => opt.id === analysis?.purchaseEstimate)?.name || ''}
`;

const propertyInfo = (property, data) => `
## Informações do imóvel
**Tipo do imóvel**: ${data.propertyTypes.find((p) => p.id === property?.propertyType)?.name || ''}\\
**Finalidade do imóvel**: ${data.propertyUseTypes.find((p) => p.id === property?.propertyUseType)?.name || ''}

### Localização do imóvel
Possui imóvel definido: ${property?.isDefined ? '<i class="fas fa-check-circle text-success"></i>' : '<i class="fas fa-times-circle text-danger"></i>'}
${property?.isDefined ? (`
  Imóvel definido\\
  **Idade do imóvel (anos)**: ${property?.age || ''}\\
  **Área do imóvel (m2)**: ${property?.totalArea || ''}\\
  **Valor do condomínio + IPTU**: ${property?.monthlyCost || ''}\\
  **CEP**: ${property?.zipCode || ''}\\
  **Rua**: ${property?.street || ''}\\
  **Número**: ${property?.number || ''}\\
  **Complemento**: ${property?.complement || ''}\\
  **Bairro**: ${property?.neighborhood || ''}\\
`) : ''}
**Estado**: ${property?.state || ''}\\
**Cidade**: ${property?.city || ''}
`;

const leadInfo = (bidder, data, isConversion) => {
  if (isConversion) {
    return `
## Dados pessoais 1/2
**Nome completo**: ${bidder?.fullName || ''}\\
**CPF**: ${bidder?.cpf || ''}`  
  }
  return `
## Dados pessoais 1/2
**Nome completo**: ${bidder?.fullName || ''}\\
**CPF**: ${bidder?.cpf || ''}\\
**Gênero**: ${data.genders.find((g) => g.id === bidder?.gender)?.name || ''}\\
**Email**: ${bidder?.email || ''}\\
**Data de nascimento**: ${dateFormat(bidder?.birthDate) || ''}\\
**Telefone**: ${bidder?.cellphone || ''}
  `;
}

const registerData = (bidder, data) => {
  const marriageStatus = data.marriageStatus?.find((m) => m.id === bidder?.marriageStatus);
  return `
## Dados pessoais 2/2
**Nacionalidade**: ${data.countries.find((c) => c.pkId === bidder?.homeCountry)?.nationality || ''}\\
**Telefone residencial**: ${bidder?.residentialPhone || ''}\\
**Nome completo da mãe**: ${bidder?.motherName || ''}\\
**Estado civil**: ${marriageStatus?.name || ''}
${(marriageStatus?.showMarriageForm) ? (`
  **Regime de casamento**: ${data.marriageSystems?.find((m) => m.id === bidder?.marriageSystem)?.name || ''}\\
  **Data do casamento**: ${dateFormat(bidder?.weddingDate) || ''}
`): ''}
${(marriageStatus?.showPartnerForm || bidder?.hasStableUnion) ? (`
  ### Dados do Cônjuge
  **Nome completo**: ${bidder?.partnerName || ''}\\
  **CPF**: ${bidder?.partnerCpf || ''}\\
  **Sexo**: ${data.genders.find((g) => g.id === bidder?.partnerGender)?.name || ''}\\
  **Data de nascimento**: ${dateFormat(bidder?.partnerBirthDate) || ''}\\
  ${bidder?.isPartnerSameAddress ? '<i class="fas fa-check-circle text-success"></i> Moram no mesmo endereço.': 'Não moram no mesmo endereço.'}
`): ''}
${(marriageStatus?.showStableUnionOption && bidder?.hasStableUnion) ? (`
  ### União estável
  **Data da união**: ${dateFormat(bidder?.stableUnionDate) || ''}
`): ''}
`;
};


const documents = (bidder) => `
## Documentos
**RG**: ${bidder?.documentNumber || ''}\\
**Orgão emissor**: ${bidder?.documentIssuedBy || ''}\\
**Local de emissão**: ${bidder?.documentIssueLocationName || ''}\\
**Data de emissão**: ${dateFormat(bidder?.documentIssueDate) || ''}
### Naturalidade
**UF**: ${bidder?.homeState?.name || ''}\\
**Cidade**: ${bidder?.homeCity?.name || ''}
`;

const homeAddress = (address, data) => `
## Endereço residencial
**Tipo de Moradia**: ${data.addresstype.find((a) => a.id === address?.addressType)?.name || ''}\\
**CEP**: ${address?.zipCode || ''}\\
**Rua**: ${address?.street || ''}\\
**Número**: ${address?.number || ''}\\
**Complemento**: ${address?.complement || ''}\\
**Bairro**: ${address?.neighborhood || ''}\\
**Estado**: ${address?.state || ''}\\
**Cidade**: ${address?.city || ''}\\
**Tempo neste endereço**: ${address?.residenceTime || ''} ${data.residenceTimeUnit.find((t) => t.id === address?.residenceTimeUnit)?.name || ''}
`;

const income = (income, data) => `
## Renda
**Tipo de renda**: ${data.contractTypes.find((t) => t.id === income?.contractType)?.name || ''}\\
**Cargo**: ${data.jobTypes.find((t) => t.id === income?.jobType)?.name || ''}\\
**Profissão**: ${data.jobTitles.find((t) => t.id === income?.jobTitle)?.name || ''}\\
**Nome da Empresa**: ${income?.companyName || ''}\\
**Telefone**: ${income?.phone || ''}\\
**Data de admissão**: ${dateFormat(income?.dateOfEmployment) || ''}\\
**Renda mensal**: ${formatCurrency(income?.monthlyIncome, 'BRL') || ''}
`;

export default ClientReadCgi;
