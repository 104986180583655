import { useEffect, useState } from "react";
import { AnnouncementComponent } from "./announcementComponent";
import api from "modules/common/utils/api";
import { Card, CardHeader, Col, Container, Row, Table } from "reactstrap";

export const Announcements = ({}) => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    api.get("legacy/announcements/").then(({ data }) => setAnnouncements(data));
  }, []);

  return (
    <Container fluid>
      <Card className=" w-100 mt-4">
        <CardHeader className="mt-3">
          <span className="title h3">Quadro de avisos</span>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" align="center" className="w-75">
                Assunto <i className="fa ml-1 fa-sort"></i>
              </th>
              <th scope="col" className="w-25">
                Publicado em <i className="fa ml-1 fa-sort"></i>
              </th>
            </tr>
          </thead>

          <tbody>
            {announcements.map((announcement, i) => (
              <AnnouncementComponent
                announcement={announcement}
                key={`announcement-${i}`}
              />
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
};
