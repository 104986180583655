import useSWR from "swr";
import api from "modules/common/utils/api";
import { defaultSwrOptions } from "modules/common/utils/swr-options";

const fetcher = (...args) => api.get(...args).then(res => res.data)

function useAddressType() {
  const { data, error } = useSWR('/customers/address-types', fetcher, defaultSwrOptions)
  
  return {
    addressTypes: data || [],
    isLoading: !error && !data,
    isError: error,
  };
}

export default useAddressType

