import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Button, ButtonGroup, Card, CardBody, CardHeader } from 'reactstrap';

import { AnalysisChat } from 'modules/processes/pages/process_details/steps/common';
import { Documents } from './Documents';
import { General } from './General';

export function PropertyEvaluation({
  forceReload,
  currentTab,
  setCurrentTab,
  task,
  proposal,
  saveDocuments,
  loadDocuments,
  deleteDocument,
  loadMessages,
  sendMessage,
}) {
  const tabs = [];

  if (task?.description !== null && task?.description !== '') {
    tabs.push({
      title: 'Geral',
      type: 'general',
    });
  }

  tabs.push({
    title: 'Chat',
    type: 'chat',
  });

  if (task?.documents) {
    tabs.push({
      title: 'Documentos',
      type: 'documents',
    });
  }

  useEffect(() => {     
    if (task?.documents) {
      setCurrentTab(currentTab || "documents");
    } else {
      setCurrentTab(currentTab || "chat");
    }
  }, [task]);

  if (!task) {
    return <></>;
  }

  return (<>
      
      <Card>
        <CardHeader>
          <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
            {tabs.map((tab, i) => (
              <Button
                key={i}
                className={classnames({
                  active: currentTab === tab.type
                })}
                color="secondary"
                onClick={() => setCurrentTab(tab.type)}
              >
                <span className="btn-inner--text">
                  {tab.title}
                </span>
              </Button>
            ))}
          </ButtonGroup>
        </CardHeader>
        <CardBody>
          {currentTab === 'general' && (
            <General task={task} />
          )}
          {currentTab === 'chat' && (
            <AnalysisChat
              task={task}
              proposal={proposal}
              loadMessages={loadMessages}
              sendMessage={sendMessage}
              forceReload={forceReload}
            />
          )}
          {currentTab === 'documents' && (
            <Documents
              forceReload={forceReload}
              proposal={proposal}
              task={task}
              loadDocuments={loadDocuments}
              saveDocuments={saveDocuments}
              deleteDocument={deleteDocument}
            />
          )}
          {/* {currentTab === 'support' && <Documents />} */}
        </CardBody>
      </Card>
    </>
  );
}
