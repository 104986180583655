import Cookies from 'js-cookie';
import { useHistory } from 'react-router';
import { COOKIE_AUTH_KEY } from 'modules/auth/constants/cookies';

function useLogout() {
  const { push } = useHistory();

  const makeLogout = () => {
    Cookies.remove(COOKIE_AUTH_KEY);
    push('/login');
  };

  return { makeLogout };
}

export default useLogout;
