import React from 'react'
import Select2 from 'react-select2-wrapper'

function Select({
  id,
  placeholder,
  onChange,
  value,
  data,
  defaultValue,
  required,
  name,
  disabled
}) {
  return (
    <Select2
      id={id}
      name={name}
      className="form-control"
      options={{ placeholder: placeholder || "Selecione", disabled: disabled || false,  }}
      onChange={onChange}
      onOpen={() => document.querySelector('.select2-search__field').focus()}
      value={value}
      data={data}
      defaultValue={defaultValue}
      required={required}
    />
  )
}

export default Select
