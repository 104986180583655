import React, { useEffect, useRef, useState } from "react";
import { MessageList, Input } from "react-chat-elements";
import { InputGroup, InputGroupAddon, Button } from "reactstrap";
import moment from "moment";

import useLoggedUser from "modules/auth/hooks/use-logged-user";

export function Chat({ messages, sendMessage, chatDesabled}) {
  const formRef = React.useRef();
  const inputRef = React.useRef();
  const buttonRef = React.useRef();
  const [messageEntered, setMessageEntered] = useState('');
  const { user } = useLoggedUser();
  useEffect(() => {
    if(inputRef.current && buttonRef.current){
      if(chatDesabled){
        inputRef.current.input.disabled = 'disabled'
        buttonRef.current.disabled = 'disabled'
        return
      }
    }
  }, [inputRef, chatDesabled])

  const pushMessage = async (msg) => {
    let message = {
      position: "right",
      type: "text",
      title: user.username,
      text: msg,
      date: moment().toDate(),
      dateString: moment().format("DD/MM HH:mm"),
    };

    await sendMessage(message.text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();    
    inputRef.current.input.focus();
    if (messageEntered !== '') {
      pushMessage(messageEntered);
      setMessageEntered('');
      inputRef.current.input.value = '';
    }
  };

  return (
    <div>
      <MessageList
        className="message-list"
        lockable={true}
        toBottomHeight={"100%"}
        dataSource={messages}
      />
        <form ref={formRef} className="rce-container-form">
          <InputGroup className="mt-5">
            <Input
              className="form-control border-0 p-0"
              placeholder="Mensagem"
              ref={inputRef}
              multiline={true}
              autofocus={true}
              value={messageEntered}
              maxHeight={100}
              minHeight={46}
              onChange={(e) => setMessageEntered(e.target.value)}
            />
    
            <InputGroupAddon addonType="append">
              <Button ref={buttonRef}color="primary" onClick={handleSubmit} className="submitButton" disabled={chatDesabled}>
                <span className="btn-inner--icon">
                  <i className="fa fa-paper-plane" />
                </span>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </form>
    </div>
  );
}
