import React, { useContext, useEffect, useState} from 'react'
import { Button, Container } from 'reactstrap'
import { useHistory } from 'react-router';

import { useRouteProcess } from 'modules/common/route/route';
import { AnalysisContext } from 'modules/processes/contexts/AnalysisContext'

import ProcessCreated from './steps/ProcessCreated'
import DirectLogin from './steps/DirectLogin'
import ProcessHeaderFollowed from './ProcessHeaderFollowed'


function ProcessDirectLogin() {
  const { push } = useHistory();
  const [forceReload, setForceReload] = useState(0);

  const { params: { id, tab: currentTab } } = useRouteProcess();
  const {
    analysis,
    loadAnalysis,
    loadProjectMessages,
    sendProjectMessage,
    saveProcessChecklist,
    loadProcessChecklist,
    deleteProcessChecklist,
    setAnalysis,
  } = useContext(AnalysisContext);

  const onFinished = () => {};
  
  const handleForceReload = () => {
    setForceReload(forceReload + 1);
  };

  useEffect(() => {
    if (id === "new") {
      setAnalysis(undefined);
      return;
    }

    loadAnalysis(id);
  }, [forceReload, id]);

  useEffect(() => {
    if (id === "new") {
      return;
    }

    if (!analysis || analysis?.hash !== id) {
      setAnalysis(undefined);
      return ;
    }
  }, [analysis]);

  if (id != 'new' && !analysis) {
    return <></>;
  }

  return (
    <Container fluid className="pt-5">
      <ProcessHeaderFollowed
        analysis={analysis}
        proposalFollowed={analysis?.proposalFollowed}
      />

      <Button color="primary" className="btn-icon mb-4" onClick={() => { push("/processes") }}>
        <span className="btn-inner--icon"><i className="far fa-angle-left"></i></span>
      </Button>

      {currentTab === 'register' && (id === "new" || (id !== "new" && analysis)) && (
        <DirectLogin
          analysis={analysis}
          onFinished={onFinished}
        />
      )}
      {currentTab === 'process' && id !== "new" && (
        <ProcessCreated
          saveDocuments={saveProcessChecklist}
          loadDocuments={loadProcessChecklist}
          deleteDocument={deleteProcessChecklist}
          loadMessages={loadProjectMessages}
          sendMessage={sendProjectMessage}
          proposalFollowed={analysis.proposalFollowed}
          forceReload={handleForceReload}
        />
      )}
    </Container>
  );
}

export default ProcessDirectLogin;
