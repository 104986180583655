import React, { useState } from 'react'
import { Col, Container, Input, Row } from 'reactstrap'

import useLoggedUser  from "modules/auth/hooks/use-logged-user";
import ProcessesTable from 'modules/common/components/ProcessesTable/ProcessesTable'
import { loadAnalyses } from 'modules/common/hooks/use-analyses'
import { CONTACT_TYPE_ONLY_YOURS } from 'modules/common/route/route';

import ProcessesFilter from './ProcessesFilter'
import ProcessFilterSpotlight from './ProcessFilterSpotlight'

let searchTimeout = null;

function Processes() {
  const { user } = useLoggedUser();
  const [analyses, setAnalyses] = useState({ loading: true, directLogin: false, list: [], summary: [] });
  const [params, setParams] = useState({
    search: '',
    step: '',
    status: [],
    sort: '',
    page: 1,
    directLogin: false,
  });

  const newProcess = user?.contactType !== CONTACT_TYPE_ONLY_YOURS || (user?.contactType === CONTACT_TYPE_ONLY_YOURS && analyses.list.length === 0);

  // Primeira chamada do prepareParams é feito pela alteração do handleSearchChange
  const prepareParams = (params) => {
    setParams(params);
    setAnalyses({ ...analyses, loading: true });
    const paramsCopy = {...params};
    paramsCopy.status = paramsCopy.status.join(',');
    loadAnalyses(paramsCopy).then((data) => setAnalyses({ ...data, loading: false }));
  }

  const handleFilterClick = (step) => {
    if (params.step !== step) {
      prepareParams({...params, step, page: 1});
    }
  };

  const handleSearchChange = search => {
    if (search.length === 0 || search.length > 2) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      searchTimeout = setTimeout(() => {
        prepareParams({...params, search, page: 1});
      }, 300);
    }
  };

  const handleSelectFilterStatus = (status) => {
    prepareParams({...params, status, page: 1});
  };

  const handleDirectLogin = () => {
    prepareParams({...params, directLogin: !params.directLogin, page: 1});
  };

  const handleSort = (sort) => {
    prepareParams({...params, sort});
  };

  const setPage = (page) => {
    prepareParams({...params, page});
  };

  return (
    <Container fluid className="pt-3 pt-lg-5">
      <Row className="mb-4 d-flex align-items-center justify-content-between">
        <Col lg="6" className='d-none d-lg-block'>
          <h1 className="font-weight-bolder display-4">Processos</h1>
        </Col>

        <Col lg="6">
          <ProcessesFilter
            onSearchChange={handleSearchChange}
            selectFilterStatus={handleSelectFilterStatus}
            filterStatus={params.status}
            newProcesses={newProcess}
          />
        </Col>
      </Row>

      <Row className='processes-filter-container pb-3 pb-lg-0'>
        {Object.values(analyses.summary).map((step, i) => (
          <Col key={i} xs="6" lg="auto" className="flex-lg-grow-1">
            <ProcessFilterSpotlight
              label={step.label}
              value={step.count}
              onClick={() => handleFilterClick(step.value)}
              selected={params.step === step.value}
            />
          </Col>
        ))}
      </Row>

      {analyses.directLogin && (
        <Row className='processes-filter-container pb-3 pb-lg-3'>
          <Col>
              <div className='d-flex align-items-center'>
                <label className='custom-toggle'>
                  <Input id="directLogin" onClick={handleDirectLogin} value={params.directLogin} type="checkbox" />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
                <label htmlFor='directLogin' className='m-0 ml-2 text-sm'>Apenas login direto</label>
              </div>
          </Col>
        </Row>
      )}

      <ProcessesTable
        header={
          <h3>Meus processos</h3>
        }
        loading={analyses.loading}
        data={analyses.list}
        pagination={analyses.pagination}
        page={params.page}
        onChangePage={setPage}
        sort={params.sort}
        onSort={handleSort}
      />
    </Container>
  )
}

export default Processes
