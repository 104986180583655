import { css } from '@emotion/css';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { Container } from 'reactstrap';
import Swal from 'sweetalert2';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from "reactstrap";

import formatCurrency from 'modules/common/utils/currency-formatter'
import api from 'modules/common/utils/api';
import { config } from 'modules/common/utils/config';
import { dateTimeFormat, dateFormat } from 'modules/common/utils/date-format';
import { browserDownload, blobToDataUrl } from 'modules/common/utils/file';
import StatusBadge from 'modules/common/components/StatusBadge/StatusBadge';
import { SanitizeHTML } from 'modules/common/components/SanitizeHTML/SanitizeHTML';


const Message = ({ data }) => {
    const divider = css`
        height: 1px;
        width: 100%;
        display: block; /* for use on default inline elements like span */
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    `;

    const attachments = data.attachments || [];

    const download = (e, path, file_name) => {
        e.preventDefault();
        browserDownload(path, file_name);
    };
    
    return (
        <>
            <Row>
                <Col lg="2">
                    <div><strong>Por:</strong> {data.submitter}</div>
                    <div>{data.staff && <span style={{fontSize: '12px'}}>{data.staff}</span>}</div>
                    <div>{data.date && <span style={{fontSize: '12px'}}><strong>Respondido:</strong> {data.date && dateTimeFormat(data.date)}</span>}</div>
                </Col>
                <Col>
                    <div><strong>Mensagem:</strong></div>
                    <SanitizeHTML html={data.message} />
                    {attachments.length > 0 && <hr className={divider} />}
                    {attachments.map((attachment, i) => {
                        const file_name = attachment.file_name || "Sem nome";
                        return (
                            <div key={`attachment${i}`}>
                                <a target="_blank" href={attachment.path} onClick={(e) => download(e, attachment.path, file_name)}>{file_name}</a>
                            </div>
                        );
                    })}
                </Col>
            </Row>
        </>
    );
};

export default function TicketDetails() {
    const { id } = useParams()
    const { replace } = useHistory();
    const [ data, setData ] = useState({});
    const [ countAttachments, setCountAttachments ] = useState(1);
    const { register, handleSubmit, reset } = useForm();

    useEffect(() => {
        api.get(`/tickets/${id}`).then((data) => {
            if (data.status === 200) {
                setData(data.data);
            } else {
                Swal.fire({
                    title: 'Ticket não encontrado',
                    icon: 'error',
                }).then((result) => {
                    if (result.isConfirmed) {
                        replace(`/tickets`);
                    }
                });
            }
        });
    }, [id]);

    const sendReply = useCallback(async (formData) => {
        const attachments = [];
        for (let file of formData.attachments) {
            if (file.length === 1) {
                file = file[0]
                const data = await blobToDataUrl(file);
                attachments.push({ filename: data.name, data: data.data, });
            }
        }

        const data = { message: formData.message, attachments };        
        api.post(`/tickets/${id}`, data).then((response) => {
            if (response.status === 200) {
                setData(response.data);

                Swal.fire({
                    text: 'Mensagem enviada',
                    icon: 'success',
                    toast: true,
                    position: 'top-right',
                    timer: 1500
                });

                reset({ message: '', 'attachments[0]': ''});
                setCountAttachments(1);
            } else {
                Swal.fire({
                    title: 'Mensagem não enviada',
                    icon: 'error',
                });
            }
        });
    }, [id]);

    const addFile = () => setCountAttachments(countAttachments+1);
    const removeFile = () => setCountAttachments(countAttachments-1);

    const messageRegister = register("message", { required: true, });

    const attachmentsRegister = [];
    for (let i = 0; i < countAttachments; i++) {
        attachmentsRegister.push(register(`attachments[${i}]`, { required: false, }));
    }

    const openProcess = (id) => {
        window.open(`/redirect/${id}`);
    };

    return <Container fluid className="pt-5">
        <h1 className="font-weight-bolder display-4">Chamado {data.id}</h1>
        <Row>
            <Col lg="3">
                <Card>
                    <CardBody>
                        <CardTitle>Informações do Chamado</CardTitle>
                        <div>
                            <div>#{data.id} - Processo</div>
                            <div><strong>Departamento:</strong> {data.department}</div>
                            <div><strong>Submetido:</strong> {data.created_at && dateTimeFormat(data.created_at)}</div>
                            <div><strong>Status:</strong> {data.status_name && (
                                <StatusBadge statusBgColor={data.status_bg_color} statusTextColor={data.status_text_color} statusName={data.status_name} />
                            )}</div>
                            <div><strong>Prioridade:</strong> {data.priority}</div>
                        </div>
                    </CardBody>
                </Card>
                {data.billing && (
                    <Card>
                        <CardBody>
                            <CardTitle>Informações financeiras</CardTitle>
                            <div>
                                <div><strong>Total a faturar:</strong> {formatCurrency(data.billing.total, 'BRL')}</div>
                                <div><strong>Previsão pagamento:</strong> {dateFormat(data.billing.payment_duedate)}</div>
                                {data.billing.paid_ad !== "" && <div><strong>Pago em:</strong> {dateFormat(data.billing.paid_ad)}</div>}
                                <div><strong>Status:</strong> {data.billing.status && (
                                    <StatusBadge
                                        statusTextColor={data.billing.status_text_color}
                                        statusBgColor={data.billing.status_bg_color}
                                        statusName={data.billing.status}
                                    />
                                )}</div>

                                {data.billing.processes.length && (
                                    <>
                                        <h3 className='mt-5'>Processos</h3>
                                        <Card className="mt-2">
                                            <CardBody>
                                            { data.billing.processes.map((process, i) => (
                                                <div key={i} >
                                                    <div>{process.project_name} {formatCurrency(process.project_cost, 'BRL')}</div>
                                                    <hr className='mx-0 my-2'></hr>
                                                    {/* <div>
                                                        <Button
                                                            onClick={() => openProcess(process.project_id)}
                                                            color="secondary"
                                                            type="submit"
                                                            style={{width: "100%"}}
                                                        >
                                                            Abrir
                                                        </Button>
                                                    </div> */}
                                                </div>
                                            ))}
                                            </CardBody>
                                        </Card>
                                    </>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                )}
            </Col>
            <Col>
                <Card>
                    <CardBody>
                    <CardTitle>Responder no chamado</CardTitle>
                        <Form onSubmit={handleSubmit(sendReply)}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Mensagem</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name={messageRegister.name}
                                        innerRef={messageRegister.ref}
                                        onBlur={messageRegister.onBlur}
                                        onChange={messageRegister.onChange}
                                        type="textarea"
                                        rows="4"
                                    />
                                </InputGroup>
                            </FormGroup>
                            {attachmentsRegister.map((attachmentRegister, i) => (
                                <FormGroup key={`uploadfile${i}`}>
                                    <InputGroup>
                                        {i === 0 ? 
                                        <InputGroupAddon addonType="prepend" onClick={addFile} className={css`cursor: pointer;`}>
                                            +
                                        </InputGroupAddon>
                                        :
                                        <InputGroupAddon addonType="prepend" onClick={removeFile} className={css`cursor: pointer;`}>
                                            -
                                        </InputGroupAddon>
                                        }
                                        <input
                                            name={attachmentRegister.name}
                                            ref={attachmentRegister.ref}
                                            onBlur={attachmentRegister.onBlur}
                                            onChange={attachmentRegister.onChange}
                                            // className={classNameAttachment}
                                            className="form-control"
                                            lang="pt-br"
                                            type="file"
                                            extension="jpg,png,pdf,doc"
                                            filesize={config().UPLOAD_FILE_SIZE || 2097152}
                                            accept=".jpg,.png,.pdf,.doc,image/jpeg,image/png,application/pdf,application/msword"
                                        />
                                    </InputGroup>
                                </FormGroup>
                            ))}
                            <FormGroup>
                                <Row className="justify-content-md-center">
                                    <Button color="primary" type="submit">
                                        Enviar resposta
                                    </Button>
                                </Row>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle>Chamado</CardTitle>
                        <Message data={{ message: data.message, submitter: data.submitter, staff: data.staff }} />
                    </CardBody>
                </Card>
                {data.replies?.map((replie, i) =>
                    <Card key={`replie${i}`}>
                        <CardBody>
                            <Message key={`replie_message${i}`} data={replie} />
                        </CardBody>
                    </Card>
                )}
            </Col>
        </Row>
    </Container>;
}