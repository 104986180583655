import React, { useEffect } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap'
import { isEmpty } from 'ramda'
import { useFormik } from "formik";

import CurrencyInput from 'modules/common/components/CurrencyInput/CurrencyInput'

import formikValidateInput from "modules/common/validations/formik-validate-input";
import getFinanceAmountSchema from "modules/processes/validators/finance-amount";
import { useDetectChange } from 'modules/common/context';

export function FinanceAmountCgi({ onSubmit, analysis, products, productSelected=1 }) {
  const { setChange } = useDetectChange();

  const formik = useFormik({
    initialValues: {
      productId: String(productSelected),
      propertyPrice: analysis?.propertyPrice ?? '',
      mortgage: analysis?.mortgage ?? '',
      installments: analysis?.installments,
      isFinanceDocumentation: analysis?.isFinanceDocumentation,
      financeAnalysisAndRatings: analysis?.isFinanceAnalysisAndRatings,
      isUsingFgts: analysis?.isUsingFgts,
    },
    validationSchema: getFinanceAmountSchema(products),
    onSubmit
  });

  // useEffect(() => {
  //   if (!isEmpty(products)) {
  //     formik.setFieldValue('productId', String(analysis?.productId || products[0].id));
  //   }
  // }, [products]);

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      {/* <FormGroup>
        <label htmlFor="productId">Produto</label>
        <Input
          id="productId"
          type="select"
          name="productId"
          value={formik.values.productId}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'productId')}
        >
          {products.map(product => (
            <option
              key={product.id}
              value={product.id}
            >{product.name}</option>
          ))}
        </Input>
        <FormFeedback>
          {formik.errors.productId}
        </FormFeedback>
      </FormGroup> */}
      <FormGroup>
        <label htmlFor="propertyPrice">Valor do imóvel</label>
        <CurrencyInput
          id="propertyPrice"
          placeholder="R$ digite aqui"
          name="propertyPrice"
          onChange={formik.handleChange}
          defaultValue={formik.values.propertyPrice}
          invalid={formikValidateInput(formik, 'propertyPrice')}
          required
        />
        <FormFeedback>
          {formik.errors.propertyPrice}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="mortgage">Valor financiado</label>
        <CurrencyInput
          id="mortgage"
          placeholder="R$ digite aqui"
          name="mortgage"
          onChange={formik.handleChange}
          value={formik.values.mortgage}
          invalid={formikValidateInput(formik, 'mortgage')}
          required
        />
        <FormFeedback>
          {formik.errors.mortgage}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="installments">Prazo (em meses)</label>
        <Input
          id="installments"
          placeholder="Digite aqui..."
          type="number"
          name="installments"
          defaultValue={formik.values.installments}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'installments')}
          required
        />
        <FormFeedback>
          {formik.errors.installments}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="custom-control custom-checkbox">
        <Input
          id="isFinanceDocumentation"
          type="checkbox"
          name="isFinanceDocumentation"
          className="custom-control-input"
          onChange={e => formik.setFieldValue('isFinanceDocumentation', e.target.checked)}
          checked={formik.values.isFinanceDocumentation}
          invalid={formikValidateInput(formik, 'isFinanceDocumentation')}
        />
        <label
          htmlFor="isFinanceDocumentation"
          className="custom-control-label"
        >
          Financiar ITBI/Cartório/IOF? (A depender da instituição e Produto)
        </label>
        <FormFeedback>
          {formik.errors.isFinanceDocumentation}
        </FormFeedback>
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Salvar e continuar</Button>
    </Form>
  )
}
