import React, { useState } from 'react'
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap'
import Swal from 'sweetalert2'

import useRecoverPassword from 'modules/auth/hooks/use-recover-password'

function ForgotPasswordForm({ onBackToLogin }) {
  const [ isLoading, setIsLoading ] = useState(false)
  const { makeRecoverPassword } = useRecoverPassword()

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault()
    const email = e.target.email.value

    try {
      setIsLoading(true)
      const success = await makeRecoverPassword({ email })
      setIsLoading(false)

      if (!success) return handleError()

      Swal.fire({
        title: 'Sucesso!',
        html: `Um e-mail foi enviado para <strong>${email}</strong> com as instruções para recuperar sua senha.`,
        icon: 'success',
        focusConfirm: true,
        returnFocus: false,
      }).then(onBackToLogin)
    } catch (e) {
      handleError()
    }
  }

  const handleError = () => {
    Swal.fire({
      title: 'Erro ao enviar e-mail de recuperação',
      text: 'Tente novamente mais tarde',
      icon: 'error',
    })
    setIsLoading(false);
  };

  return (
    <>
      {/* <Loading isOpen={isLoading} /> */}

      <img
        className="w-25 mb-auto"
        alt="Credihabitar"
        src={require("assets/img/brand/credihabitar-1.svg").default}
      />

      <div>
        <h1 className="display-2 font-weight-bolder">Recupere sua senha</h1>
        <h2 className="font-weight-normal text-muted">Você receberá em seu e-mail cadastrado um link para redefinir a sua senha.</h2>
      </div>

      <Form className="py-5" onSubmit={handleForgotPasswordSubmit}>
        <FormGroup>
          <label htmlFor="email">Seu e-mail</label>
          <Input
            required
            id="email"
            name="email"
            placeholder="Digite seu endereço de e-mail"
          />
        </FormGroup>

        <Button
          block
          color="primary"
          disabled={isLoading}
        >
          {isLoading && <Spinner size="sm"></Spinner>}
          {!isLoading && "Enviar por e-mail"}
        </Button>
      </Form>

      <div className="mt-auto">
        <p>Já possui conta? <a href="#" onClick={onBackToLogin}>Entre aqui</a></p>
        <p>Ao utilizar nosso serviço, você concorda com nossos <a href="#">Termos de uso</a></p>
      </div>
    </>
  )
}

export default ForgotPasswordForm
