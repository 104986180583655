import React from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'reactstrap'

function CnpjInput({
  id,
  value,
  onChange,
  defaultValue,
  required,
  name,
  invalid,
}) {
  return (
    <InputMask
      id={id}
      placeholder="12.345.678/9101-11"
      mask="99.999.999/9999-99"
      alwaysShowMask={false}
      maskPlaceholder=""
      defaultValue={defaultValue}
      required={required}
      value={value}
      name={name}
      onChange={onChange}
    >
      <Input invalid={invalid} />
    </InputMask>
  )
}

export default CnpjInput
