import { StyleSheet } from '@react-pdf/renderer';

const COL_COUNT = 5;
const COL_WIDTH = 100 / COL_COUNT;

export const reviewTableWidths = ['30%', '10%', '20%', '20%', '20%'];
export const installmentsTableWidths = ['5%', '20%', '15%', '20%', '20%', '20%'];

export const styles = StyleSheet.create({
  tableContainer: {
    border: '1px solid lightgray',
    padding: '10px',
  },
  table: { 
    display: "table", 
    width: "auto", 
  }, 
  tableRow: {
    display: 'flex',
    margin: "auto", 
    flexDirection: "row",
    alignItems: 'center'
  },
  tableColHeader: {
    width: COL_WIDTH + "%", 
    fontSize: 12,
    color: '#FF5185'
  },
  tableCol: {
    height: '100%',
    width: COL_WIDTH + "%",
    textAlign: "center",
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  tableColDark: {
    backgroundColor: '#E4E4E4',
  },
  tableCellHeader: {
    margin: 5, 
    fontSize: 10,
    textAlign: "center",
  },
  tableCell: { 
    margin: 5, 
    fontSize: 9,
  },
  bankLogo: {
    width: 25,
  },
})
