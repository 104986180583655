import useSWR from "swr";
import api from "modules/common/utils/api";
import { defaultSwrOptions } from "modules/common/utils/swr-options";

const fetcher = (...args) => api.get(...args).then(res => res.data.map(city =>({
  ...city,
  pkId: city.id,
})))

function useCities(stateId) {
  const url = stateId ? `/customers/states/${stateId}/cities` : null
  const { data, error } = useSWR(url, fetcher, defaultSwrOptions)

  return {
    cities: data || [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useCities

