import React, { createContext, useContext, useCallback } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import Swal from 'sweetalert2';

export const DetectDataChangeContext = createContext({
    change: false,
    setChange: null,
    canChange: null,
});

let change = false;

export const DetectDataChangeProvider = ({ children }) => {
    const setChange = (value) => {
        change = value;
    };

    const canChange = () => {
        return new Promise((resolve, reject) => {
            if (change) {
                Swal.fire({
                    title: 'Importante',
                    html: 'Ao sair dessa página, alguns dados serão perdidos. Deseja continuar?',
                    icon: 'warning',
                    showCloseButton: false,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Sair sem salvar',
                    confirmButtonAriaLabel: 'Sair sem salvar',
                    cancelButtonText: 'Voltar',
                    cancelButtonAriaLabel: 'Voltar',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary order-2 mx-0',
                        actions: 'd-flex justify-content-end px-4',
                        cancelButton: 'btn btn-secondary mr-auto'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        setChange(false);
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
        
    };

    useBeforeunload(useCallback((event) => {
        if (change) {
            event.preventDefault();
        }
    }, [change]));

    return (
        <DetectDataChangeContext.Provider
          value={{
            change,
            setChange,
            canChange,
          }}
        >
          {children}
        </DetectDataChangeContext.Provider>
      );
};

export const useDetectChange = () => useContext(DetectDataChangeContext);
