import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Button, Col, Form, FormGroup, Input, Row, Spinner } from 'reactstrap'
import Swal from 'sweetalert2'

import useLogin from 'modules/auth/hooks/use-login'
import useLogout from 'modules/auth/hooks/use-logout'
import { lgpdConfirm } from 'modules/common/components/Lgpd/Lgpd'

function LoginForm({ onForgotPassword }) {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const submitRef = useRef();
  const params = useParams();
  const { makeLogin } = useLogin();
  const { makeLogout } = useLogout();
  const { replace } = useHistory();
  const [ loginState, setLoginState] = useState({ loading: false, steps: 0 });

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    setLoginState({ loading: true, steps: 3 });
    const username = e.target.username.value
    const password = e.target.password.value

    try {
      const { success, termsAgreed } = await makeLogin({ username, password });
      if (!success) {
        setLoginState({ loading: false, steps: 1 });
        return;
      }
      if (termsAgreed) {
        if (params.redirect) {
          replace(decodeURIComponent(params.redirect));
        } else {
          replace('/');
        }
        return;
      } else {
        lgpdConfirm({
          confirm: () => replace('/'),
          reject: () => makeLogout(),
        });
      }
      setLoginState({ loading: false, steps: 2 });

    } catch (e) {
      setLoginState({ loading: false, steps: 1 });
    }
  };

  useEffect(() => {
    switch(loginState.steps) {
      case 0:
        usernameRef.current.focus();
        return;
      case 1:
        Swal.fire({
          title: 'Erro ao fazer login',
          text: 'Confira seu login e senha e tente novamente',
          icon: 'error',
          focusConfirm: true,
          returnFocus: false,
        }).then(() => {
          setLoginState({ loading: false, steps: 2 });
          passwordRef.current.focus();
        });
        return;
      case 2:
        passwordRef.current.focus();
        return;
      case 3:
        usernameRef.current.blur();
        passwordRef.current.blur();
        return;
      default:
        return;
    }
  }, [loginState]);

  return (
    <>
      <img
        className="img-login mb-4"
        alt="Credihabitar"
        src="/imgs/logo-ch.svg"
      />
      <div>
        <h1 className="display-3 mt-3 mt-lg-5 font-weight-bolder">
          Que bom ter você aqui!
        </h1>
        <h2 className="font-weight-normal text-muted">
          Vamos em busca de agilidade e melhores resultados.
        </h2>
      </div>

      <Form className="py-5" onSubmit={handleLoginSubmit}>
        <FormGroup>
          <label className="d-none" htmlFor="loginInput">
            Seu e-mail
          </label>
          <Input
            type='email'
            id="loginInput"
            innerRef={usernameRef}
            name="username"
            placeholder="E-mail"
            required
          />
        </FormGroup>
        <FormGroup>
          <label className="d-none" htmlFor="passwordInput">
            Senha
          </label>
          <Input
            id="passwordInput"
            innerRef={passwordRef}
            placeholder="Senha"
            type="password"
            name="password"
            required
          />
        </FormGroup>

        <Row>
          <Col xs="8" lg="6" className="mx-auto">
            <Button
              block
              color="primary"
              innerRef={submitRef}
              disabled={loginState.loading}
            >
              {loginState.loading && <Spinner size="sm"></Spinner>}
              {!loginState.loading && "Entrar"}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs="5" className="align-self-center pr-0">
            <div className="w-100 h-0 border border" />
          </Col>
          <Col xs="2">
            <p className="text-center my-3 font-weight-bold">Ou</p>
          </Col>
          <Col xs="5" className="align-self-center pl-0">
            <div className="w-100 h-0 border border" />
          </Col>
        </Row>

        <Row>
        <Col xs="8" className="mx-auto">
            <a className='btn btn-block btn-link' href='https://credihabitar.com.br/correspondente-credenciado/' target={"_blank"} >
              Conheça as condições de Parceria
            </a>
          </Col>
        </Row>
      </Form>

      <div className="mt-auto">
        <p>
          Esqueceu sua senha?{" "}
          <a href="#" onClick={onForgotPassword}>
            Recupere aqui
          </a>
        </p>
        <p>
          Ao utilizar nosso serviço, você concorda com nossos{" "}
          <a href="https://credihabitar.com.br/politica-privacidade/#termo-uso" target={"_blank"}>Termos de uso</a>
        </p>
      </div>
    </>
  );
}

export default LoginForm
