import React, { useEffect } from 'react'
import {Button, Form, FormFeedback, FormGroup, Input} from 'reactstrap'
import {useFormik} from "formik";

import mapIdNameToSelect from 'modules/common/utils/id-name-mapper'
import CellphoneInput from 'modules/common/components/CellphoneInput/CellphoneInput'
import CpfInput from 'modules/common/components/CpfInput/CpfInput'
import DateInput from 'modules/common/components/DateInput/DateInput'
import Select from 'modules/common/components/Select/Select'

import {leadInfoSchema} from "modules/processes/validators/lead-info";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";

import { useDetectChange } from 'modules/common/context';

export function LeadInfo({
  onSubmit,
  customer,
  isMain,
  simulationBirthDate,
  genders,
}) {
  const { setChange } = useDetectChange();

  const formik = useFormik({
    initialValues: {
      name: customer?.fullName,
      cpf: customer?.cpf,
      birthDate: customer?.birthDate || simulationBirthDate || '',
      genderId: customer?.gender,
      email: customer?.email,
      cellphone: customer?.cellphone,
      isMain,
      id: customer?.pkHash
    },
    onSubmit,
    validationSchema: leadInfoSchema,
  });

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup>
        <label htmlFor="name">Nome completo</label>
        <Input
          id="name"
          placeholder="Digite aqui"
          name="name"
          onChange={formik.handleChange}
          defaultValue={formik.values.name}
          invalid={formikValidateInput(formik, 'name')}
        />
        <FormFeedback>
          {formik.errors.name}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="cpf">CPF</label>
        <CpfInput
          id="cpf"
          placeholder="Digite aqui"
          name="cpf"
          onChange={formik.handleChange}
          defaultValue={formik.values.cpf}
          invalid={formikValidateInput(formik, 'cpf')}
        />
        <FormFeedback>
          {formik.errors.cpf}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="genderId">Gênero</label>
        <Select
          id="genderId"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(genders)}
          value={formik.values.genderId}
          onChange={formik.handleChange}
          name="genderId"
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'genderId')}>
          {formik.errors.genderId}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="email">Email</label>
        <Input
          id="email"
          placeholder="Digite aqui"
          type="email"
          name="email"
          onChange={formik.handleChange}
          defaultValue={formik.values.email}
          invalid={formikValidateInput(formik, 'email')}
        />
        <FormFeedback>
          {formik.errors.email}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="birthDate">Data de nascimento</label>
        <DateInput
          id="birthDate"
          placeholder="Digite aqui"
          name="birthDate"
          onChange={formik.handleChange}
          value={formik.values.birthDate}
          invalid={formikValidateInput(formik, 'birthDate')}
        />
        <FormFeedback>
          {formik.errors.birthDate}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="cellphone">Telefone Celular</label>
        <CellphoneInput
          id="cellphone"
          placeholder="(99) 99999-9999"
          name="cellphone"
          onChange={formik.handleChange}
          defaultValue={formik.values.cellphone}
          invalid={formikValidateInput(formik, 'cellphone')}
        />
        <FormFeedback>
          {formik.errors.cellphone}
        </FormFeedback>
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Salvar e continuar</Button>
    </Form>
  );
}
