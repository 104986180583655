import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { last } from 'ramda'

import getAvatarAbbreviation from 'modules/common/utils/get-avatar-abbreviation';
import useConfirmationDialog from 'modules/common/hooks/use-confirmation-dialog';
import { AnalysisContext } from 'modules/processes/contexts/AnalysisContext';

import { ClientCustomer } from './client/ClientCustomer';
import { ClientBidder } from './client/ClientBidder';
import { ClientCarAssuranceCustomer } from './client/ClientCustomerCarAssurance';
import { ClientCustomerCGI } from './client/ClientCustomerCGI';

function Client({ currentWizard, setCurrentWizard, analysis, onFinished, carAssurance=false,productSelected, currentTab }) {
  const { deleteCustomer } = useContext(AnalysisContext);
  const [ isNewBidderForm, setIsNewBidderForm ] = useState(false);
  const [ selectedBidder, setSelectedBidder ] = useState();

  const {
    confirmationDialog: deleteBuyerDialog,
    withConfirmation: withDeleteBuyerConfirmation
  } = useConfirmationDialog({
    title: 'Deletar proponente?',
    content: 'Deseja deletar o proponente?',
  });

  const {
    confirmationDialog: addBuyerDialog,
    withConfirmation: withAddBuyerConfirmation,
  } = useConfirmationDialog({
    title: 'Criar proponente?',
    content: 'Deseja criar um novo proponente?',
    skip: isNewBidderForm,
  });

  const showBidderWizard = (bidder) => {
    setIsNewBidderForm(false);
    setSelectedBidder(bidder);
  };

  const createNewBidderWizard = () => {
    setIsNewBidderForm(true);
    setSelectedBidder();
  };

  const showCustomerWizard = () => {
    setIsNewBidderForm(false);
    setSelectedBidder(null);
  };

  const deleteCustomerAndReloadState = async (id) => {
    await deleteCustomer(id);
    setIsNewBidderForm(false);
    setSelectedBidder(null);
  };

  useEffect(() => {
    if (analysis && analysis.bidders) {
      analysis.bidders = analysis.bidders?.sort(( a, b ) => {
        a = new Date(a.createdAt)
        b = new Date(b.createdAt)
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    }

    if (isNewBidderForm && !selectedBidder) {
      if (analysis.bidders.length > 0) {
        setSelectedBidder(last(analysis.bidders));
        setIsNewBidderForm(false);
      }
    } else if (selectedBidder) {
      setSelectedBidder(analysis.bidders.find((bidder) => bidder.pkHash === selectedBidder.pkHash));
    }
  }, [analysis]);

  return (
    <section>
      {deleteBuyerDialog}
      {addBuyerDialog}

      <div className='d-flex align-items-center my-4 bidder-menu'>
        {analysis?.customer && (
          <a
            className={classnames(
              'avatar avatar-md rounded-circle mr-2 bg-lighter text-primary',
              { 'border border-primary': !selectedBidder && !isNewBidderForm }
            )}
            role='button'
            onClick={showCustomerWizard}
          >
            {getAvatarAbbreviation(analysis.customer.fullName)}
          </a>
        )}

        {analysis?.bidders?.map(bidder => (
          <a
            key={bidder.pkHash}
            className={classnames(
              'avatar avatar-md rounded-circle mr-2 bg-lighter text-primary',
              { 'border border-primary': (selectedBidder?.pkHash === bidder.pkHash) && !isNewBidderForm }
            )}
            role='button'
            onClick={() => showBidderWizard(bidder)}
          >
            {getAvatarAbbreviation(bidder.fullName)}
          </a>
        ))}

        {isNewBidderForm && (
          <a
            className={classnames(
              'avatar avatar-md rounded-circle mr-2 bg-lighter text-primary border border-primary',
            )}
            role='button'
          >
            <i className="fas fa-user" />
          </a>
        )}

        {(analysis?.customer?.pkHash && currentTab && currentTab !== 'create-cgv') && (
          <>
            <UncontrolledTooltip target="add_bidder" placement="right">
              Adicionar proponente
            </UncontrolledTooltip>
            <div
              id="add_bidder"
              className='avatar avatar-md rounded-circle bg-primary-light'
              role='button'
              onClick={() => withAddBuyerConfirmation(createNewBidderWizard)()}
            >
              <i className="fal fa-user-plus text-primary" />
            </div>
          </>
        )}

        {selectedBidder && (
          <Button
            className='ml-auto'
            outline
            color='primary'
            onClick={
              () => withDeleteBuyerConfirmation(deleteCustomerAndReloadState)(selectedBidder.pkHash)
            }
          >
            <i className="far fa-trash mr-2"></i> Excluir proponente
          </Button>
        )}
      </div>

      {(!selectedBidder && !isNewBidderForm && carAssurance && currentTab === 'create-cgv') && (
        <ClientCarAssuranceCustomer
          currentWizard={currentWizard}
          setCurrentWizard={setCurrentWizard}
          analysis={analysis}
          onFinished={onFinished}
          productSelected={productSelected}
          
        />)}

      {(!selectedBidder && !isNewBidderForm && !carAssurance && currentTab === 'create-aquisicao' ) && (
        <ClientCustomer
          currentWizard={currentWizard}
          setCurrentWizard={setCurrentWizard}
          analysis={analysis}
          onFinished={onFinished}
          productSelected={productSelected}
          createBidder={
            () => createNewBidderWizard()
          }
        />
      )}
      {(!selectedBidder && !isNewBidderForm && !carAssurance && currentTab === 'create-cgi' ) && (
        <ClientCustomerCGI
          currentWizard={currentWizard}
          setCurrentWizard={setCurrentWizard}
          analysis={analysis}
          onFinished={onFinished}
          productSelected={productSelected}
          createBidder={
            () => createNewBidderWizard()
          }
        />
      )}
      {(isNewBidderForm || selectedBidder) && (
        <ClientBidder analysis={analysis} bidder={selectedBidder} />
      )}
    </section>
  );
}

export default Client;
