import { useHistory } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import useLoggedUser from "modules/auth/hooks/use-logged-user";
import useLogout from "modules/auth/hooks/use-logout";
import { lgpdShow } from "modules/common/components/Lgpd/Lgpd";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const { makeLogout } = useLogout()
  const { user } = useLoggedUser()
  const {replace} = useHistory()

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand",
          "border border-left-0 border-top-0 border-right-0",
          { "navbar-dark bg-white": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>         
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" },
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>

              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link d-none" color="" tag="a">
                  <img
                    alt="notification bell"
                    src={require("assets/img/icons/notification.svg").default}
                  />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      Você tem <strong className="text-info">1</strong>{" "}
                      notificações.
                    </h6>
                  </div>

                  <ListGroup flush>
                    <ListGroupItem
                      className="list-group-item-action"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      tag="a"
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {/* <img
                            alt="..."
                            className="avatar rounded-circle"
                            src={require("assets/img/theme/team-1.jpg").default}
                          /> */}
                        </Col>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="mb-0 text-sm">Jessica Rodrigues Sousa</h4>
                            </div>
                            <div className="text-right text-muted">
                              <small>2 hrs atras</small>
                            </div>
                          </div>
                          <p className="text-sm mb-0">
                            Aguardando o envio do documento de renda
                            por parte do proponente sendo que a lista
                            contém mais que os obrigatórios.
                          </p>
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>

                  <DropdownItem
                    className="text-center text-dark font-weight-bold py-3"
                    onClick={(e) => e.preventDefault()}
                  >
                    Ver todas as notificações
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>

            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <img
                    alt="person icon"
                    src={require("assets/img/icons/person.svg").default}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Olá, {user?.firstname}!</h6>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      replace("/announcements")
                    }}
                  >
                    <i className="far fa-bullhorn"/>
                    <span>Quadro de avisos</span>
                  </DropdownItem>
                  <DropdownItem className="d-none"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="far fa-user" />
                    <span>Meu perfil</span>
                  </DropdownItem>
                  <DropdownItem className="d-none"
                    onClick={() => lgpdShow()}
                  >
                    {/* <i className="far fa-user" /> */}
                    <span>LGPD</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault()
                      makeLogout()
                    }}
                  >
                    <i className="far fa-sign-out" />
                    <span>Sair</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
