import * as Yup from 'yup';

export const documentsSchema = Yup.object().shape({
  documentNumber: Yup.string().required('Campo obrigatório'),
  documentIssuedBy: Yup.string().required('Campo obrigatório'),
  documentIssueDate: Yup.string().required('Campo obrigatório'),
  documentIssuedStateId: Yup.string().required('Campo obrigatório'),
  homeStateId: Yup.string().required('Campo obrigatório'),
  homeCityId: Yup.string().required('Campo obrigatório'),
})

export default documentsSchema;
