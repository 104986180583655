import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Container } from "reactstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router";

import { useDetectChange } from "modules/common/context";
import { useRouteProcess } from "modules/common/route/route";
import { AnalysisContext } from "modules/processes/contexts/AnalysisContext";
import { CONTACT_ANALYSIS_READ_ONLY } from "modules/common/route/route";
import useLoggedUser from "modules/auth/hooks/use-logged-user";

import Client from "./steps/Client";
import ClientRead from "./steps/ClientRead";
import CreditAnalysis from "./steps/CreditAnalysis";
import ProcessCreated from "./steps/ProcessCreated";
import ProcessHeaderFollowed from "./ProcessHeaderFollowed";
import { DIRECT_LOGIN } from "modules/processes/constants";
import SelectProduct from "./processSelectProduct";

function ProcessDetails() {
  const { push, replace } = useHistory();
  const {
    push: pushProcess,
    params: { id, tab: currentTab },
  } = useRouteProcess();
  const setCurrentTab = (value) => {
    pushProcess({ tab: value, step: undefined, stepTab: undefined });
  };

  const { user } = useLoggedUser();
  const readOnly = user?.contactType === CONTACT_ANALYSIS_READ_ONLY;

  const showDirectLogin =
    id === "new" &&
    !!user?.products?.find((p) => p.tplAccessTypeId === String(DIRECT_LOGIN));
  const { canChange } = useDetectChange();
  const [forceReload, setForceReload] = useState(0);
  const [enabledSteps, setEnabledSteps] = useState([]);
  const [carAssurance, setCarAssurance] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const {
    analysis,
    loadAnalysis,
    loadProjectMessages,
    sendProjectMessage,
    createProcess,
    saveProcessChecklist,
    loadProcessChecklist,
    deleteProcessChecklist,
    setAnalysis,
    createProposalAnalysis,
    proposalProductDetails,
    currentWizard,
    setCurrentWizard,
    deleteProcess,
  } = useContext(AnalysisContext);
  // Vem da api como numero do produto criado
  
  const productCgvFinv = ["cgv", 'finv']
  const productCGI = ['cgi']

  let tabs = [
    {
      index: "create-aquisicao",
      title: "Cadastro",
      icon: "fa fa-address-card",
    },
    {
      index: "pre-analysis",
      title: "Pré-Análise",
      icon: "fa fa-file-chart-line",
    },
  ];
  
  if(analysis && productCGI.includes(analysis.product.categorySlug) || currentTab === "create-cgi"){
    tabs = [
      {
        index: "create-cgi",
        title: "Cadastro",
        icon: "fa fa-address-card",
      },
      {
        index: "pre-analysis",
        title: "Pré-Análise",
        icon: "fa fa-file-chart-line",
      },
    ]
    
  }
  if(analysis && productCgvFinv.includes(analysis.product.categorySlug) || currentTab === "create-cgv"){
    tabs = [
      {
        index: "create-cgv",
        title: "Cadastro",
        icon: "fa fa-address-card",
      },
      {
        index: "pre-analysis",
        title: "Pré-Análise",
        icon: "fa fa-file-chart-line",
      },
    ]
    if(!carAssurance){
     setCarAssurance(true)
    }
  }


  if (!readOnly) {
    tabs.push({
      index: "process",
      title: "Processo criado",
      icon: "fa fa-file",
    });
  }

  const handleForceReload = () => {
    setForceReload(forceReload + 1);
  };

  const createProcessReload = async (id) => {
    const data = await createProcess(id);
    setForceReload(forceReload + 1);
    return data;
  };

  const openTab = async (tab) => {
    if (!(await canChange())) {
      return;
    }

    if (id === "new" || !analysis) {
      setCurrentTab(tabs[0].index);
    } else {
      setCurrentTab(tab);
    }
  };

  const openTabProcess = () => {
    openTab(tabs[2].index);
  };

  const handleDeleteProcess = () => {
    Swal.fire({
      title: 'Confirma a exclusão do registro?',
      icon: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Voltar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary order-2 mx-0',
        cancelButton: 'btn btn-secondary mr-auto',
        actions: 'd-flex justify-content-end px-4'
      }
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const response = await deleteProcess(id);
        if (response) {
          push("/processes");
        }
      }
    });
  };
  
  useEffect(() => {
    if (id === "new" && !currentTab) {
      setAnalysis(undefined);
      setCurrentTab('create');
      setCurrentWizard(0);
      return;
    }
    if(id === 'new' && currentTab){
      setAnalysis(undefined);
      setCurrentWizard(0);
      setCurrentTab(currentTab)
      setCanShow(true)
      return;
    }

    loadAnalysis(id);
  }, [forceReload, id]);

  useEffect(() => {
    
    if (id === "new" && !currentTab) {
      setCurrentTab('create')
      return;
    }

    if (!analysis || analysis?.hash !== id) {
      setAnalysis(undefined);
      return;
    }

    const enabledSteps = [tabs[0].index];

    if (analysis.completed) {
      enabledSteps.push(tabs[1].index);
    }
    if (!readOnly && analysis.completed && analysis.proposalFollowed) {
      enabledSteps.push(tabs[2].index);
    }
    setEnabledSteps(enabledSteps);


    if (currentTab) {
      if (!enabledSteps.includes(currentTab)) {
        setCurrentTab(tabs[0]?.index);
      }
    } else {
      if (analysis.proposalFollowed) {
        setCurrentTab("process");
      } else if (analysis.completed) {
        setCurrentTab("pre-analysis");
        enabledSteps.push("pre-analysis");
      } else {
        setCurrentTab(tabs[0]?.index);
      }
    }
  }, [currentTab, analysis]);

  if (id != "new" && !analysis) {
    return <></>;
  }

  return (
    <Container fluid className="pt-5">
      {!readOnly && (
        <ProcessHeaderFollowed
          analysis={analysis}
          proposalFollowed={analysis?.proposalFollowed}
        />
      )}
      <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
        {tabs.map((tab, index) => (
          <Button
            key={index}
            className={classnames("btn-icon", {
              active: currentTab === tab.index,
            })}
            style={{ opacity: !enabledSteps.includes(tab.index) && "0.4" }}
            color="secondary"
            onClick={() => openTab(tab.index)}
            disabled={!enabledSteps.includes(tab.index)}
          >
            <span className="btn-inner--icon">
              <i className={tab.icon} />
            </span>
            <span className="btn-inner--text">{tab.title}</span>
          </Button>
        ))}
      </ButtonGroup>

      {showDirectLogin && (
        <>
          <Button
            color="primary"
            outline
            className="mt-4 mt-lg-0"
            size="sm"
            onClick={() => push("/direct/new/register")}
          >
            Proposta via login direto. Clique aqui
          </Button>
        </>
      )}

      {id !== 'new' && analysis?.proposals?.filter((proposal) => !!proposal?.projectId).length === 0 && (<>
        <Button
          color='primary'
          outline
          className='mt-4 mt-lg-0'
          size='sm'
          onClick={() => handleDeleteProcess()}
        >
            <i className="far fa-trash mr-2"></i>Excluir cadastro
        </Button>
      </>)}

      
      <SelectProduct
        canShow={canShow}
        setCanShow={setCanShow}
        currentTab={currentTab} 
        id={id} 
        readOnly={readOnly} 
        analysis={analysis} 
        currentWizard={currentWizard}
        setCurrentWizard={setCurrentWizard}
        setCurrentTab={setCurrentTab}
        setCarAssurance={setCarAssurance}
        carAssurance={carAssurance}
        tabs={tabs}
      />

      {currentTab === "pre-analysis" && id !== "new" && (
        <CreditAnalysis
          forceReload={handleForceReload}
          openTabProcess={openTabProcess}
          createProposalAnalysis={createProposalAnalysis}
          proposalProductDetails={proposalProductDetails}
          createProcess={createProcessReload}
          analysis={analysis}
          loadMessages={loadProjectMessages}
          sendMessage={sendProjectMessage}
          saveDocuments={saveProcessChecklist}
          loadDocuments={loadProcessChecklist}
          deleteDocument={deleteProcessChecklist}
          readOnly={readOnly}
          setForceReload={setForceReload}
        />
      )}
      {currentTab === "process" && id !== "new" && (
        <ProcessCreated
          forceReload={handleForceReload}
          saveDocuments={saveProcessChecklist}
          loadDocuments={loadProcessChecklist}
          deleteDocument={deleteProcessChecklist}
          loadMessages={loadProjectMessages}
          sendMessage={sendProjectMessage}
          proposalFollowed={analysis.proposalFollowed}
        />
      )}
    </Container>
  );
}

export default ProcessDetails;
