import React, { useEffect } from 'react'
import {Button, Form, FormFeedback, FormGroup, Input} from 'reactstrap'
import {useFormik} from "formik";
import CurrencyInput from 'modules/common/components/CurrencyInput/CurrencyInput'
import mapIdNameToSelect from 'modules/common/utils/id-name-mapper'
import CellphoneInput from 'modules/common/components/CellphoneInput/CellphoneInput'
import DateInput from 'modules/common/components/DateInput/DateInput'
import Select from 'modules/common/components/Select/Select'
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import { getRegisterSchema } from "modules/processes/validators/income";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import CnpjInput from 'modules/common/components/CnpjInput/CnpjInput'

import { useDetectChange } from 'modules/common/context';

export function Income({ jobTitles, jobTypes, contractTypes, onSubmit, customer }) {
  const { setChange } = useDetectChange();

  const sanitizeBeforeSubmit = (values) => {
    const contractType = contractTypes.find(contract => Number(contract.id) === Number(values.contractType));
    if (!contractType.show_company_form) {
      values.cnpj = '';
    }
    
    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      jobType: customer?.income?.jobType || '',
      jobTitle: customer?.income?.jobTitle || '',
      contractType: customer?.income?.contractType || '',
      cnpj: customer?.income?.cnpj || '',
      companyName: customer?.income?.companyName || '',
      phone: customer?.income?.phone || '',
      dateOfEmployment: customer?.income?.dateOfEmployment || '',
      monthlyIncome: customer?.income?.monthlyIncome || '',
      customerId: customer?.pkHash || '',
      incomeId: customer?.income?.pkHash || '',
    },
    onSubmit: sanitizeBeforeSubmit,
    validationSchema: getRegisterSchema(contractTypes),
  });

  const contractType = contractTypes.find(contract => Number(contract.id) === Number(formik.values.contractType));

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input readOnly hidden name="customerId" value={customer?.pkHash} />
      <Input readOnly hidden name="incomeId" value={customer?.income?.pkHash} />

      <FormGroup>
        <label htmlFor="contractType">Tipo de renda</label>
        <Select
          id="contractType"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(contractTypes)}
          value={formik.values.contractType}
          onChange={e => e.target.value && formik.handleChange(e)}
          name="contractType"
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'contractType')}>
          {formik.errors.contractType}
        </FormFeedbackSelect>
      </FormGroup>
      {contractType?.show_company_form && (
        <FormGroup>
          <label htmlFor="cnpj">CNPJ</label>
          <CnpjInput
            id="cnpj"
            name="cnpj"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            defaultValue={formik.values.cnpj}
            invalid={formikValidateInput(formik, 'cnpj')}
          />
          <FormFeedback>
            {formik.errors.cnpj}
          </FormFeedback>
        </FormGroup>
      )}
      <FormGroup>
        <label htmlFor="jobType">Cargo</label>
        <Select
          id="jobType"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(jobTypes)}
          name='jobType'
          value={formik.values.jobType}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'jobType')}>
          {formik.errors.jobType}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="jobTitle">Profissão</label>
        <Select
          id="jobTitle"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(jobTitles)}
          name='jobTitle'
          value={formik.values.jobTitle}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'jobTitle')}>
          {formik.errors.jobTitle}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="companyName">Nome da Empresa</label>
        <Input
          id="companyName"
          placeholder="Digite aqui..."
          name="companyName"
          defaultValue={formik.values.companyName}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'companyName')}
        />
        <FormFeedback>
          {formik.errors.companyName}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="phone">Telefone</label>
        <CellphoneInput
          id="phone"
          placeholder="(99) 99999-9999"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'phone')}
        />
        <FormFeedback>
          {formik.errors.phone}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="dateOfEmployment">Data de admissão</label>
        <DateInput
          id="dateOfEmployment"
          name="dateOfEmployment"
          value={formik.values.dateOfEmployment}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'dateOfEmployment')}
        />
        <FormFeedback>
          {formik.errors.dateOfEmployment}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="mb-5">
        <label htmlFor="monthlyIncome">Renda mensal</label>
        <CurrencyInput
          id="monthlyIncome"
          placeholder="R$ Digite aqui..."
          name="monthlyIncome"
          value={formik.values.monthlyIncome}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'monthlyIncome')}
        />
        <FormFeedback>
          {formik.errors.monthlyIncome}
        </FormFeedback>
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Salvar e continuar</Button>
    </Form>
  )
}
