import React, { useState } from 'react'
import { FormFeedback, Input } from 'reactstrap'
import isValidCep from 'modules/common/utils/is-valid-cep'
import { loadCep } from 'modules/common/hooks/use-analyses-basic-data'

function CepInput({
  id,
  placeholder,
  defaultValue,
  onChangeAddress,
  required,
  name,
  onChange,
  value: valueProp,
  invalid,
}) {
  const [errorMessage, setErrorMessage] = useState()

  const validateAndUpdateInput = e => {
    const rawValue = e.target.value.trim()
    if (rawValue.length > 8) return
  }

  const fetchAddress = (e) => {
    const { value } = e.target
    if (!isValidCep(value)) return
    loadCep(value)
      .then((data) => {
        onChangeAddress(data);
        setErrorMessage('');
      })
      .catch((e) => {
        onChangeAddress({});
        setErrorMessage(e.response.data?.[0]);
      });
  }

  return (
    <>
      <Input
        id={id}
        type='text'
        inputMode='numeric'
        name={name}
        placeholder={placeholder}
        onChange={e => {
          validateAndUpdateInput(e)
          fetchAddress(e)
          onChange(e)
        }}
        required={required}
        invalid={Boolean(errorMessage) || invalid}
        defaultValue={defaultValue}
        maxLength={8}
      />

      <FormFeedback>{errorMessage}</FormFeedback>
    </>
  )
}

export default CepInput
