import React, { useState, useCallback, useEffect } from "react";
import Swal from "sweetalert2";

import { dateTimeFormat } from 'modules/common/utils/date-format';
import { Chat } from "modules/common/components/Chat/Chat"

export function AnalysisChat({ task, proposal, loadMessages, sendMessage, forceReload }) {
  const [messages, setMessages] = useState([]);
  const [chatDesabled, setChatDesabled] = useState(false);

  const mapMessages = (data) => {
    if (data) {
      if(!data.accept_new_comment){
        setChatDesabled(true)
      }
      data = data.comments.map((data) => ({
        position: data.role === 'customer' ? "right" : "left",
        type: "text",
        title: data.role === 'customer' ? null : data.added_from,
        text: data.comment,
        date: new Date(data.created_at),
        dateString: dateTimeFormat(data.created_at, 'DD/MM HH:mm'),
      }));

      setMessages(data);
    } else {
      Swal.fire({
        title: 'Ocorreu um erro',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmBtnStyle: 'error'
      });
    }
  };
  const sendTaskMessages = useCallback(
    (message) =>
      sendMessage(proposal.pkHash, task.id, message).then((res) => {
        mapMessages(res);
        if (res.message_type) {
          Swal.fire({
            // title: 'Ocorreu um erro',
            html: res.message_content,
            icon: res.message_type,
            confirmButtonText: "Ok",
            confirmBtnStyle: "error",
          });
        }

        if (res.project_status_id !== false || res.task_status_id !== false) {
          forceReload()
        }
      }),
    [task]
  );

  useEffect(() => {
    loadMessages(proposal.pkHash, task.id).then(mapMessages);
  }, [task]);

  return (
    <Chat messages={messages} sendMessage={sendTaskMessages} chatDesabled={chatDesabled} />
  );
}
