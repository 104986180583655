import camelcaseKeysDeep from "camelcase-keys-deep"
import { useEffect, useState } from "react"
import api from "modules/common/utils/api"

function useProducts() {
  const [products, setProducts] = useState([])

  useEffect(() => {
    loadProducts().then(setProducts);
  }, []);

  return products
}

export const loadProducts = () => new Promise((resolve, reject) => {
  api.get('/simulations/products')
    .then(response => {
      if (response.status === 200) {
        const data = response.data.map(camelcaseKeysDeep);
        resolve(data);
      } else {
        resolve([]);
      }
    });
});

export default useProducts
