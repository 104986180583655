import React from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'reactstrap'

function getCellphoneRawValue(value) {
  return value.replace(/\D/g, '')
}

function CellphoneInput({
  id,
  placeholder,
  defaultValue,
  required,
  value,
  name,
  onChange,
  invalid,
}) {
  return (
    <InputMask
      id={id}
      placeholder={placeholder}
      mask="(99) 99999-9999"
      alwaysShowMask={false}
      maskPlaceholder=""
      defaultValue={defaultValue}
      required={required}
      value={value}
      name={name}
      onChange={onChange}
    >
      <Input invalid={invalid} />
    </InputMask>
  )
}

export { getCellphoneRawValue }
export default CellphoneInput
