import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';

import StatusBadge from 'modules/common/components/StatusBadge/StatusBadge';
import { formatCurrency, formatNumber } from 'modules/common/utils/currency-formatter'
import { useRouteProcess } from 'modules/common/route/route';
import { PropertyEvaluation } from 'modules/processes/pages/process_details/steps/processes/PropertyEvaluation';

export function Details({ forceReload, analysis, proposal, saveDocuments, loadDocuments, deleteDocument, loadMessages, sendMessage, onClose }) {
  const { push, params: { stepTab } } = useRouteProcess();
  const project = proposal?.project;
  const tasks = project?.tasks; 
  const task = tasks?.find((task) => task.id === proposal?.project?.currentTaskId);

  if (!proposal) {
    return <></>;
  }
  
  return (
    <section className="mt-4">
      <Row>
        <Col lg="3">
          <Card>
            <CardHeader className="d-flex justify-content-between border-bottom pb-5">
              <Button color="primary" className="btn-icon" onClick={onClose}>
                <span className="btn-inner--icon"><i className="far fa-angle-left"></i></span>
                <span className="btn-inner--text">Voltar</span>
              </Button>
              <img
                alt="logo"
                src={proposal?.product?.logo}
                width='30px'
                height='30px'
              />
            </CardHeader>
            <CardBody>
              <div>
              <div className="d-flex justify-content-between border-bottom pb-2">
                  <span className="mr-5">Banco</span>
                  <span>{proposal?.product?.bank}</span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                  <span className="mr-5">Produto</span>
                  <span>{analysis?.product?.label}</span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 align-items-md-center">
                  <span className="mt-2 mr-5">Status Pré-Análise</span>
                  <span className="mt-1">
                    {proposal?.status?.textColor ? <StatusBadge statusBgColor={proposal.status.bgColor} statusTextColor={proposal.status.textColor} statusName={proposal.status.label} /> : '--'}
                  </span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 align-items-md-center">
                  <span className="mt-2 mr-5">Status</span>
                  <span className="mt-1">
                    {proposal?.projectStatus?.label ? <StatusBadge statusBgColor={proposal.projectStatus.bgColor} statusTextColor={proposal.projectStatus.textColor} statusName={proposal.projectStatus.label} /> : '--'}
                  </span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                  <span className="mr-5">Valor do imóvel</span>
                  <span>{analysis?.propertyPrice ? formatCurrency(analysis.propertyPrice, 'BRL') : '--'}</span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                  <span className="mr-5">Valor solicitado</span>
                  <span>{proposal?.mortgageRequested ? formatCurrency(proposal.mortgageRequested, 'BRL') : '--'}</span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                  <span className="mr-5">Valor aprovado</span>
                  <span>{proposal?.mortgageApproved && proposal?.mortgageApproved !== '0.00' ? formatCurrency(proposal.mortgageApproved, 'BRL') : '--'}</span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                  <span className="mr-5">Prazo aprovado (meses)</span>
                  <span>{!!proposal?.installmentsApproved ? proposal.installmentsApproved : '--'}</span>
                </div>
                <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                  <span className="mr-5">Taxa de juros (CET)</span>
                  <span>{proposal?.mortgageFee && proposal?.mortgageFee !== '0.00' ? `${formatNumber(proposal.mortgageFee)}%` : '--'}</span>
                </div>

                {(proposal?.meta || []).map((meta, i) => (
                  <div key={i} className="d-flex justify-content-between border-bottom pb-2 mt-2">
                    <span className="mr-5">{meta.metaKey}</span>
                    <span>{meta.value}</span>
                  </div>
                ))}

              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="9">
          <Card>
            <CardBody>
              {task && (
                <PropertyEvaluation
                  forceReload={forceReload}
                  currentTab={stepTab}
                  setCurrentTab={(value) => push({ stepTab: value })}
                  loadMessages={loadMessages}
                  sendMessage={sendMessage}
                  saveDocuments={saveDocuments}
                  loadDocuments={loadDocuments}
                  deleteDocument={deleteDocument}
                  task={task}
                  proposal={proposal}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </section>
  );
}
