
export function formatCurrency(value, currency) {
  if (!value) {
    return value;
  }
  const reaisFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency,
  })

  return reaisFormatter.format(value)
}

export function formatNumber(value) {
  const intl = new Intl.NumberFormat('pt-BR')
  return intl.format(value)
}

export default formatCurrency
