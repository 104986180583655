import * as Yup from 'yup'
import formatCurrency from "../../common/utils/currency-formatter";
import moment from "moment";

const getFinanceAmountSchema = (products, isSimulation = false) => {
  return Yup.object().shape({
    productId: Yup.number().required('Campo obrigatório'),
    propertyPrice: Yup
      .number()
      .test('minValue', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value >= validations?.minGoodPrice) return true

        return this.createError({ message: `O valor mínimo é ${formatCurrency(validations?.minGoodPrice, 'BRL')}`})
      })
      .test('maxValue', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value <= validations?.maxGoodPrice) return true

        return this.createError({ message: `O valor máximo é ${formatCurrency(validations?.maxGoodPrice, 'BRL')}`})
      })
      .required('Campo obrigatório'),
    mortgage: Yup
      .number()
      .test('minValue', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value >= validations?.minLoanValue) return true

        return this.createError({ message: `O valor mínimo é ${formatCurrency(validations?.minLoanValue, 'BRL')}`})
      })
      .test('maxValue', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true

        const maxLoanValue = parent?.propertyPrice * validations?.maxLoanPercent
        if (value <= maxLoanValue) return true

        return this.createError({ message: `O valor máximo é ${formatCurrency(maxLoanValue, 'BRL')}`})
      })
      .required('Campo obrigatório'),
    installments: Yup
      .number()
      .test('minInstallments', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value >= validations?.minTimeMonths) return true

        return this.createError({ message: `O valor mínimo é ${validations?.minTimeMonths}`})
      })
      .test('maxInstallments', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value <= validations?.maxTimeMonths) return true

        return this.createError({ message: `O valor máximo é ${validations?.maxTimeMonths}`})
      })
      .required('Campo obrigatório'),
    isFinanceDocumentation: Yup.boolean().default(false),
    financeAnalysisAndRatings: Yup.boolean().default(false),
    isUsingFgts: Yup.boolean().default(false),
    birthDate: Yup.string().test('required', function (value, { parent }) {
      if (!isSimulation) return true

      if (!value) return this.createError({ message: 'Campo obrigatório' })

      const validations = products.find(product => product.id === parent?.productId)
      if (!validations) return true

      if (moment().diff(moment(value), 'years') < validations?.minAge) {
        return this.createError({ message: `O cliente deve ter no mínimo ${validations?.minAge} anos`})
      }

      if (moment().diff(moment(value), 'years') > validations?.maxAge) {
        return this.createError({ message: `O cliente deve ter no máximo ${validations?.maxAge} anos`})
      }

      return true
    })
  })
}

export default getFinanceAmountSchema;
