import React, { useContext, useEffect, useState } from "react";
import { zipObject } from "lodash";
import { Col, Row } from "reactstrap";

import {
  loadJobTitles,
  loadJobTypes,
  loadResidenceTimeUnit,
  loadGenders,
  loadMarriageStatus,
  loadMarriageSystems,
  loadContractTypes,
} from 'modules/common/hooks/use-analyses-basic-data';
import { AnalysisContext } from "modules/processes/contexts/AnalysisContext";

import { HomeAddress, Income, LeadInfo, RegisterData, Documents } from './wizard';
import { ListeSteps } from "./ListeSteps";

export const ClientBidder = ({ analysis, bidder }) => {
    const { 
      submitCustomer,
      submitMarriageData,
      submitAddress,
      submitIncome,
      submitDocuments,
    } = useContext(AnalysisContext);
  
    const wizardSteps = [
      {
        index: 0,
        title: 'Dados pessoais 1/2',
        component: (props) => <LeadInfo
          genders={analysisBasicData.genders}
          customer={bidder}
          {...props}
        />,
        onSubmit: submitCustomer,
        loadData: () => ({
          genders: loadGenders(),
        }),
      },
      { 
        index: 1,
        title: 'Dados pessoais 2/2',
        component: (props) => <RegisterData
          marriageStatus={analysisBasicData.marriageStatus}
          marriageSystems={analysisBasicData.marriageSystems}
          genders={analysisBasicData.genders}
          customer={bidder}
          {...props}
        />,
        onSubmit: submitMarriageData,
        loadData: () => ({
          marriageStatus: loadMarriageStatus(),
          marriageSystems: loadMarriageSystems(),
          genders: loadGenders(),
        }),
      },
      { 
        index: 2,
        title: 'Documentos',
        component: (props) => <Documents
          customer={bidder}
          {...props}
        />,
        onSubmit: submitDocuments,
      },
      {
        index: 3,
        title: 'Endereço residencial',
        component: (props) => <HomeAddress
          residenceTimeUnits={analysisBasicData.residenceTimeUnits}
          customer={bidder}
          {...props} />,
        onSubmit: submitAddress,
        loadData: () => ({
          residenceTimeUnits: loadResidenceTimeUnit(),
        }),
      },
      {
        index: 4,
        title: 'Renda',
        component: (props) => <Income
          jobTitles={analysisBasicData.jobTitles}
          jobTypes={analysisBasicData.jobTypes}
          contractTypes={analysisBasicData.contractTypes}
          customer={bidder} {...props}
        />,
        onSubmit: submitIncome,
        loadData: () => ({
          jobTitles: loadJobTitles(),
          jobTypes: loadJobTypes(),
          contractTypes: loadContractTypes(),
        }),
      },
    ];
    const [analysisBasicData, setAnalysisBasicData] = useState({
      genders: [],
      marriageStatus: [],
      marriageSystems: [],
      residenceTimeUnits: [],
      jobTitles: [],
      jobTypes: [],
      contractTypes: [],
    });
    const [ currentWizard, setCurrentWizard ] = useState(0);
    const [ finishedWizards, setFinishedWizards ] = useState([]);
    const step = wizardSteps.find(step => step.index === currentWizard);

    const submitStep = async (event) => {
      event.preventDefault?.();
      await step.onSubmit?.(event, analysis);
      nextStep();
    };
  
    const nextStep = () => {
      const currentStep = step.index
      setFinishedWizards(finished => [...finished, currentStep]);
      setCurrentWizard(current => {
        if (wizardSteps.length - 1 === current) {
          return current;
        }
        current = currentStep + 1;
        return current;
      });
    };
  
    const loadBidderState = (bidder) => {
      const finishedWizards = [];
      if (bidder?.income) finishedWizards.push(4);
      if (bidder?.address) finishedWizards.push(3);
      if (bidder?.documentNumber) finishedWizards.push(2);
      if (bidder?.marriageStatus) finishedWizards.push(1);
      if (bidder?.pkHash) finishedWizards.push(0);
    
      const currentWizard = Math.min(Math.max(...finishedWizards, 0) + 1, 7);
      return { currentWizard, finishedWizards };
    };

    useEffect(() => {
      if (!analysis || !bidder) {
        return;
      }

      const { finishedWizards, currentWizard } = loadBidderState(bidder);
      setFinishedWizards(finishedWizards);
      setCurrentWizard(currentWizard);
    }, []);
  
    useEffect(() => { 
      if (!analysis || !bidder) {
        if (!bidder) {
          setFinishedWizards([]);
          setCurrentWizard(0);
        }
        return;
      }

      const { finishedWizards } = loadBidderState(bidder);
      setFinishedWizards(finishedWizards);

    }, [analysis, bidder]);

    useEffect(() => {
      (async () => {
        const step = wizardSteps.find(step => step.index === currentWizard);
        if (step && step.loadData) {
          const data = step.loadData();
          const dataDone = await Promise.all(Object.values(data));

          setAnalysisBasicData(({
            ...analysisBasicData,
            ...zipObject(Object.keys(data), dataDone),
          }));
        }  
      })();
    }, [currentWizard]);
  
    return (
      <Row>
        <ListeSteps wizardSteps={wizardSteps} finishedWizards={finishedWizards} currentWizard={currentWizard} setCurrentWizard={setCurrentWizard} />
  
        <Col lg="4" md="12">
          {step && <step.component onSubmit={submitStep} />}
        </Col>
      </Row>
    );
  };