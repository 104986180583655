import React from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'reactstrap'

const maskToCurrency = ({ nextState }) => {
  const { value } = nextState || {}

  let amountFormatted = value?.replace?.(/\D/g, '')
  amountFormatted = amountFormatted?.replace?.(/^0+/g, '')

  if (amountFormatted?.length === 2) {
    return {
      ...nextState,
      value: `R$ ${amountFormatted}`,
      selection: {
        start: amountFormatted.length + 3,
        end: amountFormatted.length + 3
      }
    }
  }

  const amountFormattedWithComma = amountFormatted?.replace?.(
    /(?=\d{2})(\d{2})$/,
    ',$1'
  )
  const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
    /(\d)(?=(\d{3})+(?!\d))/g,
    '$1.'
  )

  if (amountFormattedWithDot) {
    return {
      ...nextState,
      value: `R$ ${amountFormattedWithDot}`,
      selection: {
        start: amountFormattedWithDot.length + 3,
        end: amountFormattedWithDot.length + 3
      }
    }
  }

  return nextState
}

function getCurrencyRawValue(value) {
  const hasComma = value.includes(',')
  const hasFloatingPoint = value.includes('.')

  if (hasFloatingPoint) {
    return value.replace(/(R\$|\.)/gi, '').replace(',', '.')
  }

  if (hasComma) {
    return value.replace('R$ ', '').replace(',', '.')
  }

  return value.replace('R$ ', '')
}

function CurrencyInput({
  id,
  placeholder,
  defaultValue,
  value,
  onChange,
  required,
  name,
  invalid,
}) {
  return (
    <InputMask
      id={id}
      name={name}
      placeholder={placeholder}
      mask="R$ 9999999999999"
      alwaysShowMask={false}
      beforeMaskedStateChange={maskToCurrency}
      maskPlaceholder=""
      defaultValue={defaultValue}
      value={value}
      onChange={e => {
        const customEvent = {
          target: {
            value: getCurrencyRawValue(e.target.value),
            name: e.target.name,
          }
        }
        onChange?.(customEvent)
      }}
      required={required}
    >
      <Input invalid={invalid}/>
    </InputMask>
  )
}

export { getCurrencyRawValue }
export default CurrencyInput
