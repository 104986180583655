import api from "modules/common/utils/api"
import { loadData } from "./common";

export const loadConsultants = () => loadData(`/consultants`);
export const loadAllConsultants = (consultantId) => loadData(`/consultants/all/${consultantId}`);

export const changeConsultant = ({ consultant_id, new_consultant_id, message }) => new Promise((resolve, reject) => {
  api.post(`/consultants/change`, { consultant_id, new_consultant_id, message })
    .then(response => resolve(response.status === 200))
    .catch(reject);
});
