import React, { useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import Swal from 'sweetalert2'

import { AnalysisContext } from 'modules/processes/contexts/AnalysisContext';
import { DIRECT_LOGIN } from 'modules/processes/constants';
import { CUSTOMER_SOURCE_CONVERSION } from 'modules/processes/constants';

function Redirect() {
  const { getIdsByProjectId } = useContext(AnalysisContext);
  const { replace } = useHistory();
  const params = useParams();

  useEffect(() => {
    getIdsByProjectId(params.project).then((project) => {
      if (!project) {
        Swal.fire({
          title: 'Processo não encontrado',
          // text: 'Tente novamente mais tarde',
          icon: 'error',
        }).then(() => {
          replace('/dashboard');
        })
        return;
      }

      if (project?.proposalFollowed?.product?.access_type_id === DIRECT_LOGIN || project?.customer?.source?.id === CUSTOMER_SOURCE_CONVERSION) {
        replace(`/direct/${project.hash.toString()}`);        
      } else {
        const task = project.proposals.find((p) => p.projectId === Number(params.project));
        if (params.task) {
          replace(`/processes/${project.hash.toString()}/process/${params.task}/chat`);
        } else if (project?.proposalFollowed) {
          replace(`/processes/${project.hash.toString()}/process/${task.pkHash}/chat`);
        } else {
          replace(`/processes/${project.hash.toString()}/pre-analysis/${task.pkHash}/chat`);
        }
      }
    });
  }, [])

  return (
    <>
      
    </>
  )
}

export default Redirect
