import * as Yup from 'yup';
import cpfValidator from "../../common/validations/cpf";

export const leadInfoSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  cpf: Yup.string().required('CPF é obrigatório').concat(cpfValidator),
  birthDate: Yup.string().required('Data de nascimento é obrigatório'),
  genderId: Yup.string().required('Gênero é obrigatório'),
  email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  cellphone: Yup.string().required('Celular é obrigatório'),
})
