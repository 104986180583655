import { useEffect, useState } from "react";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import { isEmpty } from "ramda";
import CurrencyInput from "modules/common/components/CurrencyInput/CurrencyInput";
import DateInput from "modules/common/components/DateInput/DateInput";
import useProducts from "modules/common/hooks/use-products";
import { useFormik } from "formik";
import getFinanceAmountSchema from "../../../processes/validators/finance-amount";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import Select from "modules/common/components/Select/Select";
import mapIdNameToSelect from "modules/common/utils/id-name-mapper";
import SimulationFormCGV from './FormCGV'
import SimulationForm from './Form'


function Form({ onSubmit }) {
  const products = useProducts();
  const [typeOfProduct, setTypeOfProduct] = useState()
  const [showFormCGV, setShowFormCGV] = useState(false)

  useEffect(() => {
    if (!isEmpty(products)) {
      setTypeOfProduct(products[0].id);
    }
  }, [products]);

  useEffect(() => {
    const productCgvFinv = ["4", "5"]
    if(productCgvFinv.includes(typeOfProduct)){
      return setShowFormCGV(true)
    }
    return setShowFormCGV(false)
  }, [typeOfProduct])
    
  return (
    <>
      <h1 className="display-3 font-weight-bolder">Simulador</h1>

      <label htmlFor="productId">Produto</label>
      <Select
        id="productId"
        type="select"
        name="productId"
        defaultValue={typeOfProduct}
        data={products?.map((product) => ({
          id: product.id,
          text: product.name,
        }))}
        onChange={(e) => {
          setTypeOfProduct(e.target.value);
        }}
      />
      {showFormCGV ? (
        <SimulationFormCGV productId={typeOfProduct} onSubmit={onSubmit}/>
      ) : (
        <SimulationForm productId={typeOfProduct} onSubmit={onSubmit}/>
      )}
    </>
  );
}

export default Form;
