import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'reactstrap'
import Swal from "sweetalert2";

import { useDetectChange } from 'modules/common/context';

function validate(purchaseEstimate) {
  return purchaseEstimate !== 0 && purchaseEstimate !== undefined && purchaseEstimate !== null && purchaseEstimate !== ''
}

export function BuyingDate({ purchaseEstimates, onSubmit, analysis }) {
  const [option, setOption] = useState(0)
  const { setChange } = useDetectChange();

  useEffect(() => {
    if (analysis?.purchaseEstimate) {
      setOption(analysis.purchaseEstimate);
    }
  }, [analysis?.purchaseEstimate]);

  useEffect(() => {
    setChange(option !== analysis?.purchaseEstimate);
  }, [option, analysis?.purchaseEstimate]);

  const submit = (event) => {
    event.preventDefault()
    if (!validate(option)) {
      return Swal.fire({
        title: 'Erro',
        text: 'Por favor, selecione uma opção',
        icon: 'error',
      })
    }

    onSubmit(option)
  }

  return (
    <Form onSubmit={submit}>
      {purchaseEstimates.map((opt, index) => (
        <div
          key={opt.id}
          onClick={() => setOption(opt.id)}
          className={classnames(
            {
              "mb-5": index === purchaseEstimates.length -1,
              "border border-primary btn-wizard--selected": option === opt.id,
            },
            "rounded py-4 px-4 text-dark mb-3 btn-wizard-light font-weight-bold"
          )}
          role="button"
        >
          <div className="custom-control custom-radio">
            <input
              className="custom-control-input"
              type="radio"
              checked={option === opt.id}
              onChange={() => {}}
            />
            <label className="custom-control-label">
              {opt.name}
            </label>
          </div>
        </div>
      ))}

      <Button
        block
        outline
        color="default"
        type="submit"
      >Salvar e continuar</Button>
    </Form>
  )
}
