import React, { useEffect } from 'react'
import {Button, Form, FormFeedback, FormGroup, Input} from 'reactstrap'
import {useFormik} from "formik";
import useCountries from 'modules/common/hooks/use-countries'
import mapIdNameToSelect from 'modules/common/utils/id-name-mapper'
import CellphoneInput from 'modules/common/components/CellphoneInput/CellphoneInput'
import DateInput from 'modules/common/components/DateInput/DateInput'
import CpfInput from 'modules/common/components/CpfInput/CpfInput'
import Select from 'modules/common/components/Select/Select'
import {getRegisterSchema} from "modules/processes/validators/register-data";
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import formikValidateInput from "modules/common/validations/formik-validate-input";

import { useDetectChange } from 'modules/common/context';

export const PARTNER_DEFAULT_DATA = {
  partnerBirthDate: '',
  partnerCpf: '',
  partnerGender: '',
  partnerName: '',
  partnerSameAddress: '',
}

export const MARRIAGE_STATUS_DEFAULT_DATA = {
  marriageSystemId: '',
  weddingDate: '',
}

export function RegisterData({ marriageStatus, marriageSystems, genders, onSubmit, customer }) {
  const { countries } = useCountries();
  const { setChange } = useDetectChange();

  const sanitizeBeforeSubmit = (values) => {
    const status = marriageStatus.find(status => status.id === +values.marriageStatus)

    if (!values.stableUnion && !status.showPartnerForm) {
      return onSubmit({
        ...values,
        ...PARTNER_DEFAULT_DATA,
        ...(status.showMarriageForm ? {} : MARRIAGE_STATUS_DEFAULT_DATA),
      })
    }

    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      motherName: customer?.motherName || '',
      homeCountry: customer?.homeCountry || '',
      residentialPhone: customer?.residentialPhone || '',
      marriageStatus: customer?.marriageStatus || '',
      marriageSystemId: customer?.marriageSystem || '',
      weddingDate: customer?.weddingDate || '',
      stableUnion: customer?.hasStableUnion || false,
      unionDate: customer?.stableUnionDate || '',
      partnerBirthDate: customer?.partnerBirthDate || '',
      partnerName: customer?.partnerName || '',
      partnerCpf: customer?.partnerCpf || '',
      partnerGender: customer?.partnerGender || '',
      partnerSameAddress: customer?.isPartnerSameAddress || false,
      id: customer?.pkHash,
    },
    onSubmit: sanitizeBeforeSubmit,
    validationSchema: getRegisterSchema(marriageStatus),
  });

  const status = marriageStatus.find(status => status.id === +formik.values.marriageStatus);

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input hidden name="id" value={customer?.pkHash} readOnly />

      <FormGroup>
        <label htmlFor="homeCountry">Nacionalidade</label>
        <Select
          id="homeCountry"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={countries.map(({ pkId, nationality }) => ({ id: pkId, text: nationality }))}
          name="homeCountry"
          value={formik.values.homeCountry}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'homeCountry')}>
          {formik.errors.homeCountry}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="residentialPhone">Telefone residencial</label>
        <CellphoneInput
          id="residentialPhone"
          placeholder="(99) 99999-9999"
          name="residentialPhone"
          value={formik.values.residentialPhone}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'residentialPhone')}
        />
        <FormFeedback>
          {formik.errors.residentialPhone}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="motherName">Nome completo da mãe</label>
        <Input
          id="motherName"
          placeholder="Digite aqui"
          name="motherName"
          value={formik.values.motherName}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'motherName')}
        />
        <FormFeedback>
          {formik.errors.motherName}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="marriageStatus">Estado civil</label>
        <Select
          id="marriageStatus"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(marriageStatus)}
          name="marriageStatus"
          value={formik.values.marriageStatus}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'marriageStatus')}>
          {formik.errors.marriageStatus}
        </FormFeedbackSelect>
      </FormGroup>
      {status?.showMarriageForm && (
        <>
          <FormGroup>
            <label htmlFor="marriageSystemId">Regime de casamento</label>
            <Select
              id="marriageSystemId"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              data={mapIdNameToSelect(marriageSystems)}
              name="marriageSystemId"
              value={formik.values.marriageSystemId}
              onChange={e => e.target.value && formik.handleChange(e)}
            />
            <FormFeedbackSelect invalid={formikValidateInput(formik, 'marriageSystemId')}>
              {formik.errors.marriageSystemId}
            </FormFeedbackSelect>
          </FormGroup>
          <FormGroup>
            <label htmlFor="weddingDate">Data do casamento</label>
            <DateInput
              id="weddingDate"
              name="weddingDate"
              value={formik.values.weddingDate}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'weddingDate')}
            />
            <FormFeedback>
              {formik.errors.weddingDate}
            </FormFeedback>
          </FormGroup>
        </>
      )}
      {status?.showStableUnionOption && (
        <FormGroup>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="d-block">União estável</span>
              <span className="h4 font-weight-normal text-muted">
                O cliente está em um relacionamento de união estável?
              </span>
            </div>

            <label htmlFor="stableUnion" className="custom-toggle ml-auto">
              <input
                id="stableUnion"
                type="checkbox"
                name="stableUnion"
                checked={formik.values.stableUnion}
                onChange={e => formik.setFieldValue('stableUnion', e.target.checked)}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </div>
        </FormGroup>
      )}
      {formik.values.stableUnion && status?.showStableUnionOption && (
        <FormGroup>
          <label htmlFor="unionDate">Data da união</label>
          <DateInput
            id="unionDate"
            name="unionDate"
            value={formik.values.unionDate}
            onChange={formik.handleChange}
            invalid={formikValidateInput(formik, 'unionDate')}
          />
          <FormFeedback>
            {formik.errors.unionDate}
          </FormFeedback>
        </FormGroup>
      )}

      {(status?.showPartnerForm || formik.values.stableUnion) && (
        <>
          <div className="text-primary font-weight-bold mb-3">
            {formik.values.stableUnion ? 'Dados do Parceiro' : 'Dados do Cônjuge'}
          </div>
          <FormGroup className="mt-3">
            <label htmlFor="partnerName">Nome completo</label>
            <Input
              id="partnerName"
              placeholder="Digite aqui"
              name="partnerName"
              value={formik.values.partnerName}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'partnerName')}
            />
            <FormFeedback>
              {formik.errors.partnerName}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-3">
            <label htmlFor="partnerCpf">CPF</label>
            <CpfInput
              id="partnerCpf"
              name="partnerCpf"
              value={formik.values.partnerCpf}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'partnerCpf')}
            />
            <FormFeedback>
              {formik.errors.partnerCpf}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-3">
            <label htmlFor="partnerGender">Sexo</label>
            <Select
              id="partnerGender"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              data={mapIdNameToSelect(genders)}
              name="partnerGender"
              value={formik.values.partnerGender}
              onChange={e => e.target.value && formik.handleChange(e)}
            />
            <FormFeedbackSelect invalid={formikValidateInput(formik, 'partnerGender')}>
              {formik.errors.partnerGender}
            </FormFeedbackSelect>
          </FormGroup>
          <FormGroup>
            <label htmlFor="partnerBirthDate">Data de nascimento</label>
            <DateInput
              id="partnerBirthDate"
              name="partnerBirthDate"
              value={formik.values.partnerBirthDate}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'partnerBirthDate')}
            />
            <FormFeedback>
              {formik.errors.partnerBirthDate}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="d-block">Moram no mesmo endereço?</span>
                <span className="h4 font-weight-normal text-muted">
                  O cliente vive no mesmo endereço que o cônjuge
                </span>
              </div>

              <label htmlFor="partnerSameAddress" className="custom-toggle ml-auto">
                <input
                  id="partnerSameAddress"
                  type="checkbox"
                  name="partnerSameAddress"
                  checked={formik.values.partnerSameAddress}
                  onChange={e => formik.setFieldValue('partnerSameAddress', e.target.checked)}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>
          </FormGroup>
        </>
      )}

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Salvar e continuar</Button>
    </Form>
  )
}
