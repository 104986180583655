import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  // Font
} from '@react-pdf/renderer';
import {
  styles as firstTableStyles,
  firstTableWidths,
} from './first-table-styles'
import {
  styles as secondTableStyles,
  reviewTableWidths, installmentsTableWidths,
} from './second-table-styles'
import formatCurrency from "../../../common/utils/currency-formatter";
import { head } from "ramda";

const styles = StyleSheet.create({
  page: {
    paddingTop: 25,
    paddingBottom: 45,
    paddingHorizontal: 15,
  },
  section: {
    padding: '15pt 25pt',
  },
  logo: {
    marginTop: 25,
    marginBottom: 25,
    marginLeft: 35,
    width: 200
  },
  header: {
    color: '#FF5185',
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 12,
  },
  smalltext: {
    fontSize: 10,
    color: 'gray'
  },
  listItem: {
    fontSize: 12,
    marginBottom: 5
  },
  chatBubble: {
    padding: 10,
    backgroundColor: '#FF5185',
    borderRadius: 10,
    width: '50%',
  },
  chatBubbleText: {
    color: 'white',
    fontSize: 12,
    marginBottom: 5,
  },
  headerCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 50
  },
  headerCenterImage: {
    width: 50,
    borderRadius: '50%',
    marginRight: 10,
  },
  headerCenterText: {}
});

function SimulationPdf({ result, propertyPrice }) {
  const getTacValue = ({ tac, dfi, mip }) => tac + dfi + mip
  const getMessage = () => {
    let message = ''
    switch (result.productType) {
      case 'aqu':
        message = `
        * Os resultados exibidos neste documetno referem-se a simulação. As taxas de juros são apenas referenciais, podendo variar conforme perfil de crédito/aprovação do cliente. O valor da parcela apresentado sofrerá alterações de acordo com o valor da avaliação do imóvel. O valor do seguro DFI também está sujeito a alteração, uma vez que este incide sobre o valor de avaliação do imóvel. As condições eixbidas poderão ser alteradas ou descontinuadas a qualquer momento, sem aviso prévio. Em caso de a tarifa de avaliação não ser incorporada ao financiamento, ela será debitada integralmente da conta informada pelo cliente.`

        break;
      case 'cgi':
        message = `
        * Os resultados exibidos neste documetno referem-se a simulação. As taxas de juros são apenas referenciais, podendo variar conforme perfil de crédito/aprovação do cliente. O valor da parcela apresentado sofrerá alterações de acordo com o valor da avaliação do imóvel. O valor do seguro DFI também está sujeito a alteração, uma vez que este incide sobre o valor de avaliação do imóvel. As condições eixbidas poderão ser alteradas ou descontinuadas a qualquer momento, sem aviso prévio. Em caso de a tarifa de avaliação não ser incorporada ao financiamento, ela será debitada integralmente da conta informada pelo cliente.`

        break;
      case 'cgv':
        message = `
        * Os resultados exibidos neste documetno referem-se a simulação. As taxas de juros são apenas referenciais, podendo variar conforme perfil de crédito/aprovação do cliente. O valor da parcela apresentado sofrerá alterações de acordo com o valor da avaliação do veículo em garantia. As condições eixbidas poderão ser alteradas ou descontinuadas a qualquer momento, sem aviso prévio.`

        break;
      default:
        message = `
        * Os resultados exibidos neste documetno referem-se a simulação. As taxas de juros são apenas referenciais, podendo variar conforme perfil de crédito/aprovação do cliente. O valor da parcela apresentado sofrerá alterações de acordo com o valor da avaliação do imóvel/bem em garantia. O valor do seguro DFI também está sujeito a alteração, uma vez que este incide sobre o valor de avaliação do imóvel/bem em garantia. As condições eixbidas poderão ser alteradas ou descontinuadas a qualquer momento, sem aviso prévio. Em caso de a tarifa de avaliação não ser incorporada ao financiamento, ela será debitada integralmente da conta informada pelo cliente.`
        break;
    }
    return message
  }
  const getAssets = () => {
    let message = ''
    switch (result.productType) {
      case 'aqu':
         message = 'do imóvel'
        break;
      case 'cgi':
         message = 'do imóvel'
        break;
      case 'cgv':
         message = 'de avaliação do veículo'
        break;
      default:
        message = 'do bem em garantia'
        break;
    }
    return message
  }
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        {/* <Image
          style={styles.logo}
          fixed
          src={require('assets/img/brand/logo-credihabitar-tipografia.png').default}
        /> */}

        {/* <View style={styles.section}>
          <Text style={styles.header}>Sobre nós.</Text>
          <Text style={styles.paragraph}>
            A CrediHabitar é uma plataforma digital de crédito imobiliário que te conecta a todos os bancos de maneira
            fácil, prática e sem burocracia. Ajudaremos você, desde a análise de crédito até a liberação dos valores de forma gratuita, segura e online
          </Text>
        </View> */}

        {/* <View style={styles.section}>
          <Text style={styles.header}>Por que a CrediHabitar é a melhor opção?</Text>
          <View>
            <Text style={styles.listItem}>* Somos especialistas em Crédito Imobiliário!</Text>
            <Text style={styles.listItem}>
              * Negociamos com todos os bancos, por isso, conseguimos a melhor opção para você!
            </Text>
            <Text style={styles.listItem}>* Sem taxas, prático, ágil e conveniente!</Text>
          </View>
        </View> */}

        <View style={styles.section}>
          <Text style={styles.header}>Simulação</Text>

          <View style={firstTableStyles.table}>
            <View style={firstTableStyles.tableRow}>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[0] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>Banco</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[1] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>Oferta</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[2] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>
                  Total Financiado
                </Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[3] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>Prazo</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[4] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>Tabela</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[5] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>Juros</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[6] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>CET</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableColHeader,
                  { width: firstTableWidths[7] },
                ]}
              >
                <Text style={firstTableStyles.tableCellHeader}>1a parcela</Text>
              </View>
            </View>

            <View style={firstTableStyles.tableRow}>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[0] },
                ]}
              >
                <Image
                  style={[
                    firstTableStyles.tableCell,
                    firstTableStyles.bankLogo,
                  ]}
                  src={result.productGroup.smallLogoUrl}
                />
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[1] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>
                  {result.subtitle}
                </Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[2] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>
                  {formatCurrency(result.loanValue, "BRL")}
                </Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[3] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>
                  {result.installments.length} meses
                </Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[4] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>SAC</Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[5] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>
                  {result.publicMonthlyFee}% a.m.
                </Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[6] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>
                  {result.publicMonthlyCet}% a.m.
                </Text>
              </View>
              <View
                style={[
                  firstTableStyles.tableCol,
                  { width: firstTableWidths[7] },
                ]}
              >
                <Text style={firstTableStyles.tableCell}>
                  {formatCurrency(head(result.installments).installment, "BRL")}
                </Text>
              </View>
            </View>
          </View>
          
        <View style={styles.section}>
            <Text style={styles.smalltext}>
            {getMessage()}
             
            </Text>
          </View>
        </View>

        <View style={styles.section} break>
          <Text style={styles.header}>Dados utilizados na simulação</Text>
          <View style={styles.chatBubble}>
            <Text style={styles.chatBubbleText}>
              Produto: {result.shortName}
            </Text>
            <Text style={styles.chatBubbleText}>
              Valor {getAssets()}: {formatCurrency(propertyPrice, "BRL")}
            </Text>
            <Text style={styles.chatBubbleText}>
              Valor do empréstimo: {formatCurrency(result.loanValue, "BRL")}
            </Text>
            <Text style={styles.chatBubbleText}>
              Prazo: {result.installments.length} meses
            </Text>
            <Text style={styles.chatBubbleText}>
              Inclui tarifas bancárias? {result.judicialValue ? "Sim" : "Não"}
            </Text>
          </View>
        </View>

        <View style={styles.section} break>
          <View style={styles.headerCenter}>
            <Image
              style={styles.headerCenterImage}
              src={result.productGroup.smallLogoUrl}
            />
            <Text style={styles.headerCenterText}>{result.productType}</Text>
          </View>

          <View style={secondTableStyles.tableContainer}>
            <View style={[secondTableStyles.table, { marginBottom: 50 }]}>
              <View style={secondTableStyles.tableRow}>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: reviewTableWidths[0] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>
                    Valor do empréstimo
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: reviewTableWidths[1] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>Prazo</Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: reviewTableWidths[2] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>Tabela</Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: reviewTableWidths[3] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>
                    Taxa de juros
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: reviewTableWidths[4] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>CET</Text>
                </View>
              </View>
              <View style={secondTableStyles.tableRow}>
                <View
                  style={[
                    secondTableStyles.tableCol,
                    secondTableStyles.tableColDark,
                    { width: reviewTableWidths[0] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCell}>
                    {formatCurrency(result.loanValue, "BRL")}
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableCol,
                    secondTableStyles.tableColDark,
                    { width: reviewTableWidths[1] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCell}>
                    {result.installments.length} meses
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableCol,
                    secondTableStyles.tableColDark,
                    { width: reviewTableWidths[2] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCell}>SAC</Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableCol,
                    secondTableStyles.tableColDark,
                    { width: reviewTableWidths[3] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCell}>
                    {result.publicMonthlyFee}% a.m.
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableCol,
                    secondTableStyles.tableColDark,
                    { width: reviewTableWidths[4] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCell}>
                    {result.publicMonthlyCet}% a.m.
                  </Text>
                </View>
              </View>
            </View>

            <View style={secondTableStyles.table}>
              <View style={secondTableStyles.tableRow}>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: installmentsTableWidths[0] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>Parcela</Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: installmentsTableWidths[1] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>Juros</Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: installmentsTableWidths[2] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>
                    Amortização
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: installmentsTableWidths[3] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>
                    Seguros+TAC
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: installmentsTableWidths[4] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>
                    Prestação
                  </Text>
                </View>
                <View
                  style={[
                    secondTableStyles.tableColHeader,
                    { width: installmentsTableWidths[5] },
                  ]}
                >
                  <Text style={secondTableStyles.tableCellHeader}>
                    Saldo Devedor
                  </Text>
                </View>
              </View>
              {result.installments.map((installment, index) => (
                <View style={secondTableStyles.tableRow}>
                  <View
                    style={[
                      secondTableStyles.tableCol,
                      index % 2 === 0 && secondTableStyles.tableColDark,
                      { width: installmentsTableWidths[0] },
                    ]}
                  >
                    <Text style={secondTableStyles.tableCell}>{index + 1}</Text>
                  </View>
                  <View
                    style={[
                      secondTableStyles.tableCol,
                      index % 2 === 0 && secondTableStyles.tableColDark,
                      { width: installmentsTableWidths[1] },
                    ]}
                  >
                    <Text style={secondTableStyles.tableCell}>
                      {formatCurrency(installment.interest, "BRL")}
                    </Text>
                  </View>
                  <View
                    style={[
                      secondTableStyles.tableCol,
                      index % 2 === 0 && secondTableStyles.tableColDark,
                      { width: installmentsTableWidths[2] },
                    ]}
                  >
                    <Text style={secondTableStyles.tableCell}>
                      {formatCurrency(installment.amortization, "BRL")}
                    </Text>
                  </View>
                  <View
                    style={[
                      secondTableStyles.tableCol,
                      index % 2 === 0 && secondTableStyles.tableColDark,
                      { width: installmentsTableWidths[3] },
                    ]}
                  >
                    <Text style={secondTableStyles.tableCell}>
                      {formatCurrency(getTacValue(installment), "BRL")}
                    </Text>
                  </View>
                  <View
                    style={[
                      secondTableStyles.tableCol,
                      index % 2 === 0 && secondTableStyles.tableColDark,
                      { width: installmentsTableWidths[4] },
                    ]}
                  >
                    <Text style={secondTableStyles.tableCell}>
                      {formatCurrency(installment.installment, "BRL")}
                    </Text>
                  </View>
                  <View
                    style={[
                      secondTableStyles.tableCol,
                      index % 2 === 0 && secondTableStyles.tableColDark,
                      { width: installmentsTableWidths[5] },
                    ]}
                  >
                    <Text style={secondTableStyles.tableCell}>
                      {formatCurrency(installment.balanceDue, "BRL")}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default SimulationPdf
