import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Form, FormGroup, Input } from 'reactstrap'
import useRecoverPassword from 'modules/auth/hooks/use-recover-password'
import Loading from 'modules/common/components/Loading/Loading'
import Swal from 'sweetalert2'

function RecoverPasswordForm({ userId, token }) {
  const [isLoading, setIsLoading] = useState(false);
  const { requestValidToken, requestRecoverPassword } = useRecoverPassword();
  const { replace } = useHistory();

  const onBackToLogin = () => {
    replace('/login');
  };

  const handleError = () => {
    Swal.fire({
      title: 'Ocorreu um erro ao redefinir sua senha!',
      text: 'Tente novamente mais tarde',
      icon: 'error',
    })
    setIsLoading(false);
  };
  
  const handleErrorExpired = () => {
    Swal.fire({
      title: 'Ooops, algo saiu errado.',
      text: 'Token expirado. Solicite novamente a troca de senha!',
      icon: 'error',
    }).then(() => onBackToLogin());
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await requestValidToken({ userId, token });
        if (data.message !== 'success') {
          handleErrorExpired();
        }
      } catch (e)  {
        handleError();
      }
    })()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const password = e.target.password.value
    const repeatPassword = e.target.repeatPassword.value

    if (password.length < 6) {
      Swal.fire({
        title: 'Revise a senha',
        text: 'A senha precisa ter pelo menos 6 caracteres.',
        icon: 'error',
      });
      return;
    }

    if (password !== repeatPassword) {
      Swal.fire({
        title: 'Revise a senha',
        text: 'As senhas digitadas não são iguais.',
        icon: 'error',
        confirmButtonText: 'Voltar'
      });
      return;
    }

    try {
      setIsLoading(true)
      const data = await requestRecoverPassword({ userId, token, password })
      setIsLoading(false)

      if (data.message === 'success') {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Senha redefinida com sucesso.',
          icon: 'success',
        }).then(() => onBackToLogin())
      } else if (data.message === 'expired') {
        handleErrorExpired();
      } else {
        handleError();
      }
    } catch (e) {
      handleError()
    }
  };

  return (
    <>
      <Loading isOpen={isLoading} />

      <img
        className="w-25 mb-auto"
        alt="Credihabitar"
        src={require("assets/img/brand/credihabitar-1.svg").default}
      />

      <div>
        <h1 className="display-2 font-weight-bolder">Escolha uma nova senha</h1>
        <h2 className="font-weight-normal text-muted">Use o formulário abaixo para criar sua nova senha.</h2>
      </div>

      <Form className="py-5" onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="email">Nova senha:</label>
          <Input
            type="password"
            required
            id="password"
            name="password"
            placeholder="Digite a senha"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">Repetir a nova senha:</label>
          <Input
            type="password"
            required
            id="repeatPassword"
            name="repeatPassword"
            placeholder="Repita a senha"
          />
        </FormGroup>

        <Button block color="primary">Salvar</Button>
      </Form>

      <div className="mt-auto">
        <p>Já possui conta? <a href="#" onClick={onBackToLogin}>Entre aqui</a></p>
      </div>
    </>
  )
}

export default RecoverPasswordForm
