import * as Yup from 'yup';
import validateCpf from "modules/common/utils/cpf-validator";

export const getRegisterSchema = (statuses) => Yup.object().shape({
  motherName: Yup.string().required('Nome da mãe é obrigatório'),
  homeCountry: Yup.number().required('País de origem é obrigatório'),
  residentialPhone: Yup.string().required('Telefone residencial é obrigatório'),
  marriageStatus: Yup.number().required('Estado civil é obrigatório'),
  marriageSystemId: Yup.number().test('required', 'Regime de casamento é obrigatório', function (value, {parent}) {
    const marriageStatus = statuses.find(status => status.id === parent.marriageStatus)
    if (!value && marriageStatus?.showPartnerForm) {
      return this.createError({message: 'Regime de casamento é obrigatório'})
    }
    return true
  }),
  weddingDate: Yup.string().test('required', 'Data do casamento é obrigatório', function(value, {parent}) {
    const marriageStatus = statuses.find(status => status.id === parent.marriageStatus)
    if (!value && marriageStatus?.showPartnerForm) {
      return this.createError({message: 'Data do casamento é obrigatório'})
    }
    return true
  }),
  stableUnion: Yup.boolean(),
  unionDate: Yup.string()
    .test('required', 'Data da união estável é obrigatório', function(value, { parent }) {
      if (parent.stableUnion && !value) {
        return this.createError({ message: 'Data da união estável é obrigatório' });
      }
      return true
    }),
  partnerBirthDate: Yup.string().test('required', 'Data de nascimento do cônjuge é obrigatório', function(value, { parent }) {
    const marriageStatus = statuses.find(status => status.id === parent.marriageStatus)
    if (!value && (marriageStatus?.showPartnerForm || parent.stableUnion)) {
      return this.createError({ message: 'Data de nascimento do cônjuge é obrigatório' });
    }
    return true
  }),
  partnerGender: Yup.number().test('required', 'Gênero do cônjuge é obrigatório', function(value, { parent }) {
    const marriageStatus = statuses.find(status => status.id === parent.marriageStatus)
    if (!value && (marriageStatus?.showPartnerForm || parent.stableUnion)) {
      return this.createError({ message: 'Gênero do cônjuge é obrigatório' });
    }
    return true
  }),
  partnerName: Yup.string().test('required', 'Nome do cônjuge é obrigatório', function(value, { parent }) {
    const marriageStatus = statuses.find(status => status.id === parent.marriageStatus)
    if (!value && (marriageStatus?.showPartnerForm || parent.stableUnion)) {
      return this.createError({ message: 'Nome do cônjuge é obrigatório' });
    }
    return true
  }),
  partnerCpf: Yup.string().test('required', 'CPF do cônjuge é obrigatório', function(value, { parent }) {
    const marriageStatus = statuses.find(status => status.id === parent.marriageStatus)
    if ((marriageStatus?.showPartnerForm || parent.stableUnion) && !validateCpf(value)) {
      return this.createError({ message: 'CPF do cônjuge é obrigatório' });
    }
    return true
  }),
  partnerSameAddress: Yup.boolean(),
})
