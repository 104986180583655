import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Container } from 'reactstrap'

import api from 'modules/common/utils/api'

import OngoingTable from './OngoingTable'
// import PendingTable from './PendingTable'

function Tickets() {
  const [ data, setData ] = useState([]);
  const { replace } = useHistory();

  useEffect(() => {
    api.get(`/tickets/user`).then((data) => {
      if (data.status === 200) {
        setData(data.data);
      }
    });
  }, []);

  const openTicket = (ticketId) => {
    replace(`/tickets/${ticketId}`);
  };

  return (
    <Container fluid className="pt-5">
      <h1 className="font-weight-bolder display-4">Chamados</h1>

      <OngoingTable data={data} onRowClick={openTicket} />

      <div className="my-2" />

      {/* <PendingTable data={pendingData} onRowClick={() => {}} /> */}
    </Container>
  )
}

export default Tickets
