import React, { useEffect, useState } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap'
import { isEmpty } from 'ramda'
import { useFormik } from "formik";

import CurrencyInput from 'modules/common/components/CurrencyInput/CurrencyInput'
import Select from "modules/common/components/Select/Select";


import formikValidateInput from "modules/common/validations/formik-validate-input";
import {getFinanceAmountCGVSchema} from "modules/processes/validators/finance-amount-cgv";
import { useDetectChange } from 'modules/common/context';
import { 
  getVehiclesBrand,
  getVehiclesModel,
  getVehiclesYears,
  getVehicle
} from 'modules/common/hooks/use-vehicles';
import { getCurrencyRawValue } from 'modules/common/components/CurrencyInput/CurrencyInput';


export function FinanceAmountCGV({ onSubmit, analysis, products, productSelected=1,carsBrand,trucksBrand, motorcyclesBrand,vehicleType,  }) {
  const { setChange } = useDetectChange();
  const product = products?.find(product => product.id == productSelected)
  const formik = useFormik({
    initialValues: {
      productId: String(productSelected),
      propertyPrice: 0,
      mortgage: analysis?.mortgage ?? '',
      installments: analysis?.installments,
      brandFipe: analysis?.vehicle?.brandFipe ||"",
      brand: analysis?.vehicle?.brand ||"",
      modelFipe: analysis?.vehicle?.modelFipe ||"",
      model: analysis?.vehicle?.model ||"",
      year: analysis?.vehicle?.year ||"",
      vehicletype: analysis?.vehicle?.vehicletype || '',
    },
    validationSchema: getFinanceAmountCGVSchema(products),
    onSubmit
  });

  const [selectedVehicleType, setSelectedVehicleType] = useState();
  const [vehiclesBrand, setVehiclesBrand] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleYears, setVehicleYears] = useState([]);

  const [vehicleDetail, setVehicleDetail] = useState({});

  const [canSubmit, setCanSubmit] = useState(false);
  const [vehicleDisabled, setVehicleDisabled] = useState(true);
  const [brandDisabled, setBrandDisabled] = useState(true);
  const [yearsDisabled, setYearsDisabled] = useState(true);

  useEffect(() => {
    if(!isEmpty(formik.values.vehicletype)){
      setSelectedVehicleType(String(formik.values.vehicletype))
      const vehicles = getVehiclesBrand(String(formik.values.vehicletype), carsBrand,trucksBrand,motorcyclesBrand)
      setVehiclesBrand(vehicles)
      setVehicleDisabled(false)
    }
  }, [formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(formik.values.brandFipe)) {
      getVehiclesModel(String(formik.values.vehicletype), formik.values.brandFipe).then(data=> setVehicles(data))
      setBrandDisabled(false)
    }
  }, [formik.values.brandFipe, formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(formik.values.modelFipe) && !isEmpty(formik.values.brandFipe)) {
      getVehiclesYears(String(formik.values.vehicletype), formik.values.brandFipe, formik.values.modelFipe).then(data=> setVehicleYears(data))
      setYearsDisabled(false)
      
    }
  }, [formik.values.modelFipe, formik.values.brandFipe, formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(formik.values.year) && !isEmpty(formik.values.brandFipe) && !isEmpty(formik.values.vehicletype)) {
      getVehicle(String(formik.values.vehicletype), formik.values.brandFipe,formik.values.modelFipe,formik.values.year).then(data=> setVehicleDetail(data))
      
    }
  }, [formik.values.year, formik.values.modelFipe, formik.values.vehicletype]);

  useEffect(() => {
    if(!isEmpty(vehicleDetail)){
      const carValue = Number(getCurrencyRawValue(vehicleDetail.valorVeiculo))
      formik.setFieldValue('propertyPrice', carValue);
    }
  }, [vehicleDetail])

  useEffect(()=> {
    if(formik.values.propertyPrice && product){
      const carValue = Number(formik.values.propertyPrice)
      const permitedValue = carValue * product?.maxLoanPercent
      if(permitedValue >= product?.minLoanValue){
        setCanSubmit(true)
      }else{
        setCanSubmit(false)
      }
    }
  }, [formik.values.propertyPrice])

  useEffect(() => {
    // formik.values tem um valor que e atualizado apos a montagem que e o valor do propertyPrice
    // entao ele sempre sera diferente entao fiz uma forma de nao validar esse campo por sempre atualizar
    /*   values.modelFipe ja e diferente por algum motivo de auto preenchimento ele vem como string isso pode afetar o comportamento do canChange.
    assim fazendo que precise atualizar sendo que todos os dados estão iguais
    */
    let values = {...formik.values}
    values.propertyPrice = 0
    values.modelFipe = Number(values.modelFipe)
    setChange(JSON.stringify(values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);
  return (
    <Form onSubmit={formik.handleSubmit}>

      <div className="text-primary font-weight-bold mb-3">
        Dados do veículo
      </div>
      <FormGroup>
        <label htmlFor="vehicletype">Selecione seu tipo de veículo</label>
        <Select
          id="vehicletype"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={vehicleType?.map((vehicle)=>({id: vehicle.id, text: vehicle.name }))}
          defaultValue={formik.values.vehicletype}
          onChange={(e) => {
            if(e.target.value){
              formik.handleChange(e)
              setSelectedVehicleType(e.target.value)
            }
          }}
          name="vehicletype"
        />   
      </FormGroup>
        <FormGroup>
          <label htmlFor="brandFipe">Marca</label>
          <Select
            id="brandFipe"
            className="form-control"
            options={{ placeholder: "Selecione" }}
            disabled={vehicleDisabled}
            data={vehiclesBrand?.map((brand) => ({id: brand.codMarca, text: brand.nomeMarca }))}
            defaultValue={formik.values.brandFipe}
            onChange={(e) => {
              if(e.target.value){
                const valueText = e.target.options[e.target.selectedIndex].text
                formik.handleChange(e)
                formik.setFieldValue('brand', valueText)
              }
              // formik.handleChange(e)
            }}
            name="brandFipe"
          />
          <FormFeedback>{formik.errors.brandFipe}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="modelFipe">Veículo</label> 
          <Select
            id="modelFipe"
            className="form-control"
            options={{ placeholder: "Selecione", disabled: brandDisabled }}
            disabled={brandDisabled}
            data={vehicles.map((car) => ({id: car.codModelo, text: car.nomeModelo }) )}
            defaultValue={formik.values.modelFipe}
            onChange={(e) => {
              if(e.target.value){
                const valueText = e.target.options[e.target.selectedIndex].text
                formik.handleChange(e)
                formik.setFieldValue('model', valueText)
              }
            }}
            name="modelFipe"
          />
        </FormGroup>
        <FormGroup>
            
            <label htmlFor="year">Ano do veículo</label>
            <Select
              id="year"
              className="form-control"
              options={{ placeholder: "Selecione", disabled: yearsDisabled }}
              disabled={yearsDisabled}
              data={vehicleYears.map((car) => ({id: car.codAno, text: car.nomeAno,}))}
              defaultValue={formik.values.year}
              onChange={(e) => e.target.value && formik.handleChange(e)}
              name="year"
            />
          
            <FormFeedback>{formik.errors.year}</FormFeedback>
          </FormGroup>
        {!isEmpty(vehicleDetail) && (
          <FormGroup>
            <label>Consulta Tabela FIPE:</label>
            <div className="d-block small">Valor referência: {vehicleDetail.valorVeiculo}</div>
            <div className="d-block small" >Mês referência: {vehicleDetail.refMes}</div>
            
            {/*<Input disabled defaultValue={`Valor: ${carDetail.valorVeiculo}`}/>
            <Input disabled defaultValue={`Mês de referencia: ${carDetail.refMes}`}/>
            <Input disabled defaultValue={`Codigo fipe: ${carDetail.codFipe}`}/>
            <Input disabled defaultValue={carDetail.Marca}></Input>
            <Input disabled defaultValue={carDetail.Modelo}></Input>
            <Input disabled defaultValue={carDetail.AnoModelo}></Input>
            <Input disabled defaultValue={carDetail.Combustivel}></Input> 
          <Input disabled defaultValue={carDetail.SiglaCombustivel}></Input>*/}
          {formik.errors?.propertyPrice && 
          (<div className="invalid-feedback d-block">{formik.errors.propertyPrice}</div>)
          }
          </FormGroup>
        )}
        
      
    
  
      <FormGroup>
        <label htmlFor="mortgage">Valor do empréstimo</label>
        <CurrencyInput
          id="mortgage"
          placeholder="R$ digite aqui"
          name="mortgage"
          onChange={formik.handleChange}
          value={formik.values.mortgage}
          invalid={formikValidateInput(formik, 'mortgage')}
          required
        />
        <FormFeedback>
          {formik.errors.mortgage}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="installments">Prazo (em meses)</label>
        <Input
          id="installments"
          placeholder="Digite aqui..."
          type="number"
          name="installments"
          defaultValue={formik.values.installments}
          onChange={formik.handleChange}
          invalid={formikValidateInput(formik, 'installments')}
          required
        />
        <FormFeedback>
          {formik.errors.installments}
        </FormFeedback>
      </FormGroup>
      
      

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting && canSubmit)}
        className="mb-5"
      >
        Salvar e continuar
      </Button>
    </Form>
  )
}
