import { sortBy, prop } from 'ramda'
import camelcaseKeysDeep from 'camelcase-keys-deep';
import { loadData } from './common';

export const loadPropertyTypes = () => loadData('/analysis/property-types/');
export const loadAnalysisStatus = () => loadData('/analysis/status/');
export const loadAnalysisDashboard = () => loadData('/analysis/dashboard/');
export const loadReport = () => loadData("/reports");

export const loadMarriageStatus = () => loadData('/customers/marriage-status/').then(camelcaseKeysDeep);
export const loadGenders = () => loadData('/customers/genders/');
export const loadMarriageSystems = () => loadData('/customers/marriage-systems/');
export const loadPurchaseEstimates = () => loadData('/analysis/purchase-estimates/');
export const loadJobTitles = () => loadData('/customers/job-titles/');
export const loadJobTypes = () => loadData('/customers/job-types/');
export const loadContractTypes = () => loadData('/customers/contract-types/');
export const loadPropertyUseTypes = () => loadData('/analysis/property-use-types/');
export const loadAddressType = () => loadData("/customers/address-types");

export const loadCountries = () => loadData('/customers/countries').then(swapIds);
export const loadStates = (countryId) => loadData(`/customers/countries/${countryId}/states`).then(swapIds);
export const loadCities = (stateId) => loadData(`/customers/states/${stateId}/cities`).then(swapIds);
export const loadCep = async (cep) => {
  const rawCep = cep.replace(/\D/g, '');
  return await loadData(`/cep/${rawCep}`);
};

export const loadResidenceTimeUnit = async () =>
    sortBy(prop('id'))(
        await loadData('/customers/residence-time-units/')
    );

export const loadProposalsDirectLogin = () => loadData(`/analysis/proposals-direct-login`);

export const loadCarsBrand = () => loadData(`cars/`)
export const loadCars = (brandId) => loadData(`cars/${brandId}`)
export const loadCarsYears = (brandId, carId) => loadData(`cars/${brandId}/${carId}`)
export const loadCar = (brandId, carId, carYear) => loadData(`cars/${brandId}/${carId}/${carYear}`)

export const loadMotorcyclesBrand = () => loadData(`motorcycle/`)
export const loadMotorcycles = (brandId) => loadData(`motorcycle/${brandId}`)
export const loadMotorcyclesYears = (brandId, motorcycleId) => loadData(`motorcycle/${brandId}/${motorcycleId}`)
export const loadMotorcycle = (brandId, motorcycleId, motorcycleYear) => loadData(`motorcycle/${brandId}/${motorcycleId}/${motorcycleYear}`)

export const loadTrucksBrand = () => loadData(`truck/`)
export const loadTrucks = (brandId) => loadData(`truck/${brandId}`)
export const loadTrucksYears = (brandId, truckId) => loadData(`truck/${brandId}/${truckId}`)
export const loadTruck = (brandId, truckId, truckYear) => loadData(`truck/${brandId}/${truckId}/${truckYear}`)

export const loadVehiclesType = () => loadData(`analysis/vehicle-types/`)

const swapIds = (data) => data.map(country =>({
  ...country,
  id: country.pk_hash,
  pkId: country.id,
}));
