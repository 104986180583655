import api from "modules/common/utils/api"


const useRecoverPassword = () => {
  const makeRecoverPassword = ({ email }) => {
    return new Promise((resolve, reject) => {
      return api.get(`/auth/forgot-password/${email}`)
      .then((response) => resolve(response.status === 200))
      .catch(reject);
    })
  };

  const requestRecoverPassword = ({ userId, token, password }) => {
    return new Promise((resolve, reject) => {
      return api.post(`/auth/recover-password`, {
        userId,
        token,
        password,
      })
      .then((response) => resolve(response.data))
      .catch(reject);
    })
  };


  const requestValidToken = ({ userId, token }) => {
    return new Promise((resolve, reject) => {
      return api.post(`/auth/forgot-password-validate`, {
        userId,
        token,
      })
      .then((response) => resolve(response.data))
      .catch(reject);
    })
  };

  return { makeRecoverPassword, requestValidToken, requestRecoverPassword };
};

export default useRecoverPassword;
