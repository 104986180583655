import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

function Loading({ isOpen }) {
  return (
    <Modal isOpen={isOpen} centered={true} style={{ width: 100 }}>
      <ModalBody>

        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Loading
