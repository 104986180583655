import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Modal, Spinner } from 'reactstrap';
import { useFormik } from "formik";
import * as Yup from 'yup';
import {
  Row,
  Col,
} from "reactstrap";
import formikValidateInput from 'modules/common/validations/formik-validate-input';
import Select from 'modules/common/components/Select/Select'
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import { SanitizeHTML } from 'modules/common/components/SanitizeHTML/SanitizeHTML';

const FieldSelect = ({ formik, field }) => {
  return (
    <FormGroup>
      <label htmlFor={field.meta_key}>{field.label}</label>
      <Select
        id={field.meta_key}
        className="form-control"
        placeholder={field.help_text}
        data={field?.options?.split(',').map((o) => ({ id: o, text: o }))}
        defaultValue={formik.values[field.meta_key]}
        onChange={e => e.target.value && formik.handleChange(e)}
      />
      <FormFeedbackSelect invalid={formikValidateInput(formik, field.meta_key)}>
        {formik.errors[field.meta_key]}
      </FormFeedbackSelect>
    </FormGroup>
  );
}

const FieldText = ({ formik, field }) => {
  return (
    <FormGroup>
      <label htmlFor={field.meta_key}>{field.label}</label>
      <Input
        id={field.meta_key}
        placeholder={field.help_text}
        name={field.meta_key}
        onChange={formik.handleChange}
        defaultValue={formik.values[field.meta_key]}
        invalid={formikValidateInput(formik, field.meta_key)}
      />
      <FormFeedback>
        {formik.errors[field.meta_key]}
      </FormFeedback>
    </FormGroup>
  );
}

const FieldType = ({ formik, field }) => {
  let Component;
  switch (field.type) {
  case 'text':
    Component = FieldText
    break;
  case 'select':
    Component = FieldSelect
    break;
  default:
    return <></>;
  }

  return (
    <Component
      formik={formik}
      field={field}
    />
  );
}

function FormDynamicModal({ 
  productDetails,
  fields,
  open,
  onConfirm,
  onReject,
  shouldSendRequest
}) {
  fields = fields || [];
  const initialValues = {};
  const validations = {};

  for (const field of fields) {
    initialValues[field.meta_key] = '';
    if (field.is_required) {
      validations[field.meta_key] = Yup.string().required('Campo obrigatório');
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: onConfirm,
    validationSchema: Yup.object().shape(validations),
  });

  return (
    <Modal isOpen={open} toggle={onReject} size="lg">
      <Form onSubmit={formik.handleSubmit}>
        <div className="modal-body">
          <section className="container">
            <div>
              <div className="d-flex px-0">
                <div
                  className="rounded d-flex mr-3"
                  style={{ width: 50, height: 50, backgroundColor: '#FFF8F5' }}
                >
                  <img alt="logo" src={productDetails?.small_logo_url} />
                </div>
                <div>
                  <span className="font-weight-bold d-block">{productDetails?.tpl_bank_name}</span>
                  <span className="text-muted text-xs">{productDetails?.tpl_product_name}</span>
                </div>
              </div>
              <h1 className='mt-4'>Confirma o envio para análise?</h1>
            </div>

            <div>
              <SanitizeHTML html={productDetails?.description || ''} />
            </div>
            <aside className="mt-4">
              {fields.map((f, i) => (
                <Row key={i}>
                  <Col xs="12" md="6">
                    <FieldType
                      formik={formik}
                      field={f}
                    />
                  </Col>
                </Row>
              ))}
            </aside>
          </section>
        </div>

        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            disabled={!(shouldSendRequest)}
            onClick={onReject}
          >
            Cancelar
          </Button>
          <Button 
          color="primary" 
          type="submit"
          disabled={!(shouldSendRequest) || formik.isSubmitting}
          >
            {shouldSendRequest ? (
              <>Confirmar</>
            ) : (<><Spinner size="sm mr-2"></Spinner> Aguarde...</>)}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default FormDynamicModal
