import { Fragment } from "@fullcalendar/core";
import { last, head } from "ramda";
import React, { useEffect, useState } from "react";
import {
    Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  UncontrolledCollapse
} from "reactstrap";
import formatCurrency from "modules/common/utils/currency-formatter";
import InstallmentTable from "./InstallmentTable";
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: [
    "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
    "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
    "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
    "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
    "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
    "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
    "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
  ],
  allowedAttributes: {
    div: [ 'style', 'class' ],
    i: [ 'style', 'class' ],
    a: [ 'href', 'name', 'target', 'class' ],
    // We don't currently allow img itself by default, but
    // these attributes would make sense if we did.
    img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading', 'class' ]
  },
  selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
  allowedSchemes: [ 'http', 'https' ],
  allowedSchemesAppliedToAttributes: [ 'href', 'src' ],
  allowedIframeHostnames: []
};

const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty, 
        { ...defaultOptions, ...options },
    )
});

const SanitizeHTML = ({ html, options }) => {
    const sanitizedHtml = sanitize(html, options);
    return <div dangerouslySetInnerHTML={sanitizedHtml} />
};


function SimulationResult({ data, onSubmit, downloadSimulationPdf }) {
  const [openCollapse, setOpenCollapse] = useState([])
  const [installments, setInstallments] = useState({})

  const enterCollapse = (id) => {
    setOpenCollapse(ids => [...ids, id])
  }

  const exitCollapse = (id) => {
    setOpenCollapse(ids => ids.filter(i => i !== id))
  }

  const openInstallments = (id, value) => {
    setInstallments(installments => ({ ...installments, [id]: value }))
  }

  const closeInstallments = (id) => {
    const newInstallments = {
      ...installments,
      [id]: null
    }
    setInstallments(newInstallments)
  }

  useEffect(() => {
    if (data) {
      setInstallments({})
    }
  }, [data])

  const getAnalysisValue = ({ judicialValue, evaluationValue }) => {
    if (evaluationValue && judicialValue) {
      return (
        <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
          <span className="mr-5">Análise Jurídica e Avaliação Imóvel</span>
          <span>
            {formatCurrency(evaluationValue + judicialValue, 'BRL')}
          </span>
        </div>
      )
    }
    if (evaluationValue) {
      return (
        <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
          <span className="mr-5">Avaliação Imóvel</span>
          <span>
            {formatCurrency(evaluationValue, 'BRL')}
          </span>
        </div>
      )
    }
    if (judicialValue) {
      return (
        <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
          <span className="mr-5">Análise Jurídica</span>
          <span>
            {formatCurrency(judicialValue, 'BRL')}
          </span>
        </div>
      )
    }
  }

  return (
    <>
      <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">Resultados</h3>
            </div>
          </Row>
        </CardHeader>

        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Banco</th>
              <th scope="col">Oferta</th>
              <th scope="col">Produto</th>
              <th scope="col">Renda mínima</th>
              <th scope="col">Primeira parcela</th>
              <th scope="col">Juros</th>
              <th scope="col">Prazo</th>
              <th scope="col"></th>
              
            </tr>
          </thead>

          <tbody>
            {data.map((value, index) => (
              <Fragment key={index}>
                <tr>
                  <td>
                    <img
                      alt="Icone banco"
                      width="30px"
                      height="30px"
                      src={value.productGroup.smallLogoUrl}
                    />
                  </td>
                  <td>{value.subtitle}</td>
                  <td>{value.shortName}</td>
                  <td>{formatCurrency(value.minimumIncome, 'BRL')}</td>
                  <td>{formatCurrency(head(value.installments).installment, 'BRL')}</td>
                  <td>{value.publicFee}%</td>
                  <td>{value.installments.length}</td>
                  <td id={`toggle${index}`} role='button' className='pl-0'>
                    <i className={openCollapse.includes(index) ? "fa fa-chevron-up" : "fa fa-chevron-down" } />
                  </td>
                </tr>
                <tr >
                  <td colSpan="12" className="p-0 border-0 simulation-content">
                    <UncontrolledCollapse
                      onEntered={() => enterCollapse(index)}
                      onExited={() => exitCollapse(index)}
                      toggler={`toggle${index}`}
                    >
                      {installments[index]?.length > 0 ? (
                        <InstallmentTable
                          installments={installments[index]}
                          onClose={() => closeInstallments(index)}
                        />
                      ) : (
                        <Row className="px-4 pb-4 mr-0">
                          <Col lg={7}>
                            <div className="d-flex justify-content-between border-bottom pb-2">
                              <span className="mr-5">Valor do empréstimo</span>
                              <span>
                                {formatCurrency(value.loanValue, 'BRL')}
                              </span>
                              
                            </div>
                            {Boolean(value.npValue) && (
                              <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                                <span className="mr-5">Cartório</span>
                                <span>
                                  {formatCurrency(value.npValue, 'BRL')}
                                </span>
                              </div>
                            )}
                            {Boolean(value.itbiValue) && (
                              <div className="d-flex justify-content-between border-bottom pb-2 mt-2">
                                <span className="mr-5">ITBI</span>
                                <span>
                                  {formatCurrency(value.itbiValue, 'BRL')}
                                </span>
                              </div>
                            )}
                            {getAnalysisValue(value)}
                            <div className="d-flex justify-content-between pb-2 mt-2">
                              <span className="mr-5 font-weight-bold">Valor total do empréstimo</span>
                              <span className="font-weight-bold">
                                {formatCurrency(value.loanTotalValue, 'BRL')}
                              </span>
                            </div>
                            {value.description && 
                              <div>
                                <SanitizeHTML html={value.description} />
                              </div>
                            }
                          </Col>

                          <Col lg={5}>
                            <div className="d-flex flex-column justify-content-start">
                              <div className="d-flex mb--3">
                                <Card className="w-50">
                                  <CardBody className="px-2 py-2">
                                    <Badge color="info">Juros</Badge>
                                    <p className="font-weight-bolder m-0 mt-1">
                                      {value.publicMonthlyFee}% a.m.
                                    </p>
                                    <span className="mt-1 text-muted">Taxa média</span>
                                  </CardBody>
                                </Card>
                                <Card className="w-50">
                                  <CardBody className="px-2 py-2">
                                    <Badge color="info">CET</Badge>
                                    <p className="font-weight-bolder m-0 mt-1">
                                      {value.publicMonthlyCet}% a.m.
                                    </p>
                                    <span className="mt-1 text-muted">Taxa média</span>
                                  </CardBody>
                                </Card>
                              </div>

                              <div className="py-3 px-4 rounded mb-2" style={{ background: '#FFE1BD'}}>
                                <i className="fa fa-flag-checkered mr-2" />
                                Ultima parcela: <strong>{formatCurrency(last(value.installments).installment, 'BRL')}</strong>
                              </div>

                              <Button
                                size='sm'
                                block
                                outline
                                color="default"
                                className="btn-icon"
                                onClick={() => openInstallments(index, value.installments)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fa fa-eye" />
                                </span>
                                <span className="btn-inner--text">Visualizar parcelas</span>
                              </Button>

                              <Button
                                size='sm'
                                block
                                outline
                                color="default"
                                className="btn-icon mt-2 ml-0"
                                onClick={() => downloadSimulationPdf(value)}
                              >
                              <span className="btn-inner--icon">
                                <i className="fas fa-arrow-to-bottom" />
                              </span>
                                <span className="btn-inner--text">Baixar PDF</span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </UncontrolledCollapse>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Card>

      <Row>
        <Col xs="12" md="8">
          <p className="text-left text-xs disclaimer pl-md-4 pl-0">
            Os resultados exibidos referem-se a simulação.
            As taxas de juros são apenas referenciais, podendo variar conforme perfil de crédito/aprovação do cliente.
            O valor da parcela apresentado sofrerá alterações de acordo com o valor da avaliação do imóvel/bem em garantia.
            O valor do seguro DFI também está sujeito a alteração, pois ele incide sobre o valor de avaliação do imóvel/bem em garantia.
            As condições exibidas poderão ser alteradas ou descontinuadas a qualquer momento, sem aviso prévio.
            Em caso de a tarifa de avaliação não ser incorporada ao financiamento, ela será debitada integralmente da conta informada pelo cliente.
          </p>
        </Col>
  
        <Col xs="12" md="4" className="d-flex justify-content-end align-items-start pb-5 pb-md-0">
          <Button
            color="primary"
            onClick={onSubmit}
          >Seguir com proposta</Button>
        </Col>
      </Row>
    </>
  )
}

export default SimulationResult

