import React, { createContext, useContext } from 'react';
import Swal from 'sweetalert2';
import api from 'modules/common/utils/api';

export const LoadingContext = createContext({ setEnable: null });

export const SwalLoading = () => {
    return Swal.fire({
        target:'div.main-content',
        title: 'Carregando',
        backdrop: false,
        html: '',
        position:'top-end',
        allowOutsideClick: () => !Swal.isLoading(),
        allowEscapeKey: () => !Swal.isLoading(),
        didOpen: () => {
          Swal.showLoading()
        },
    });
};

let timeout = false;
let enable = false;
let currentSwal = null;
export const LoadingProvider = ({ children }) => {
    const close = () => {
        if (!enable) {
            return ;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            currentSwal.close();
            timeout = null;
        }, 250);
    };

    const requestCallback = (config) => {
        if (!enable) {
            return config;
        }

        if (!timeout) {
            currentSwal = SwalLoading();
        }

        return config;
    };

    const responseClose = (config) => {
        close();
        return config;
    };
    const rejectClose = (error) => {
        close();
        return Promise.reject(error);
    };

    const setEnable = (value) => {
        enable = value;
    }

    api.interceptors.request.use(requestCallback, rejectClose);
    api.interceptors.response.use(responseClose, rejectClose);

    return (
        <LoadingContext.Provider
          value={{
            setEnable,
          }}
        >
          {children}
        </LoadingContext.Provider>
      );
};

export const useLoading = () => useContext(LoadingContext);
