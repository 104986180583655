import * as Yup from 'yup';
import { validarCNPJ } from "modules/common/utils/cnpj-validator"

export const getRegisterSchema = (contractTypes) => {
  const schema = Yup.object().shape({
    jobType: Yup.string().required('Tipo de trabalho é obrigatório'),
    jobTitle: Yup.string().required('Cargo é obrigatório'),
    contractType: Yup.string().required('Tipo de contrato é obrigatório'),
    cnpj: Yup.string().test('required', 'CNPJ é obrigatório', function(value, { parent }) {
      const contractType = contractTypes.find(contract => Number(contract.id) === Number(parent.contractType));
      if (contractType?.show_company_form && !validarCNPJ(value)) {
        return this.createError({ message: 'CNPJ é obrigatório' });
      }
      return true
    }),
    // companyName: Yup.string().required('Nome da empresa é obrigatório'),
    // phone: Yup.string().required('Telefone é obrigatório'),
    // dateOfEmployment: Yup.string().required('Data de admissão é obrigatório'),
    monthlyIncome: Yup.string().required('Renda mensal é obrigatório'),
  })

  return schema;
};
