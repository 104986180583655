import api from "modules/common/utils/api"

export const loadData = (url) => new Promise((resolve, reject) => {
    api.get(url)
      .then(response => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          resolve([]);
        }
    }).catch(reject);
});
