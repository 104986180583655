import React from 'react'
import { useHistory } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
// import { matchPath, useParams } from "react-router";
import useLoggedUser from 'modules/auth/hooks/use-logged-user'

function AuthRoute({ path, render, ...props }) {
  const { location } = useHistory();
  const { user, loading } = useLoggedUser()

  if (!user && !loading) {
    let path = '/login';
    if (location.pathname !== '/') {
      path += `/${encodeURIComponent(location.pathname)}`;
    }
    
    return (
      <Redirect to={path} />
    );
  }

  return (
    <Route path={path} render={render} {...props} />
  );
}

export default AuthRoute
