import React, { useEffect, useState } from "react";
import { Button, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { isEmpty } from "ramda";
import CurrencyInput from "modules/common/components/CurrencyInput/CurrencyInput";
import DateInput from "modules/common/components/DateInput/DateInput";
import useProducts from "modules/common/hooks/use-products";
import { useFormik } from "formik";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import {getVehiclesBrand,getVehiclesModel,getVehiclesYears,getVehicle} from "modules/common/hooks/use-vehicles"
import Select from "modules/common/components/Select/Select";
import {getFinanceAmountCGVSchema} from "modules/processes/validators/finance-amount-cgv";
import mapIdNameToSelect from "modules/common/utils/id-name-mapper";
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import { getCurrencyRawValue } from "modules/common/components/CurrencyInput/CurrencyInput";
import {
  loadCarsBrand,
  loadMotorcyclesBrand,
  loadTrucksBrand,
  loadVehiclesType
} from "modules/common/hooks/use-analyses-basic-data";

function SimulationFormCGV ({ onSubmit, productId }) {
  const products = useProducts();
  const [carsBrand, setCarsBrand] = useState([]);
  const [trucksBrand, setTrucksBrand] = useState([]);
  const [motorcyclesBrand, setMotorcyclesBrand] = useState([]);
  const [vehicleType, setVehicleType] = useState([]);

  useEffect(() => {
    loadCarsBrand().then(setCarsBrand)
    loadMotorcyclesBrand().then(setMotorcyclesBrand)
    loadTrucksBrand().then(setTrucksBrand)
    loadVehiclesType().then(setVehicleType)
  }, []);
  const formik = useFormik({
    initialValues: {
      productId: productId,
      propertyPrice: "",
      mortgage: "",
      installments: "",
      isFinanceDocumentation: false,
      isFinanceAnalysisAndRatings: false,
      birthDate: "",
      brandFipe: "",
      brand: "",
      modelFipe: "",
      model: "",
      year: "",
      vehicletype: '',
    },
    validationSchema: getFinanceAmountCGVSchema(products, true),
    onSubmit,
  });

  const [vehiclesBrand, setVehiclesBrand] = useState([]);
  const [vehiclesModel, setVehiclesModel] = useState([]);
  const [vehicleYears, setVehicleYears] = useState([]);
  const [vehicleDetail, setVehicleDetail] = useState({});

  const [vehicleDisabled, setVehicleDisabled] = useState(true);
  const [brandDisabled, setBrandDisabled] = useState(true);
  const [yearsDisabled, setYearsDisabled] = useState(true);
  useEffect(() => {
    if(productId != formik.initialValues.productId){
      formik.setFieldValue('productId', productId)
    }
  }, [productId, formik.initialValues])
  useEffect(() => {
    if (!isEmpty(formik.values.vehicletype)) {
      const vehicles = getVehiclesBrand(String(formik.values.vehicletype), carsBrand, trucksBrand, motorcyclesBrand);
      setVehiclesBrand(vehicles);
      setVehicleDisabled(false)
    }
  }, [formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(formik.values.brandFipe)) {
      getVehiclesModel(String(formik.values.vehicletype), formik.values.brandFipe).then((data) =>
        setVehiclesModel(data)
      );
      setBrandDisabled(false)
    }
  }, [formik.values.brandFipe, formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(formik.values.modelFipe) && !isEmpty(formik.values.brandFipe)) {
      getVehiclesYears(String(formik.values.vehicletype), formik.values.brandFipe, formik.values.modelFipe).then(
        (data) => setVehicleYears(data)
      );
      setYearsDisabled(false)
    }
  }, [formik.values.modelFipe, formik.values.brandFipe, formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(formik.values.year) && !isEmpty(formik.values.brandFipe) && !isEmpty(formik.values.vehicletype)) {
      getVehicle(String(formik.values.vehicletype), formik.values.brandFipe, formik.values.modelFipe, formik.values.year)
        .then((data) => setVehicleDetail(data));
    }
  }, [formik.values.year, formik.values.modelFipe, formik.values.vehicletype]);

  useEffect(() => {
    if (!isEmpty(vehicleDetail)) {
      const carValue = Number(getCurrencyRawValue(vehicleDetail.valorVeiculo))

      formik.setFieldValue('propertyPrice', carValue);
    }
  }, [vehicleDetail]);

  let maxDate = new Date().toJSON().slice(0, 10);
  let minDate = new Date().toJSON().slice(0, 10);
  maxDate = Number(maxDate.slice(0, 4)) - 18 + maxDate.slice(4);
  minDate = Number(minDate.slice(0, 4)) - 80 + minDate.slice(4);

  const handleSubmit = (e,actions) => {
  
    e.preventDefault()
    formik.validateForm().then((errors) => {
      if (isEmpty(errors) && formik.isValid && formik.dirty && !formik.isSubmitting) {
        formik.handleSubmit(e);
      }
    });

  }
  return (
      <Form className="py-4" onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="vehicleType">Tipo de veículo</label>
          <Select
            id="vehicletype"
            className="form-control"
            options={{ placeholder: "Selecione" }}
            data={vehicleType?.map((vehicle)=>({id: vehicle.id, text: vehicle.name }))}
            defaultValue={formik.values.vehicletype}
            onChange={(e) => e.target.value && formik.handleChange(e)}
            name="vehicletype"
          />
          {formik.errors.vehicletype && <div className="invalid-feedback d-block">{formik.errors.vehicletype}</div>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="brandFipe">Marca</label>
          <Select
            id="brandFipe"
            className="form-control"
            options={{ placeholder: "Selecione" }}
            data={vehiclesBrand?.map((brand) => ({id: brand.codMarca, text: brand.nomeMarca }))}
            defaultValue={formik.values.brandFipe}
            disabled={vehicleDisabled}
            onChange={(e) => {
              if(e.target.value){
                const valueText = e.target.options[e.target.selectedIndex].text
                formik.handleChange(e)
                formik.setFieldValue('brand', valueText)
              }
              // formik.handleChange(e)
            }}
            name="brandFipe"
          />
          {formik.errors?.brandFipe && 
          (<div className="invalid-feedback d-block">{formik.errors.brandFipe}</div>)
          }
        </FormGroup>
        <FormGroup>
            <label htmlFor="modelFipe">Veículo</label> 
            <Select
              id="modelFipe"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              data={vehiclesModel.map((car) => ({id: car.codModelo, text: car.nomeModelo }) )}
              disabled={brandDisabled}
              defaultValue={formik.values.modelFipe}
              onChange={(e) => {
                if(e.target.value){
                  const valueText = e.target.options[e.target.selectedIndex].text
                  formik.handleChange(e)
                  formik.setFieldValue('model', valueText)
                }
              }}
              name="modelFipe"
            />
         {formik.errors?.modelFipe && 
          (<div className="invalid-feedback d-block">{formik.errors.modelFipe}</div>)
          }
        </FormGroup>
        <FormGroup>
            <label htmlFor="year">Ano do veículo</label>
            <Select
              id="year"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              disabled={yearsDisabled}
              data={vehicleYears.map((car) => ({id: car.codAno, text: car.nomeAno,}))}
              defaultValue={formik.values.year}
              onChange={(e) => e.target.value && formik.handleChange(e)}
              name="year"
            />
          {formik.errors?.year && 
          (<div className="invalid-feedback d-block">{formik.errors.year}</div>)
          }
        </FormGroup>
        {!isEmpty(vehicleDetail) && (
          <FormGroup>
            <label>Consulta Tabela FIPE:</label>
            <div className="d-block small">Valor referência: {vehicleDetail.valorVeiculo}</div>
            <div className="d-block small" >Mês referência: {vehicleDetail.refMes}</div>
            
            {/*<Input disabled defaultValue={`Valor: ${carDetail.valorVeiculo}`}/>
            <Input disabled defaultValue={`Mês de referencia: ${carDetail.refMes}`}/>
            <Input disabled defaultValue={`Codigo fipe: ${carDetail.codFipe}`}/>
            <Input disabled defaultValue={carDetail.Marca}></Input>
            <Input disabled defaultValue={carDetail.Modelo}></Input>
            <Input disabled defaultValue={carDetail.AnoModelo}></Input>
            <Input disabled defaultValue={carDetail.Combustivel}></Input> 
          <Input disabled defaultValue={carDetail.SiglaCombustivel}></Input>*/}
          {formik.errors?.propertyPrice && 
          (<div className="invalid-feedback d-block">{formik.errors.propertyPrice}</div>)
          }
          </FormGroup>
        )}
         <FormGroup>
          <label htmlFor="mortgage">Valor do empréstimo</label>
          <CurrencyInput
            id="mortgage"
            placeholder="R$ digite aqui"
            name="mortgage"
            onChange={formik.handleChange}
            value={formik.values.mortgage}
            invalid={formikValidateInput(formik, "mortgage")}
          />
          {formik.errors.mortgage && <div className="invalid-feedback d-block">{formik.errors.mortgage}</div>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="birthDate">Data de nascimento</label>
          <DateInput
            id="birthDate"
            name="birthDate"
            max={maxDate}
            min={minDate}
            onChange={formik.handleChange}
            value={formik.values.birthDate}
            invalid={formikValidateInput(formik, "birthDate")}
          />
          {formik.errors.birthDate && <div className="invalid-feedback d-block">{formik.errors.birthDate}</div>}
        </FormGroup>
        <FormGroup>
          <label htmlFor="installments">Prazo (em meses)</label>
          <Input
            id="installments"
            placeholder="Digite..."
            type="number"
            min={formik.values.installments}
            name="installments"
            onChange={formik.handleChange}
            defaultValue={formik.values.installments}
            invalid={formikValidateInput(formik, "installments")}
          />
          {formik.errors.installments && <div className="invalid-feedback d-block">{formik.errors.installments}</div>}
        </FormGroup>
        
        <Button block color="primary" type="submit">
          Simular
        </Button>
      </Form>
  );
}

export default SimulationFormCGV;
