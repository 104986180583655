import { StyleSheet } from '@react-pdf/renderer';

const COL_COUNT = 9;
const COL_WIDTH = 100 / COL_COUNT;

export const styles = StyleSheet.create({
  table: { 
    display: "table", 
    width: "auto", 
  }, 
  tableRow: {
    display: 'flex',
    margin: "auto", 
    flexDirection: "row",
    alignItems: 'center'
  },
  tableColHeader: {
    width: COL_WIDTH + "%", 
    fontSize: 12,
    color: '#FF5185'
  },
  tableCol: {
    width: COL_WIDTH + "%", 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 10,
    fontWeight: 500,
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 9,
    textAlign: 'center'
  },
  bankLogo: {
    width: 25,
  },
})

export const firstTableWidths = ['8%', '13%', '16%', '16%', '9%', '8%', '11%', '8%'];
