import React, { useState } from "react";
import { useParams } from 'react-router';
import { Row, Col } from "reactstrap";

import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import RecoverPasswordForm from "./RecoverPasswordForm";
import { useLoading } from "modules/common/context/Loading";

const FORMS = {
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
};

function Login() {
  const [form, setForm] = useState(FORMS.LOGIN);
  const { id: userId, token } = useParams();
  const { setEnable } = useLoading();
  setEnable(false);

  const toggleForm = () => {
    setForm(form === FORMS.LOGIN ? FORMS.FORGOT_PASSWORD : FORMS.LOGIN);
  };

  return (
    <Row className="d-flex align-items-stretch justify-content-center m-0 login-container">

      <Col sm="5" xs="12" className="py-5 bg-white h-100 mr-auto">
        <Col xs={10} sm={8} className="mx-auto">
          {token && <RecoverPasswordForm userId={userId} token={token} />}
          {!token && form === FORMS.LOGIN && <LoginForm onForgotPassword={toggleForm} />}
          {!token && form === FORMS.FORGOT_PASSWORD && <ForgotPasswordForm onBackToLogin={toggleForm} />}
        </Col>
      </Col>

      <Col xs="7" className="p-0 bg-primary position-fixed vh-100 d-none d-sm-block"
        style={{ backgroundImage: `url(${require("assets/img/theme/login.png").default})` }}
      >
      </Col>
    </Row>
  );
}

export default Login;

