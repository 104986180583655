import * as Yup from 'yup';

const propertyInfoSchema = Yup.object().shape({
  isDefined: Yup.boolean(),
  propertyType: Yup.number().required('Campo obrigatório'),
  propertyUseType: Yup.number().required('Campo obrigatório'),
  street: Yup.string().when('isDefined', {
    is: true,
    then: Yup.string().required('Campo obrigatório'),
  }),
  number: Yup.string().when('isDefined', {
    is: true,
    then: Yup.string().required('Campo obrigatório'),
  }),
  complement: Yup.string(),
  neighborhood: Yup.string().when('isDefined', {
    is: true,
    then: Yup.string().required('Campo obrigatório'),
  }),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  zipCode: Yup.number().when('isDefined', {
    is: true,
    then: Yup.number().required('Campo obrigatório'),
  }),
  totalArea: Yup.number().when('isDefined', {
    is: true,
    then: Yup.number().required('Campo obrigatório'),
  }),
  monthlyCost: Yup.number().when('isDefined', {
    is: true,
    then: Yup.number().required('Campo obrigatório'),
  }),
  age: Yup.number().when('isDefined', {
    is: true,
    then: Yup.number().required('Campo obrigatório'),
  })
})

export const propertyInfoSchemaCgi = Yup.object().shape({
  isDefined: Yup.boolean(),
  propertyType: Yup.number().required('Campo obrigatório'),
  street: Yup.string().required('Campo obrigatório'),
  number: Yup.string().required('Campo obrigatório'),
  complement: Yup.string(),
  neighborhood: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  zipCode: Yup.number().required('Campo obrigatório'),
})

export default propertyInfoSchema
