import useSWR from "swr";
import api from 'modules/common/utils/api'
import { defaultSwrOptions } from "modules/common/utils/swr-options";

const fetcher = (...args) => api.get(...args).then(res => res.data.map(country =>({
  ...country,
  id: country.pk_hash,
  pkId: country.id,
})))

function useCountries() {
  const { data, error } = useSWR('/customers/countries', fetcher, defaultSwrOptions)

  return {
    countries: data || [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useCountries
