import * as Yup from 'yup';
import cpfValidator from "../../common/validations/cpf";

export const registerProposalSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  cpf: Yup.string().required('CPF é obrigatório').concat(cpfValidator),
  productId: Yup.number().required('Campo obrigatório'),
  mortgage: Yup.number('Campo obrigatório'),
});

export default registerProposalSchema;
