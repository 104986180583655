import React, { useEffect, useState, useRef } from 'react';

import { loadReport } from 'modules/common/hooks/use-analyses-basic-data';
import { Col, Container, Row } from 'reactstrap';


const Report = ({}) => {
    const [ url, setUrl ] = useState("");
    const iframe = useRef();
    useEffect(() => {
        loadReport().then(setUrl);
    }, []);

    useEffect(() => {
    }, [iframe]);
    

    return (
        <Container fluid className="pt-5">
            <Row>
                <Col xs="12">
                  {url !== "" && (
                      <iframe
                          ref={iframe}
                          className="responsive-iframe"
                          // style={{
                          //   ...(iframeHeight
                          //     ? { height: iframeHeight }
                          //     : {}
                          //   )
                          // }}
                          src={url}
                          
                      >
                      </iframe>
                  )}
                </Col>
            </Row>
            
            
        </Container>
    );
};

function FrameWrapper({ url }) {
    const ref = React.useRef();
    const [height, setHeight] = React.useState("0px");
    const onLoad = () => {
      setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };
    return (
      <iframe
        ref={ref}
        onLoad={onLoad}
        id="myFrame"
        src="http://demo_iframe.htm"
        width="100%"
        height={height}
        scrolling="no"
        frameBorder="0"
        style={{
          maxWidth: 640,
          width: "100%",
          overflow: "auto",
        }}
      ></iframe>
    );
  }

export default Report;

// # You'll need to install PyJWT via pip 'pip install PyJWT' or your project packages file

// import jwt
// import time

// METABASE_SECRET_KEY = "fd143a968edbc01ea79ca347088c4d15dbdaa144f3df178f36f20c20149cc0b7"

// payload = {
//   "resource": {"dashboard": 2},
//   "params": {
//     "id": null
//   },
//   "exp": round(time.time()) + (60 * 10) // 10 minute expiration
// }
// token = jwt.encode(payload, METABASE_SECRET_KEY, algorithm="HS256")

// iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true"
