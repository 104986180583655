import { createContext, useState } from "react";
import camelcaseKeysDeep from 'camelcase-keys-deep';
import decamelizeKeysDeep from 'decamelize-keys-deep';

import api from "modules/common/utils/api";
import { getCellphoneRawValue } from "modules/common/components/CellphoneInput/CellphoneInput";
import { getRawValue } from "modules/common/components/CpfInput/CpfInput";
import { getCurrencyRawValue } from "modules/common/components/CurrencyInput/CurrencyInput";

export const analysisInitialState = {
  analysis: null,
  submitAnalysis: null,
  submitAnalysisDirectLogin: null,
  loadAnalysis: null,
  submitCustomer: null,
  submitMarriageData: null,
  submitPurchaseEstimate: null,
  submitAddress: null,
  submitProperty: null,
  submitIncome: null,
  submitDocuments: null,
  deleteCustomer: null,
  deleteProcess: null,
};

export const AnalysisContext = createContext(analysisInitialState)

const AnalysisContextProvider = ({ children }) => {
  const [ analysis, setAnalysis ] = useState();
  const [ currentWizard, setCurrentWizard ] = useState(0);

  const normalizeAnalysisPayload = (data) => {
    return {
      productId: data.product?.value,
      product:camelcaseKeysDeep(data.product),
      propertyPrice: data.property_price,
      mortgage: data.mortgage,
      mortgageFee: data.mortgage_fee,
      installments: data.installments,
      status: data.status,
      step: data.step,
      hash: data.pk_hash,
      purchaseEstimate: data.purchase_estimate,
      isFinanceDocumentation: data.is_finance_documentation,
      isFinanceAnalysisAndRatings: data.is_finance_analysis_and_ratings,
      isUsingFgts: data.is_using_fgts,
      customer: camelcaseKeysDeep(data.customer),
      property: camelcaseKeysDeep(data.property),
      simulation: camelcaseKeysDeep(data.simulation),
      bidders: data.bidders.map(camelcaseKeysDeep),
      proposalFollowed: camelcaseKeysDeep(data.proposal_followed),
      proposals: camelcaseKeysDeep(data.proposals),
      project_tasks: data.project_tasks,
      completed: data.completed,
      allowEdit: data.allow_edit,
      vehicle: camelcaseKeysDeep(data.vehicle)
    };
  }

  const loadAnalysis = async (id) => {
    const { data } = await api.get(`/analysis/${id}`);
    setAnalysis(normalizeAnalysisPayload(data));
  };

  const submitAnalysis = async (
    values,
    analysis,
    additionalData = {},
  ) => {
    const payload = {
      tbl_tpl_category_id: values.productId,
      is_finance_documentation: values.isFinanceDocumentation,
      is_finance_analysis_and_ratings: values.financeAnalysisAndRatings,
      is_using_fgts: values.isUsingFgts,
      property_price: values.propertyPrice,
      mortgage: values.mortgage,
      mortgage_fee: values.mortgage,
      simulation_id: additionalData.simulationHash,
      installments: values.installments,
    };
    let payloadCar
    if(values.brandFipe){
      payloadCar = {
        brandFipe: values.brandFipe,
        brand: values.brand,
        year: values.year,
        modelFipe: values.modelFipe,
        model: values.model,
        vehicletype: values.vehicletype,
      }
    }
    let response
    if (analysis?.hash) {
      response = await api.patch(`/analysis/${analysis.hash}/update`, payload);
    } else {
      response = await api.post('/analysis/create', payload);
    }
    if(payloadCar){
      await submitCarsData(payloadCar, response.data)
    }
    await loadAnalysis(response.data.pk_hash);
    return response.data.pk_hash;
  };

  const deleteProcess = async (pkHash) => {
    try {
      const response = await api.delete(`/analysis/${pkHash}`);
      if (response.status !== 200) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const submitAnalysisDirectLogin = async (
    values,
    analysis,
    additionalData = {},
  ) => {
    const payload = {
      product_id: values.productId,
      name: values.name,
      cpf: getRawValue(values.cpf),
      mortgage: values.mortgage,
    };

    let response
    if (!analysis?.hash) {
      response = await api.post('/analysis/create-direct-login', payload);
    }

    await loadAnalysis(response.data.pk_hash);
    return response.data.pk_hash;
  };

  const submitCustomer = async (values, analysis) => {
    const id = values.id
    const payload = {
      is_main: values.isMain,
      full_name: values.name,
      cpf: getRawValue(values.cpf),
      email: values.email,
      cellphone: getCellphoneRawValue(values.cellphone),
      birth_date: values.birthDate,
      gender: values.genderId,
      is_partner_same_address: Boolean(values.partnerSameAddress),
    }

    await sendCustomerData(id, payload, analysis)
  }

  const submitMarriageData = async (values, analysis) => {
    const id = values.id
    const payload = {
      mother_name: values.motherName,
      residential_phone: getCellphoneRawValue(values.residentialPhone),
      home_country: values.homeCountry,
      partner_birth_date: values.partnerBirthDate || null,
      partner_gender: values.partnerGender || null,
      partner_name: values.partnerName || null,
      partner_cpf: (values.partnerCpf && getRawValue(values.partnerCpf)) || null,
      is_partner_same_address: values.partnerSameAddress || false,
      marriage_status: values.marriageStatus,
      has_stable_union: values.stableUnion,
      stable_union_date: values.stableUnion ? values.unionDate : null,
      wedding_date: values.weddingDate || null,
      marriage_system: values.marriageSystemId || null,
    }

    await sendCustomerData(id, payload, analysis)
  }
  const submitCarsData = async (values, analysis, additional_data={}) => {
    const analysisHash = analysis.hash || analysis.pk_hash || ''
    const id = analysis.vehicle?.pkHash || ''

    const payload = {
      brand_fipe: values.brandFipe,
      brand: values.brand,
      year: values.year,
      model_fipe: values.modelFipe,
      model: values.model,
      vehicletype: values.vehicletype
    }

    if (id) {
      await api.patch(`/analysis/${analysisHash}/vehicle/${id}/update`, payload)
    } else {
      await api.post(`/analysis/${analysisHash}/vehicle/create`, payload)
    }
    await loadAnalysis(analysisHash);
  }

  const submitDocuments = async (values, analysis) => {
    const id = values.id
    const payload = {
      document_number: values.documentNumber,
      document_issued_by: values.documentIssuedBy,
      document_issue_location: values.documentIssuedStateId,
      document_issue_date: values.documentIssueDate,
      home_state: values.homeStateId,
      home_city: values.homeCityId,
    }
    sendCustomerData(id, payload, analysis);
  }

  const sendCustomerData = async (id, payload, analysis) => {
    const customer = analysis.bidders.find(b => b.pkHash === id) || analysis.customer
    if (customer) {
      customer.homeState = customer.homeState?.id
      customer.homeCity = customer.homeCity?.id
    }

    const fullData = {
      ...decamelizeKeysDeep(customer),
      ...payload,
    };

    if (id) {
      await api.patch(`/analysis/${analysis.hash}/customers/${id}/update`, fullData)
    } else {
      await api.post(`/analysis/${analysis.hash}/customers/create`, payload)
    }
    await loadAnalysis(analysis.hash);
  };

  const submitPurchaseEstimate = async (purchaseEstimate, analysis) => {
    const payload = { 
      purchase_estimate: purchaseEstimate
    }

    await api.patch(
      `/analysis/${analysis.hash}/update`,
      payload
    )
    await loadAnalysis(analysis.hash);
  };

  const submitAddress = async (values, analysis) => {
    const customerId = values.customerId
    const addressId = values.addressId
    const payload = {
      address_type: values.addressTypeId,
      street: values.street,
      number: values.number,
      complement: values.complement,
      neighborhood: values.neighborhood,
      zip_code: values.zipCode,
      city: values.city,
      state: values.state,
      residence_time_unit: values.residenceTimeUnit,
      residence_time: values.residenceTime,
    }

    if (addressId) {
      await api.patch(
        `/analysis/${analysis.hash}/customers/${customerId}/address/${addressId}/update`,
        payload
      )
    } else {
      await api.post(
        `/analysis/${analysis.hash}/customers/${customerId}/address/create`,
        payload
      )
    }
    await loadAnalysis(analysis.hash);
  };

  const submitProperty = async (values, analysis) => {
    const isDefined = values.isDefined
    const product = values.productId
    let payload
    if(product === 2){
      payload = {
        property_type: values.propertyType,
        property_use_type: null,
        is_defined: false,
        monthly_cost: null,
        total_area: null,
        street: values.street,
        number: values.number,
        complement: values.complement,
        neighborhood: values.neighborhood,
        zip_code: values.zipCode,
        city: values.city,
        state: values.state,
        age: null,
        is_seller_pj: false,
      }
    }else{
      payload = {
        property_type: values.propertyType,
        property_use_type: values.propertyUseType,
        is_defined: isDefined,
        monthly_cost: isDefined ? values.monthlyCost : null,
        total_area: isDefined ? values.totalArea : null,
        street: isDefined ? values.street : null,
        number: isDefined ? values.number : null,
        complement: isDefined ? values.complement : null,
        neighborhood: isDefined ? values.neighborhood : null,
        zip_code: isDefined ? values.zipCode : null,
        city: values.city,
        state: values.state,
        age: isDefined ? values.age : null,
        is_seller_pj: isDefined ? values.isSellerPj : false,
      }
    }

    if (analysis?.property?.id) {
      await api.patch(
        `/analysis/${analysis.hash}/property/${analysis.property?.pkHash}/update`,
        payload
      )
    } else {
      await api.post(
        `/analysis/${analysis.hash}/property/create`,
        payload
      )
    }
    await loadAnalysis(analysis.hash);
  };

  const submitIncome = async (values, analysis) => {
    const customerId = values.customerId
    const incomeId = values.incomeId
    const payload = {
      job_type: values.jobType,
      job_title: values.jobTitle,
      contract_type: values.contractType,
      cnpj: getRawValue(values.cnpj),
      company_name: values.companyName,
      phone: values.phone,
      date_of_employment: values.dateOfEmployment,
      monthly_income: values.monthlyIncome,
    }

    if (incomeId) {
      await api.patch(
        `/analysis/${analysis.hash}/customers/${customerId}/income/${incomeId}/update`,
        payload
      )
    } else {
      await api.post(
        `/analysis/${analysis.hash}/customers/${customerId}/income/create`,
        payload
      )
    }
    await loadAnalysis(analysis.hash);
  };

  const deleteCustomer = async (id) => {
    await api.delete(`/analysis/${analysis.hash}/customers/${id}/delete`);
    await loadAnalysis(analysis.hash);
  };

  const createProcess = async (processHash) => {
    try {
      const response = await api.patch(`/analysis/${analysis.hash}/process/${processHash}`, { is_followed: true });
      if (response.status !== 200) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  };

  const loadProjectMessages = async (processHash, taskId) => {
    try {
      const response = await api.get(`/analysis/${analysis.hash}/process/${processHash}/tasks/${taskId}/comments`, {});
      if (response.status !== 200) {
        return false;
      }
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const sendProjectMessage = async (processHash, taskId, text) => {
    try {
      const response = await api.post(`/analysis/${analysis.hash}/process/${processHash}/tasks/${taskId}/comments`, {text});
      if (response.status !== 200) {
        return false;
      }
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const loadProcessChecklist = async (processHash, taskId) => {
    try {
      const response = await api.get(`/analysis/${analysis.hash}/process/${processHash}/tasks/${taskId}/checklists`);
      if (response.status !== 200) {
        return false;
      }
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const saveProcessChecklist = async (processHash, taskId, data) => {
    try {
      const response = await api.post(`/analysis/${analysis.hash}/process/${processHash}/tasks/${taskId}/checklists`, data);
      if (response.status !== 200) {
        return false;
      }
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const deleteProcessChecklist = async (processHash, taskId, id) => {
    try {
      const response = await api.delete(`/analysis/${analysis.hash}/process/${processHash}/tasks/${taskId}/checklists/attachments/${id}`, );
      if (response.status !== 200) {
        return false;
      }
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const createProposalAnalysis = async (product_id, additional_data) => {
    try {
      const response = await api.post(`/analysis/${analysis.hash}/proposals`, { product_id, additional_data });
      if (response.status !== 200) {
        return response;
      }
      return true;
    } catch (e) {
      return e.response.data;
    }
  };

  const proposalProductDetails = async (product_id) => {
    try {
      const response = await api.get(`/analysis/${analysis.hash}/proposals/products/${product_id}`);
      if (response.status !== 200) {
        return false;
      }
      return response.data;
    } catch (e) {
      return false;
    }
  };

  const getIdsByProjectId = async (projectId) => {
    try {
      const response = await api.get(`/analysis/resolve-project/${projectId}`);
      if (response.status !== 200) {
        return false;
      }
      return normalizeAnalysisPayload(response.data);
    } catch (e) {
      return false;
    }
  };

  return (
    <AnalysisContext.Provider
      value={{
        analysis,
        submitAnalysis,
        submitAnalysisDirectLogin,
        loadAnalysis,
        submitCustomer,
        submitMarriageData,
        submitPurchaseEstimate,
        submitAddress,
        submitProperty,
        submitIncome,
        submitDocuments,
        deleteCustomer,
        loadProjectMessages,
        sendProjectMessage,
        createProcess,
        loadProcessChecklist,
        saveProcessChecklist,
        deleteProcessChecklist,
        setAnalysis,
        createProposalAnalysis,
        proposalProductDetails,
        currentWizard,
        setCurrentWizard,
        deleteProcess,
        getIdsByProjectId,
        submitCarsData,
      }}
    >
      {children}
    </AnalysisContext.Provider>
  );
}

export default AnalysisContextProvider
