
import { css, cx } from '@emotion/css';

const StatusBadge = ({ statusBgColor, statusTextColor, statusName, statusFontSize }) => {
  if (!statusTextColor) {
    statusTextColor = '#fff';
  }
  if (!statusBgColor) {
    statusBgColor = '#222';
  }
  if (!statusFontSize) {
    statusFontSize = 'xs';
  }
  const bagedClassName = cx(
      'badge text-' + statusFontSize,
      css`
        color: ${statusTextColor};
        background-color: ${statusBgColor};
      `
    );
  
    return <span className={bagedClassName}>{statusName}</span>;
};

export default StatusBadge;