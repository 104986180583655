import React from 'react'
import InputMask from 'react-input-mask'
import { Input } from 'reactstrap'

export function getRawValue(value) {
  return value.replace(/\D/g, '')
}

function CpfInput({
  id,
  value,
  onChange,
  defaultValue,
  required,
  name,
  invalid,
}) {
  return (
    <InputMask
      id={id}
      placeholder="123.456.789.01"
      mask="999.999.999-99"
      alwaysShowMask={false}
      maskPlaceholder=""
      defaultValue={defaultValue}
      required={required}
      value={value}
      name={name}
      onChange={onChange}
    >
      <Input invalid={invalid} />
    </InputMask>
  )
}

export default CpfInput
