import Cookies from 'js-cookie'
import api from 'modules/common/utils/api'
import { COOKIE_AUTH_KEY } from 'modules/auth/constants/cookies'

function useLogin() {
  const makeLogin = async ({ username, password }) => {
    return api.post('/auth/login', { username, password }).then(({ status, data }) => {
      if (status !== 200) {
        return false;
      }

      Cookies.set(COOKIE_AUTH_KEY, data.token);

      return {
        success: true,
        termsAgreed: data.terms_agreed,
      };
    }).catch(() => ({
      success: false,
      termsAgreed: false,
    }));
  }

  return { makeLogin };
};

export function authomaticLogin({ type, id, token }) {
  return api.post('/auth/authomatic-login', { type, id, token }).then(({ status, data }) => {
    if (status !== 200) {
      return false;
    }

    Cookies.set(COOKIE_AUTH_KEY, data.token);
    return true;
  }).catch(() => false);
}

export default useLogin
