import React, { useEffect, useState } from 'react'
import {Button, Form, FormFeedback, FormGroup, Input} from 'reactstrap'
import { isEmpty } from 'ramda'
import {useFormik} from "formik";
import mapIdNameToSelect from 'modules/common/utils/id-name-mapper'
import CurrencyInput from 'modules/common/components/CurrencyInput/CurrencyInput'
import Select from 'modules/common/components/Select/Select'
// import useCities from 'modules/common/hooks/use-cities'
// import useStates from 'modules/common/hooks/use-states'
// import useCountries from 'modules/common/hooks/use-countries'
import CepInput from 'modules/common/components/CepInput/CepInput'
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import propertyInfoSchema, {propertyInfoSchemaCgi} from "modules/processes/validators/property-info";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import { loadStates } from 'modules/common/hooks/use-analyses-basic-data'
import { loadCities } from 'modules/common/hooks/use-analyses-basic-data'
import { useDetectChange } from 'modules/common/context';
import { formatterPropertyArea } from 'modules/common/utils/area-imovel-format';

export function PropertyInfo({ countries, propertyTypes, propertyUseTypes, onSubmit, analysis,productSelected}) {
  const { setChange } = useDetectChange();
  const formik = useFormik({
    initialValues: {
      productId:productSelected,
      isDefined: analysis?.property?.isDefined || false,
      propertyType: analysis?.property?.propertyType || '',
      propertyUseType: analysis?.property?.propertyUseType || '',
      street: analysis?.property?.street || '',
      number: analysis?.property?.number || '',
      complement: analysis?.property?.complement || '',
      neighborhood: analysis?.property?.neighborhood || '',
      city: analysis?.property?.city || '',
      state: analysis?.property?.state || '',
      zipCode: analysis?.property?.zipCode || '',
      totalArea: analysis?.property?.totalArea || '',
      monthlyCost: analysis?.property?.monthlyCost || '',
      age: analysis?.property?.age || '',
      isSellerPj: analysis?.property?.isSellerPj || '',
    },
    onSubmit,
    validationSchema: productSelected === 1 ? propertyInfoSchema: propertyInfoSchemaCgi,
  });
  const [selectedStateId, setSelectedStateId] = useState('');
  const [address, setAddress] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const country = countries.find(({ name }) => name.trim().toLowerCase().includes('brasil'));

  useEffect(() => {
    if (country) {
      loadStates(country?.id).then((data) => setStates(data));
    }
  }, [country])

  useEffect(() => {
    if (selectedStateId && selectedStateId !== '') {
      loadCities(selectedStateId).then(setCities);
    }
  }, [selectedStateId]);

  useEffect(() => {
    if (address) {
      formik.setValues({
        ...formik.values,
        street: address?.street || '',
        state: address?.state || '',
        city: address?.city || '',
        neighborhood: address?.neighborhood || '',
      })
    }
  }, [analysis, address])

  useEffect(() => {
    const isStringAndHasTwoLetters = (value) => typeof value === 'string' && value.length === 2

    // We need to have selectedState so we can get the pkHash and get the cities
    if (isStringAndHasTwoLetters(formik.values.state) && !isEmpty(states)) {
      const newState = states.find(({code}) => code === formik.values.state)
      setSelectedStateId(newState?.id)
    }
  }, [formik.values.state, states])

  useEffect(() => {
    if (!isEmpty(cities) && isNaN(formik.values.city) && !formik.values.isDefined) {
      const newCity = cities.find(({ name }) => name === formik.values.city)
      formik.setFieldValue('city', newCity?.name)
    }
  }, [cities, formik.values.isDefined])

  useEffect(() => {
    if (!formik.values.isDefined) return

    if (formik.values.state && !isEmpty(states)) {
      const selectedState = states.find(({ id }) => id === Number(formik.values.state))
      selectedState && formik.setFieldValue('state', selectedState?.name)
    }

    if (formik.values.city && !isEmpty(cities)) {
      const selectedCity = cities.find(({ name }) => name === Number(formik.values.city));
      selectedCity && formik.setFieldValue('city', selectedCity?.name);
    }
  }, [formik.values.isDefined])

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="text-primary font-weight-bold mb-3">
        Informações do Imóvel
      </div>
      <FormGroup>
        <label htmlFor="propertyType">Tipo do imóvel</label>
        <Select
          id="propertyType"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(propertyTypes)}
          name='propertyType'
          value={formik.values.propertyType}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'propertyType')}>
          {formik.errors.propertyType}
        </FormFeedbackSelect>
      </FormGroup>
      {productSelected === 2 && (<>
          <FormGroup>
            <label htmlFor="zipCode">CEP</label>
            <CepInput
              id="zipCode"
              placeholder="12345678"
              onChangeAddress={address => setAddress(address)}
              name="zipCode"
              defaultValue={formik.values.zipCode}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'zipCode')}
            />
            <FormFeedback>
              {formik.errors.zipCode}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="street">Rua</label>
            <Input
              id="street"
              placeholder="Digite aqui"
              name="street"
              defaultValue={formik.values.street}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'street')}
              disabled={
                (address?.neighborhood) || (!address && analysis?.property?.street)
              }
            />
            <FormFeedback>
              {formik.errors.street}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="number">Número</label>
            <Input
              id="number"
              placeholder="Digite aqui"
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'number')}
            />
            <FormFeedback>
              {formik.errors.number}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="complement">Complemento</label>
            <Input
              id="complement"
              placeholder="Digite aqui"
              name="complement"
              value={formik.values.complement}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'complement')}
            />
            <FormFeedback>
              {formik.errors.complement}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="neighborhood">Bairro</label>
            <Input
              id="neighborhood"
              placeholder="Digite aqui"
              name="neighborhood"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'neighborhood')}
              disabled={
                (address?.neighborhood) || (!address && analysis?.property?.neighborhood)
              }
            />
            <FormFeedback>
              {formik.errors.neighborhood}
            </FormFeedback>
          </FormGroup></>)}
      {productSelected === 1 &&(<><FormGroup>
        <label htmlFor="propertyUseType">Finalidade do imóvel</label>
        <Select
          id="propertyUseType"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(propertyUseTypes)}
          name='propertyUseType'
          value={formik.values.propertyUseType}
          onChange={e => e.target.value && formik.handleChange(e)}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'propertyUseType')}>
          {formik.errors.propertyUseType}
        </FormFeedbackSelect>
      </FormGroup>
      <div className="text-primary font-weight-bold mb-3">
        Localização do Imóvel
      </div>
      <FormGroup>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="d-block">Já possui o imóvel definido</span>
            <span className="h4 font-weight-normal text-muted">
              O cliente já escolheu o imóvel a ser financiado?
            </span>
          </div>

          <label htmlFor="isDefined" className="custom-toggle ml-auto">
            <input
              id="isDefined"
              type="checkbox"
              name="isDefined"
              checked={formik.values.isDefined}
              onChange={e => formik.setFieldValue('isDefined', e.target.checked)}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      </FormGroup></>)}

      {(formik.values.isDefined && productSelected === 1) && (
        <>
          <FormGroup>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="d-block">O vendedor é PJ</span>
                <span className="h4 font-weight-normal text-muted">
                  Marque se vendedor for uma pessoa jurídica
                </span>
              </div>

              <label htmlFor="isSellerPj" className="custom-toggle ml-auto">
                <input
                  id="isSellerPj"
                  type="checkbox"
                  name="isSellerPj"
                  checked={formik.values.isSellerPj}
                  onChange={e => formik.setFieldValue('isSellerPj', e.target.checked)}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>
          </FormGroup>
          <FormGroup>
            <label htmlFor="age">Idade do imóvel (anos)</label>
            <Input
              id="age"
              type="number"
              name="age"
              value={formik.values.age}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'age')}
            />
            <FormFeedback>
              {formik.errors.age}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="totalArea">Área do imóvel (m2)</label>
            <Input
              id="totalArea"
              type="number"
              name="totalArea"
              value={formik.values.totalArea}
              onChange={(e)=>{
                formik.handleChange(formatterPropertyArea(e))
              }}
              invalid={formikValidateInput(formik, 'totalArea')}
            />
            <FormFeedback>
              {formik.errors.totalArea}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="monthlyCost">Valor do condomínio + IPTU</label>
            <CurrencyInput
              id="monthlyCost"
              placeholder="R$ Digite aqui..."
              name="monthlyCost"
              value={formik.values.monthlyCost}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'monthlyCost')}
            />
            <FormFeedback>
              {formik.errors.monthlyCost}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="zipCode">CEP</label>
            <CepInput
              id="zipCode"
              placeholder="12345678"
              onChangeAddress={address => setAddress(address)}
              name="zipCode"
              defaultValue={formik.values.zipCode}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'zipCode')}
            />
            <FormFeedback>
              {formik.errors.zipCode}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="street">Rua</label>
            <Input
              id="street"
              placeholder="Digite aqui"
              name="street"
              defaultValue={formik.values.street}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'street')}
              disabled={
                (address?.neighborhood) || (!address && analysis?.property?.street)
              }
            />
            <FormFeedback>
              {formik.errors.street}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="number">Número</label>
            <Input
              id="number"
              placeholder="Digite aqui"
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'number')}
            />
            <FormFeedback>
              {formik.errors.number}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="complement">Complemento</label>
            <Input
              id="complement"
              placeholder="Digite aqui"
              name="complement"
              value={formik.values.complement}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'complement')}
            />
            <FormFeedback>
              {formik.errors.complement}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="neighborhood">Bairro</label>
            <Input
              id="neighborhood"
              placeholder="Digite aqui"
              name="neighborhood"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'neighborhood')}
              disabled={
                (address?.neighborhood) || (!address && analysis?.property?.neighborhood)
              }
            />
            <FormFeedback>
              {formik.errors.neighborhood}
            </FormFeedback>
          </FormGroup>
        </>
      )}

      <FormGroup>
        <label htmlFor="state">Estado</label>
        {formik.values.isDefined ? (
          <>
            <Input
              id="state"
              placeholder="Digite aqui"
              disabled={formik.values.isDefined}
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'state')}
            />
            <FormFeedback>
              {formik.errors.state}
            </FormFeedback>
          </>
        ) : (
          <>
            <Select
              id="state"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              disabled={productSelected===2?true:false}
              data={mapIdNameToSelect(states, 'code', 'name')}
              name="state"
              value={formik.values.state}
              onChange={e => e.target.value && formik.handleChange(e)}
            />
            <FormFeedbackSelect invalid={formikValidateInput(formik, 'state')}>
              {formik.errors.state}
            </FormFeedbackSelect>
          </>
        )}
      </FormGroup>
      <FormGroup>
        <label htmlFor="city">Cidade</label>
        {formik.values.isDefined ? (
          <>
            <Input
              id="city"
              placeholder="Digite aqui"
              disabled={formik.values.isDefined}
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              invalid={formikValidateInput(formik, 'city')}
            />
            <FormFeedback>
              {formik.errors.city}
            </FormFeedback>
          </>
        ) : (
          <>
            <Select
              id="city"
              className="form-control"
              options={{ placeholder: "Selecione" }}
              disabled={productSelected===2?true:false}
              data={mapIdNameToSelect(cities, 'name')}
              name="city"
              value={formik.values.city}
              onChange={e => e.target.value && formik.handleChange(e)}
            />
            <FormFeedbackSelect invalid={formikValidateInput(formik, 'city')}>
              {formik.errors.city}
            </FormFeedbackSelect>
          </>
        )}
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
        className="mb-5"
      >Salvar e continuar</Button>
    </Form>
  )
}
