import {
  loadCar,
  loadCarsYears,
  loadCars,
  loadMotorcycle,
  loadMotorcyclesYears,
  loadMotorcycles,
  loadTruck,
  loadTrucksYears,
  loadTrucks,
} from "modules/common/hooks/use-analyses-basic-data";

const getVehiclesBrand = (
  vehicleType,
  carsBrand,
  trucksBrand,
  motorcyclesBrand
) => {
  
  let vehiclesBrand = [];
  switch (vehicleType) {
    case "1":
      vehiclesBrand = carsBrand;
      break;
    case "2":
      vehiclesBrand = trucksBrand;

      break;
    case "3":
      vehiclesBrand = motorcyclesBrand;
      break;

    default:
      break;
  }
  return vehiclesBrand;
};

const getVehiclesModel = async (vehicleType, brandFipe) => {
  let vehiclesModel = [];

  switch (vehicleType) {
    case "1":
      const carsModel = await loadCars(brandFipe);

      vehiclesModel = carsModel;
      break;
    case "2":
      const truckModel = await loadTrucks(brandFipe);
      vehiclesModel = truckModel;
      break;

    case "3":
      const motorcycleModel = await loadMotorcycles(brandFipe);
      vehiclesModel = motorcycleModel;
      break;
    default:
      break;
  }

  return vehiclesModel;
};

const getVehiclesYears = async (vehicleType, brandFipe, modelFipe) => {
  let vehiclesYears = [];
  switch (vehicleType) {
    case "1":
      const carsYears = await loadCarsYears(brandFipe, modelFipe);
      vehiclesYears = carsYears;
      break;
    case "2":
      const truckYears = await loadTrucksYears(brandFipe, modelFipe);
      vehiclesYears = truckYears;
      break;

    case "3":
      const motorcycleYears = await loadMotorcyclesYears(brandFipe, modelFipe);
      vehiclesYears = motorcycleYears;
      break;

    default:
      break;
  }
  return vehiclesYears;
};
const getVehicle = async (vehicleType, brandFipe, modelFipe, year) => {
  let vehicle = [];
  
  switch (vehicleType) {
    case "1":
      const cars = await loadCar(brandFipe, modelFipe, year);
      vehicle = cars;
      break;

    case "2":
      const truck = await loadTruck(brandFipe, modelFipe, year);
      vehicle = truck;
      break;

    case "3":
      const motorcycle = await loadMotorcycle(brandFipe, modelFipe, year);
      vehicle = motorcycle;
      break;
    default:
      break;
  }
  return vehicle;
};

export { getVehicle,getVehiclesBrand,getVehiclesModel,getVehiclesYears}