import { loadProducts } from "modules/common/hooks/use-products";

import { useContext, useEffect, useState } from "react";
import { Button, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import Client from "./steps/Client";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ClientReaderSelect from "./steps/ClientReaderSelect";
import imageCgi from "assets/img/icons/credito-com-garantia-imovel.png"
import imageCgv from "assets/img/icons/credito-com-garantia-veiculo.png"
import imageAquisicao from "assets/img/icons/financiamento-imovel.png"
import imageFinanciamentoVeicular from "assets/img/icons/financiamento-veiculo.png"

function SelectProduct({
  currentTab,
  id,
  readOnly,
  analysis,
  setCurrentTab,
  tabs,
  currentWizard,
  setCurrentWizard,carAssurance,setCarAssurance,canShow, setCanShow
}) {
  const [products, setProducts] = useState([]);
  const [productSelected, setProductSelected] = useState(1);
  const {replace, push} = useHistory()
  useEffect(() => {
    let isMounted = true;
  
    loadProducts().then((products) => {
      if (isMounted) {
        setProducts(products);
      }
    });
    if(currentTab){
      switch (currentTab) {
        case 'create-cgi':
          setProductSelected(2)
          break
        case 'create-cgv':
          setProductSelected(4)
          break
        case 'create-aquisicao':
          setProductSelected(1)
          break
        default:
          setProductSelected(1)
          break
      }
    }
    return () => {
      isMounted = false;
    };
  }, [currentTab]);
  
  useEffect(() => {
    const productIdHasCarsAssurance = ["4", "5"];
    if(currentTab == 'create-cgv' && analysis) {
      setProductSelected(analysis.productId)
      return setCarAssurance(true);
    }
    if (productIdHasCarsAssurance.includes(String(productSelected))) {
      return setCarAssurance(true);
    }else{
      return setCarAssurance(false);
    }
  }, [productSelected, currentTab]);

  useEffect(() => {
    if(id !== "new"){
      setCanShow(true)
    }
  }, [id]);
  const renderImage = (slug,id) => {
    switch (slug) {
      case 'finv':
        return (<img id={id} src={imageFinanciamentoVeicular}/>);
      case 'cgv':
        return (<img id={id} src={imageCgv}/>);
      case 'cgi':
        return (<img id={id} src={imageCgi}/>);
      default:
        return (<img id={id} src={imageAquisicao}/>);
    }
  }
  return (
    <section>
     {(!analysis && id === "new" && currentTab === 'create') && ( 
        <FormGroup className='flex-column'>
          <h1 className="font-weight-bolder display-4 mt-5">Selecione o tipo de empréstimo</h1>
          <div className='d-md-flex d-block align-items-center justify-content-start'>
            {products.map((product)=> (
              <div
                key={product.id}
                className='new_process__product my-3 mr-5 p-3'
                onClick={(e) => {
                  setProductSelected(e.target.id);
                  setCanShow(true)
                  switch (product.slug) {
                    case 'cgv':
                      setCarAssurance(true)
                      replace('create-cgv')
                      break;
                    case 'cgi':
                      replace('create-cgi')
                      break;
                    default:
                      replace('create-aquisicao')
                      break;
                  }
                }}
              >
                {renderImage(product.slug, product.id)} <div className="display-4 mt-3">{product.name}</div>
              </div>
            ))}
          </div>
      </FormGroup>
    )}
      {(currentTab === "create-cgi" || currentTab === 'create-cgv' || currentTab === "create-aquisicao") &&
        canShow &&
        (id === "new" || (id !== "new" && analysis)) &&
        (!readOnly && (id === "new" || analysis?.allowEdit) ? (
          <Client
            analysis={analysis}
            currentWizard={currentWizard}
            setCurrentWizard={setCurrentWizard}
            carAssurance={carAssurance}
            productSelected={productSelected}
            currentTab={currentTab}
            onFinished={() => {
              setCurrentTab(tabs[1].index);
            }}
          />
        ) : (
          <ClientReaderSelect analysis={analysis} />
        ))}
    </section>
  );
}

export default SelectProduct;
