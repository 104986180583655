import Cookies from 'js-cookie'
import { useEffect, useState } from "react"
import jwt from 'jsonwebtoken'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import { COOKIE_AUTH_KEY } from 'modules/auth/constants/cookies'

function useLoggedUser() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = jwt.decode(Cookies.get(COOKIE_AUTH_KEY))
    if (user) {
      setUser(camelcaseKeysDeep(user))
    }
    setLoading(false);
  }, []);

  return { user, loading };
}

export default useLoggedUser
