import React from 'react'

function EmptySimulation() {
  return (
    <div 
      className="rounded w-100 h-50 mt-5 d-flex flex-column align-items-center justify-content-center"
      style={{ background: '#FFF9FB', minHeight: '400px' }}
    >
      <img
        width="70px"
        height="70px"
        alt="Credihabitar"
        src={require("assets/img/icons/simulation.png").default}
        />
      <p className="font-weight-bolder mt-3 mb-0">Uau! Mais uma simulação.</p>
      <p className="font-weight-normal text-muted m-0 text-center">
        Preencha as informações ao lado para ver os <br/> produtos e taxas disponíveis.
      </p>
    </div>
  )
}

export default EmptySimulation
