import React from "react";

import {
  // Badge,
  // Button,
  Card,
  CardFooter,
  // CardHeader,
  // Row,
  Table
} from "reactstrap";
import { dateTimeFormat } from 'modules/common/utils/date-format';
import StatusBadge from 'modules/common/components/StatusBadge/StatusBadge';

function OngoingTable({ data, onRowClick }) {  

  data = data.sort(( a, b ) => {
    if (a.id < b.id) {
      return 1;
    }
    if (a.id > b.id) {
      return -1;
    }
    return 0;
  });

  return (
    <Card>
      {/* <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <Button color="default">
              <span>Aberto</span>
              <Badge color="primary" className="badge-md badge-circle badge-floating border-white">4</Badge>
            </Button>
            <Button color="default">
              <span>Em andamento</span>
              <Badge color="primary" className="badge-md badge-circle badge-floating border-white" >4</Badge>
            </Button>
            <Button color="default">
              <span>Respondido</span>
              <Badge color="primary" className="badge-md badge-circle badge-floating border-white">4</Badge>
            </Button>
            <Button color="default">
              <span>Em espera</span>
              <Badge color="danger" className="badge-md badge-circle badge-floating border-white">4</Badge>
            </Button>
            <Button color="default">
              <span>Fechado</span>
              <Badge color="primary" className="badge-md badge-circle badge-floating border-white">4</Badge>
            </Button>
          </div>
        </Row>
      </CardHeader> */}

      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col" data-sort="ticket">Chamado</th>
            <th scope="col" data-sort="subjcect">Assunto</th>
            <th scope="col" data-sort="department">Departamento</th>
            <th scope="col" data-sort="process">Processo</th>
            <th scope="col" data-sort="status">Status</th>
            <th scope="col" data-sort="createdAt">Submetido</th>
          </tr>
        </thead>

        <tbody>
          {data.map(value => (
            <tr key={value.id} onClick={() => onRowClick(value.id)} role="button">
              <th scope="row">
                {value.id}
              </th>
              <td>{value.subject}</td>
              <td>{value.department}</td>
              <td>{value.project_name}</td>
              <td>
                <StatusBadge statusBgColor={value.status_color} statusName={value.status_name} />
              </td>
              <td>{dateTimeFormat(value.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <CardFooter className="py-4">
        <nav aria-label="...">
        </nav>
      </CardFooter>
    </Card>
  )
}

export default OngoingTable
