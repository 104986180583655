import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import Swal from 'sweetalert2';

import { authomaticLogin } from 'modules/auth/hooks/use-login';

function AutomaticLogin({ type }) {
    const { id, token } = useParams();
    const { replace } = useHistory();

    useEffect(() => {
        authomaticLogin({ type: type, id, token }).then((response) => {
            if (response) {
                if (type === 'consultant') {
                    replace(`/processes/${id}`);    
                } else if (type === 'customer') {
                    replace(`/`);
                }
            } else {
                Swal.fire({
                    title: 'Sem permissão',
                    text: '',
                    icon: 'error',
                });
            }
        });
    }, []);

    return (
        <></>
    );
}

export default AutomaticLogin;