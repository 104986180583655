import { uniqueId } from "lodash";
import React from "react";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"

function BigNumberBank({ number, title, iconSrc }) {
  const id = uniqueId("tooltip")
  

  return (
    <Card id={id}>
      <CardBody>
        <h1 className="display-3 font-weight-bolder d-block d-md-flex align-items-center" >
          {number}

          {iconSrc && (
            <img
              className="ml-4 icon"
              src={iconSrc}
            />
          )}
        {/* <div className="ml-0 ml-md-2">
          <h5 className="mt-2 font-weight-normal text-muted mb-1">{title}</h5>
          {iconSrc && (
            <div className="h6 text-muted font-weight-normal">* Processos ativos</div>
          )}
        </div> */}
        </h1>
        <UncontrolledTooltip target={id} placement="top">
          {title} * Processos ativos
        </UncontrolledTooltip>
      </CardBody>
    </Card>
  )
}

export default BigNumberBank
