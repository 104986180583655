import React, { useEffect, useState } from "react"

import { Row, Col, Button, Card, CardBody, Spinner } from "reactstrap"
import classNames from "classnames"
import Swal from "sweetalert2"

import StatusBadge from "modules/common/components/StatusBadge/StatusBadge"
import FormDynamicModal from "modules/common/components/FormDynamicModal/FormDynamicModal"
import { formatNumber } from "modules/common/utils/currency-formatter"

const getActionButton = ({
  status,
  createProposalAnalysis,
  createProcess,
  loading,
  data,
}) => {
  const createProcessAlert = async (e, id) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Você confirma a sequência da proposta?",
        text: "Esta ação não poderá ser desfeita.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Voltar",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary order-2 mx-0",
          actions: 'd-flex justify-content-end px-4',
          cancelButton: "btn btn-secondary mr-auto",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await createProcess(id)
          if (data) {
            Swal.fire({
              title: "Sucesso!",
              html: `Excelente! A partir daqui você irá acompanhar o processo na aba Processo Criado`,
              icon: "success",
            }).then(() => {
              return resolve(data)
            })
          } else {
            Swal.fire({
              title: "Ooops...",
              html: `Ocorreu um erro`,
              icon: "error",
            }).then(() => resolve(data))
          }
        }
      })
    })
  }
  switch (status) {
    case 0:
      return (
        <Button
          outline
          block
          className={classNames("btn-icon")}
          color="dark"
          onClick={createProposalAnalysis}
        >
          {loading && <Spinner size="sm"></Spinner>}
          {!loading && (
            <>
              <span className="btn-inner--text">Solicitar Análise</span>
              <span className="btn-inner--icon">
                      <i className="far fa-angle-right"></i>
                    </span>
            </>
          )}
        </Button>
      )
    case 2: // Aprovado a menor
    case 3: // Aprovado
      return (
        <Button
          className={classNames("btn-icon")}
          color="primary"
          block
          onClick={(e) => createProcessAlert(e, data.pkHash)}
        >
          <span className="btn-inner--text">Seguir neste</span>
          <span className="btn-inner--icon">
                    <i className="far fa-angle-right"></i>
                  </span>
        </Button>
      )
    case 4: // Dados incompletos
    // return (
    //   <Button outline className={classNames("btn-icon")} color="dark">
    //     <span className="btn-inner--text">Reenviar</span>
    //   </Button>
    // )
  }
  return <></>
}

export function CreditAnalysisCard({
  openTabProcess,
  openDetails,
  disableActions,
  createProposalAnalysis,
  proposalProductDetails,
  createProcess,
  readOnly,
  setForceReload,
  forceReload,
  data,
}) {
  const [requestAnalysis, setRequestAnalysis] = useState()
  const [loading, setLoading] = useState(false)
  const [shouldSendRequest, setShouldSendRequest] = useState(true)

  const createProposalAnalysisReload = async (productId, additional_data) => {
    const response = await createProposalAnalysis(productId, additional_data)
    if (response === true) {
      Swal.fire({
        title: "Parabéns! A proposta foi enviada para análise.",
        icon: "success",
      }).then(() => {
        setLoading(true)
        setShouldSendRequest(true)
        return forceReload()
      })
    } else {
      Swal.fire({
        title: "Ooops...",
        html: `${response.detail.error}`,
        icon: "error",
      }).then(() => {
        setShouldSendRequest(true)
        return forceReload()
      })
    }
  }

  const openProposalModal = async (productId) => {
    const productDetails = await proposalProductDetails(productId)
    if (productDetails) {
      setRequestAnalysis({
        productId,
        productDetails,
      })
    } else {
      Swal.fire({
        title: "Ooops...",
        html: `Ocorreu um erro solicitar a análise`,
        icon: "error",
      })
    }
  }

  return (
    <>
      {requestAnalysis !== undefined && (
        <FormDynamicModal
          productDetails={requestAnalysis.productDetails}
          fields={requestAnalysis.productDetails.additional_data}
          open={requestAnalysis.productId !== undefined}
          shouldSendRequest={shouldSendRequest}
          onConfirm={async (event) => {
            setShouldSendRequest(false)
            
            event.preventDefault?.()
            await createProposalAnalysisReload(
              requestAnalysis.productId,
              event
            )
            setRequestAnalysis(undefined)
          }}
          onReject={() => {
            setRequestAnalysis(undefined)
            setLoading(false)
          }}
        />
      )}

      <Card
        className={classNames("flex-fill", {
          "selected-tpl": data.isFollowed,
          "no-action-tpl": disableActions && !data.isFollowed,
        })}
      >
        <CardBody className="d-flex align-items-center flex-column analysis-card-body">
          {/* {data.isFollowed && (

          <div className="badge badge-success text-sm mb-3 mt-n4 mr-auto">Seguiu neste</div>
          )} */}
          <div className="d-flex px-0 w-100">
            <div
              className="rounded d-flex mr-3"
              style={{ width: 50, height: 50, backgroundColor: "#FFF8F5" }}
            >
              <img src={data?.product?.logo} />
            </div>

            <div>
              <span className="font-weight-bold d-block">
                {data?.product?.bank}
              </span>
              <span className="text-muted text-xs">{data?.product?.name}</span>
            </div>
          </div>
          <div className="data-wrapper w-100">
            <div className="d-flex align-items-center justify-content-between data-row mt-3">
              <span className="text-xs text-muted font-weight-bold d-block">
                STATUS PRÉ-ANALISE
              </span>
              <StatusBadge
                statusFontSize="sm"
                statusBgColor={data.status.bgColor}
                statusTextColor={data.status.textColor}
                statusName={data.status.label}
              />
            </div>
            {data.projectStatus && (
              <div className="d-flex align-items-center justify-content-between data-row">
                <span className="text-xs text-muted font-weight-bold d-block text-truncate">
                  STATUS
                </span>
                <StatusBadge
                  statusBgColor={data.projectStatus.bgColor}
                  statusTextColor={data.projectStatus.textColor}
                  statusName={data.projectStatus.label}
                />
              </div>
            )}
            {data.projectId && (
              <div className="d-flex align-items-center justify-content-between data-row">
                <span className="text-xs text-muted font-weight-bold d-block">
                  ID PROCESSO
                </span>
                <span className="font-weight-bold text-muted">
                  {data.projectId}
                </span>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-between data-row">
              <span className="text-xs text-muted font-weight-bold d-block">
                VALOR SOLIC.
              </span>
              <span className="font-weight-bold">
                {data.mortgageRequested
                  ? formatNumber(data.mortgageRequested)
                  : "--"}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between data-row">
              <span className="text-xs text-muted font-weight-bold d-block">
                VALOR APROV.
              </span>
              <span className="font-weight-bold">
                {data.mortgageApproved > 0
                  ? formatNumber(data.mortgageApproved, "BRL")
                  : "--"}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between data-row">
              <span className="text-xs text-muted font-weight-bold d-block">
                PRAZO APR.
              </span>
              <span className="font-weight-bold">
                {data.installmentsApproved > 0
                  ? `${data.installmentsApproved} meses`
                  : "--"}{" "}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-between data-row">
              <span className="text-xs text-muted font-weight-bold d-block">
                TAXA (CET a.a.)
              </span>
              <span className="font-weight-bold">
                {data.mortgageFee > 0 ? `${data.mortgageFee}%` : "--"}{" "}
              </span>
            </div>
          </div>
          {!readOnly && (
            <div className="w-100 mt-auto">
              <Col 
              className="analysis-buttons mx-auto"
              size="12">
                {!data.isFollowed && data.status.value !== 0 && (
                  <Button
                    className="btn-icon"
                    block
                    color="dark"
                    outline
                    onClick={openDetails}
                  >
                    <span className="btn-inner--icon">
                      <i className="far fa-eye"></i>
                    </span>
                    <span className="btn-inner--text">Detalhes</span>
                  </Button>
                )}
                {data.isFollowed && (
                  <Button
                    color="primary"
                    block
                    className="btn-icon "
                    onClick={openTabProcess}
                  >
                    <span className="btn-inner--text">Abrir</span>
                    <span className="btn-inner--icon">
                      <i className="far fa-angle-right"></i>
                    </span>
                  </Button>
                )}
                {!disableActions &&
                  getActionButton({
                    status: data.status.value,
                    createProposalAnalysis: () =>
                      openProposalModal(data.product.id),
                    createProcess: createProcess,
                    loading,
                    data,
                  })}
              </Col>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}
