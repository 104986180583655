import classnames from "classnames";
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import Swal from "sweetalert2";

import { Notification, useNotification } from 'modules/common/hooks/use-notification';
import { dateTimeFormat } from 'modules/common/utils/date-format';
import { browserDownload } from 'modules/common/utils/file';
import { blobToDataUrl } from 'modules/common/utils/file';
import { ANALYS_DELETE_FILE_TIME } from 'modules/processes/constants';

// import UploadFileModal from 'modules/common/components/UploadFileModal/UploadFileModal'

export function Documents({ forceReload, proposal, task, saveDocuments, loadDocuments, deleteDocument }) {
  const [documents, setDocuments] = useState([]);
  const [documentsAttached, setDocumentsAttached] = useState([]);

  const [documentSelected, setDocumentSelected] = useState(-1);
  const [filter, setFilter] = useState('');
  const [canUploadArchive, setCanUploadArchive] = useState(true);
  const hiddenFileInput = useRef(null);

  const ref = useRef();
  const { notify } = useNotification({ref});

  const mapDocuments = (data) => {
    if (data) {
      if(!data.accept_new_files){
        setCanUploadArchive(false)
      }
      const documents = data['expected'].sort(( a, b ) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });

      setDocuments(documents);
      setDocumentsAttached(data['existing']);
    } else {
      Swal.fire({
        title: 'Ocorreu um erro',
        icon: 'error',
        confirmButtonText: 'Ok',
        confirmBtnStyle: 'error'
      });
    }
  };

  const saveTaskDocuments = useCallback(
    async (files) => {
      if (!files.files || files.files.length === 0) {
        notify({type: 'warning', title: '', message: 'Nenhum arquivo selecionado.'});
        return false;
      }

      const data = await saveDocuments(proposal.pkHash, task.id, files);

      if (data.message_type) {
        Swal.fire({
          // title: 'Ocorreu um erro',
          html: data.message_content,
          icon: data.message_type,
          confirmButtonText: 'Ok',
          confirmBtnStyle: 'error'
        });
      }

      if (data.project_status_id !== false || data.task_status_id !== false) {
        forceReload()
      }

      mapDocuments(data);
      if (data) {
        notify({type: 'success', title: '', message: 'Documento(s) enviado(s) com sucesso'});
      }
      return data;
    },
    [task],
  );

  const onSubmit = async (files) => {
    const data = await saveTaskDocuments(files);
    if (data) {
      setDocumentSelected(-1);
    }
    return data;
  };

  const onChangeFilter = (e) => {
    setFilter(e.target.value)
  };

  const download = (e, path, file_name) => {
    e.preventDefault();
    browserDownload(path, file_name);
  };

  const filterValues = {};
  documents.forEach((document) => {
    if (document.group?.key) {
      filterValues[document.group.key] = document.group.label;
    }
  });

  const documentsFiltered = documents.filter((doc) => filter === '' || doc.group.key === filter);

  const selectFile = async (id) => {
    setDocumentSelected(id);
    hiddenFileInput.current.click();
  };

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    const data = await blobToDataUrl(file);
    await onSubmit({ id: documentSelected, files: [data] });
    hiddenFileInput.current.value = null;
  };

  const funcLoadDocuments = () => loadDocuments(proposal.pkHash, task.id).then(mapDocuments);

  const canDelete = useCallback(async (e, document) => {
    e.preventDefault();

    Swal.fire({
      title: 'Você confirma a exclusão deste arquivo?',
      icon: 'warning',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Voltar',
      confirmBtnStyle: 'error',
      customClass: {
        confirmButton: 'btn btn-primary order-2 mx-0',
        cancelButton: 'btn btn-secondary mr-auto',
        actions: 'd-flex justify-content-end  px-4'
      }
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        deleteDocument(proposal.pkHash, task.id, document.id)
          .then((response) => {
            if (response === false) {
              notify({ type: 'danger', title: '', message: 'Ocorreu um erro ao apagar o arquivo.' });
            } else if (response.expired) {
              notify({ type: 'warning', title: '', message: 'Não foi possível apagar. Expirou o tempo.' });
            } else {
              notify({ type: 'success', title: '', message: 'Arquivo apagado com sucesso.' });
              funcLoadDocuments();
            }
          }).catch(() => {
            notify({ type: 'danger', title: '', message: 'Não foi possível apagar o arquivo.' });
          });
      }
    });
  }, [task]);

  useEffect(() => {
    funcLoadDocuments();
  }, []);

  return (
    <section className="pb-5">
      <Notification innerRef={ref} />
      <Row className="justify-content-end mb-4">
        <Col lg="3">
          <span className="text-sm">Filtrar por:</span>
          <Input
            type="select"
            name="filter"
            className="form-control-muted form-control-sm"
            value={filter}
            onChange={onChangeFilter}
          >
            <option value="">Tudo</option>
            {Object.keys(filterValues).map((kei, i) => (
              <option key={`filter-${i}`} value={kei}>{filterValues[kei]}</option>
            ))}
          </Input>
        </Col>
      </Row>

      <div className='table-responsive'>
        <Table className="table table-checklist table-flush" >
          <thead className="thead-light">
            <tr>
              <th scope="col" align='center' className='td-status'>Status</th>
              <th scope="col" className='td-title'>Documento</th>
              <th scope="col" className='td-description'>Descrição</th>
              <th scope="col" className='td-group'>Grupo</th>
              {/* <th scope="col">Ajuda</th> */}
              <th scope="col" className='td-action text-center'>Ação</th>
            </tr>
          </thead>
  
          <tbody>
            {documentsFiltered.map((doc, i) => (
              <tr key={`documents-${i}`}>
                <td align='center' className=''>
                  <i className={classnames('fa-lg',{
                    'fa': !doc.is_pending,
                    'fal': doc.is_pending,
                    'fa-hourglass': doc.is_pending,
                    'fa-check': !doc.is_pending,
                    'text-danger': doc.is_pending && doc.is_required,
                    'text-success': !doc.is_pending,
                  })} aria-hidden="true"></i>
                </td>
                <td>{doc.is_required && <>*</>} {doc.name}</td>
                <td className='text-description'>
                  
                    <div className='text-truncate'>{doc.description}</div>
                    
                  
                  {/* <span id={`document-tooltip-${i}`}>{doc.description.length <= DESCRIPTION_SIZE ? doc.description : doc.description.substring(0, DESCRIPTION_SIZE) + ' ...'}</span>
                  {doc.description.length > DESCRIPTION_SIZE && <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={`document-tooltip-${i}`}
                  >
                    {doc.description}
                  </UncontrolledTooltip>} */}
                </td>
                <td>{doc.group?.label}</td>
                {/* <td>
                  <a
                    className='btn btn-secondary btn-sm btn-icon'
                    href="https://www.youtube.com"
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-play-circle"></i>
                    </span>
                    <span className="btn-inner--text">Ajuda</span>
                  </a>
                </td> */}
                <td className="text-right">
                  {doc.template_type === 'link' && doc.template_content !== '' && (
                    <a
                      className='btn btn-secondary btn-sm btn-icon ml-1'
                      href={doc.template_content}
                      target="_blank"
                    >
                      <span className="btn-inner--icon">
                        <i className="far fa-arrow-to-bottom"></i>
                      </span>
                      <span className="btn-inner--text">Modelo</span>
                    </a>
                  )}
                  <Button
                    className='btn-icon'
                    size='sm'
                    color='primary'
                    onClick={() => selectFile(doc.id)}
                    disabled={!canUploadArchive}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-cloud-upload"></i>
                    </span>
                    <span className="btn-inner--text">Enviar arquivo</span>
                  </Button>
                  
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Table className="align-items-center table-flush mt-5 w-50" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Nome do arquivo</th>
            <th scope="col">Envio em</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>

        <tbody>
          {documentsAttached.map((document, i) => (
            <tr key={`attached-${i}`}>
              <td>{document.file_name}</td>
              <td>{dateTimeFormat(document.created_at)}</td>
              <td>
                <a
                  className='btn btn-secondary btn-sm btn-icon'
                  onClick={(e) => download(e, document.path, document.file_name)}
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="far fa-arrow-to-bottom"></i>
                  </span>
                  <span className="btn-inner--text">Baixar</span>
                </a>
                {(new Date(document.created_at).getTime()) > (new Date().getTime() - ANALYS_DELETE_FILE_TIME) && (
                  <a
                    className='btn btn-danger btn-sm btn-icon'
                    onClick={(e) => canDelete(e, document)}
                    target="_blank"
                  >
                    <span className="btn-inner--icon">
                      <i className="far fa-trash"></i>
                    </span>
                    <span className="btn-inner--text">Apagar</span>
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <input type="file"
        ref={hiddenFileInput}
        onChange={uploadFile}
        style={{ display:'none' }}
      />

      {/* <UploadFileModal
        documentSelected={documentSelected}
        onClose={() => setDocumentSelected(-1)}
        onSubmit={onSubmit}
      /> */}
    </section>
  );
}
