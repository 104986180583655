import React, { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useHistory } from 'react-router'
import {
  Col,
  Row,
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
} from 'reactstrap'

import { CONTACT_TYPE_ONLY_YOURS } from 'modules/common/route/route';
import { loadAnalysisStatus } from 'modules/common/hooks/use-analyses-basic-data';
import useLoggedUser from 'modules/auth/hooks/use-logged-user'

function ProcessesFilter({ onSearchChange, filterStatus, selectFilterStatus, newProcesses }) {
  const { replace } = useHistory();
  const [ status, setStatus ] = useState([]);
  const { user } = useLoggedUser();
  const filters = [
    { label: 'Sem filtro', value: -1 },
    ...status.map((status) => ({label: status.name, value: status.id})),
  ];
  const selectedFilter = filters.filter(f => filterStatus.indexOf(f.value) !== -1);

  useEffect(() => {
    if (user?.contactType && user?.contactType !== CONTACT_TYPE_ONLY_YOURS) {
      loadAnalysisStatus().then((data) => {
        if (filterStatus.length === 0) {
          selectFilterStatus(data.filter(d => d.is_open).map(d => d.id));
        }
        setStatus(data);
      });
    } else {
      selectFilterStatus([]);
    }
  }, [user]);

  return (
    <Row>
      {user?.contactType && user?.contactType !== CONTACT_TYPE_ONLY_YOURS && (
        <>
          <Col xs="6">
            <InputGroup className="mr-3 mb-3 mb-md-0">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-search"/>
                </InputGroupText>
              </InputGroupAddon>
              <DebounceInput
                className='form-control'
                placeholder="Buscar"
                type="text"
                minLength={ 3 }
                debounceTimeout={ 500 }
                onChange={ event => onSearchChange(event.target.value) }
              />
            </InputGroup>
          </Col>

          <Col xs="2" className='filter-status pl-0' >
            <UncontrolledDropdown id="filter-dropdown">
              <DropdownToggle caret className='px-2 px-lg-3'>
                <i className="far fa-filter"/> <span className='d-none d-xl-inline'>Status</span>
                { selectedFilter.length > 0 && (
                  <Badge
                    color="danger"
                    className="badge-md badge-circle badge-floating border-white"
                  >
                    <i className="far fa-check"/>
                  </Badge>
                ) }
              </DropdownToggle>
              <DropdownMenu>
                { filters.map(({
                  label,
                  value
                }) => (
                  <DropdownItem
                    key={ value }
                    onClick={() => {
                      if (value === -1) {
                        selectFilterStatus([]);
                        return;
                      }
                      const index = filterStatus.indexOf(value);
                      if (index !== -1) {
                        filterStatus.splice(index, 1)
                        selectFilterStatus(filterStatus);
                      } else {
                        selectFilterStatus([...filterStatus, value]);
                      }
                    }}
                    className={ filterStatus.indexOf(value) !== -1 ? 'selected' : '' }
                  >
                    {label}
                  </DropdownItem>
                )) }
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          {/* <UncontrolledTooltip placement="top" target="filter-dropdown">
            { filterStatus.length > 0 ? `Filtrando por ${ selectedFilter.map(s => s.label).join(',') }` : 'Filtrar' }
          </UncontrolledTooltip> */}
        </>
      )}

      {newProcesses && (
        <Col xs="4" className='pl-0 text-right ml-auto'>
          <Button
            outline
            className="btn-icon text-truncate"
            color="default"
            onClick={ () => replace('/processes/new') }
          >
            <div className='text-truncate'>
              <span className="btn-inner--icon">
                <i className="far fa-plus-circle"/>
              </span>
              <span
                className="btn-inner--text"
              >Novo <span className='d-none d-xl-inline'>processo</span></span>
            </div>
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default ProcessesFilter
