function formikValidateInput(
  formik,
  name,
) {
  return Boolean(
    (formik.errors[name] && formik.getFieldMeta(name).value)
    || (formik.submitCount > 0 && formik.errors[name])
  )
}

export default formikValidateInput
