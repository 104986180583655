import * as Yup from 'yup';

export const homeAddressSchema = Yup.object().shape({
  addressTypeId: Yup.string().required('Campo obrigatório'),
  street: Yup.string().required('Campo obrigatório'),
  number: Yup.number().required('Campo obrigatório'),
  complement: Yup.string(),
  neighborhood: Yup.string().required('Campo obrigatório'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
  zipCode: Yup.string().required('Campo obrigatório'),
  residenceTime: Yup.number().required('Campo obrigatório'),
  residenceTimeUnit: Yup.number().required('Campo obrigatório'),
})

export default homeAddressSchema;
