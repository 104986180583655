import * as Yup from 'yup';
import validateCpf from "../utils/cpf-validator";

const cpfValidator = Yup.string().test('cpf', function (value) {
  const isValid = validateCpf(value);
  if (!isValid) {
    return this.createError({ message: 'CPF inválido' });
  }

  return true;
})

export default cpfValidator
