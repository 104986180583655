import React, { useEffect, useState } from 'react'
import {Button, Form, FormFeedback, FormGroup, Input} from 'reactstrap'
import {useFormik} from "formik";

import mapIdNameToSelect from 'modules/common/utils/id-name-mapper'
import DateInput from 'modules/common/components/DateInput/DateInput'
import Select from 'modules/common/components/Select/Select'
import useStates from 'modules/common/hooks/use-states'
import useCities from 'modules/common/hooks/use-cities'
import useCountries from 'modules/common/hooks/use-countries'
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";
import formikValidateInput from "modules/common/validations/formik-validate-input";
import documentsSchema from "modules/processes/validators/documents";
import { useDetectChange } from 'modules/common/context';

export function Documents({ onSubmit, customer }) {
  const { setChange } = useDetectChange();

  const formik = useFormik({
    initialValues: {
      documentNumber: customer?.documentNumber || '',
      documentIssuedBy: customer?.documentIssuedBy || '',
      documentIssueDate: customer?.documentIssueDate || '',
      documentIssuedStateId: customer?.documentIssueLocation || '',
      homeStateId: String(customer?.homeState?.id || ''),
      homeCityId: String(customer?.homeCity?.id || ''),
      id: customer?.pkHash,
    },
    onSubmit,
    validationSchema: documentsSchema,
  })

  const [selectedStateId, setSelectedStateId] = useState()

  const { countries } = useCountries()
  const country = countries.find(({ name }) => name.trim().toLowerCase().includes('brasil'))
  const { states } = useStates(country?.id)
  const { cities } = useCities(selectedStateId)

  useEffect(() => {
    // We need to have selectedState so we can get the pkHash and get the cities
    if (!isNaN(formik.values.homeStateId) && states.length > 0) {
      const state = states.find(({ pkId }) => pkId === Number(formik.values.homeStateId))
      setSelectedStateId(state?.id)
    }
  }, [formik.values.homeStateId, states])

  useEffect(() => {
    setChange(JSON.stringify(formik.values) !== JSON.stringify(formik.initialValues))
  }, [formik.values, formik.initialValues]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Input readOnly hidden name="id" value={customer?.pkHash} />
      <div className="text-primary font-weight-bold mb-3">
        Documento de identificação
      </div>
      <FormGroup>
        <label htmlFor="documentNumber">RG</label>
        <Input
          id="documentNumber"
          placeholder="Digite aqui"
          name="documentNumber"
          onChange={formik.handleChange}
          value={formik.values.documentNumber}
          invalid={formikValidateInput(formik, 'documentNumber')}
        />
        <FormFeedback>
          {formik.errors.documentNumber}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="documentIssuedBy">Orgão emissor</label>
        <Input
          id="documentIssuedBy"
          placeholder="Digite aqui"
          name="documentIssuedBy"
          onChange={formik.handleChange}
          value={formik.values.documentIssuedBy}
          invalid={formikValidateInput(formik, 'documentIssuedBy')}
        />
        <FormFeedback>
          {formik.errors.documentIssuedBy}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <label htmlFor="documentIssuedStateId">Local de emissão</label>
        <Select
          id="documentIssuedStateId"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(states)}
          name="documentIssuedStateId"
          onChange={e => e.target.value && formik.handleChange(e)}
          value={formik.values.documentIssuedStateId}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'documentIssuedStateId')}>
          {formik.errors.documentIssuedStateId}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="documentIssueDate">Data de emissão</label>
        <DateInput
          id="documentIssueDate"
          name="documentIssueDate"
          onChange={formik.handleChange}
          value={formik.values.documentIssueDate}
          invalid={formikValidateInput(formik, 'documentIssueDate')}
        />
        <FormFeedback>
          {formik.errors.documentIssueDate}
        </FormFeedback>
      </FormGroup>
      <div className="text-primary font-weight-bold mb-3">
        Naturalidade
      </div>
      <FormGroup>
        <label htmlFor="homeStateId">UF</label>
        <Select
          id="homeStateId"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(states, 'pkId')}
          name="homeStateId"
          onChange={e => e.target.value && formik.handleChange(e)}
          value={formik.values.homeStateId}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'homeStateId')}>
          {formik.errors.homeStateId}
        </FormFeedbackSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="homeCityId">Cidade</label>
        <Select
          id="homeCityId"
          className="form-control"
          options={{ placeholder: "Selecione" }}
          data={mapIdNameToSelect(cities)}
          name="homeCityId"
          onChange={e => e.target.value && formik.handleChange(e)}
          value={formik.values.homeCityId}
        />
        <FormFeedbackSelect invalid={formikValidateInput(formik, 'homeCityId')}>
          {formik.errors.homeCityId}
        </FormFeedbackSelect>
      </FormGroup>

      <Button
        block
        outline
        color="default"
        type="submit"
        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
      >Salvar e continuar</Button>
    </Form>
  )
}
