import api from "modules/common/utils/api";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Button, Container } from "reactstrap";

import sanitizeHtml from 'sanitize-html';


const defaultOptions = {
  allowedTags: [
    "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
    "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
    "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
    "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
    "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
    "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
    "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img"
  ],
  allowedAttributes: {
    div: [ 'style', 'class' ],
    table: [ 'style', 'class', 'border', 'cellpadding'],
    td: [ 'style', 'class', 'colspan', 'width'],
    tr: [ 'style', 'class' ],
    a: [ 'href', 'name', 'target', 'class' ],
    // We don't currently allow img itself by default, but
    // these attributes would make sense if we did.
    img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading', 'class' ]
  },
  selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
  allowedSchemes: [ 'http', 'https' ],
  allowedSchemesAppliedToAttributes: [ 'href', 'src' ],
  allowedIframeHostnames: []
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(
      dirty,
      { ...defaultOptions, ...options },
  )
});

const SanitizeHTML = ({ html, options }) => {
  const sanitizedHtml = sanitize(html, options);
  return <div dangerouslySetInnerHTML={sanitizedHtml} />
};


export const AnnouncementDetail = () => {
  const params = useParams()
  const {replace} = useHistory()
  const [announcement, setAnnouncement] = useState([])
  const [date, setDate] = useState("")

  useEffect(() => {
    api.get(`legacy/announcements/${params.id}/`).then(({data})=> {
      const {dateadded} = data
      // formatando timestamp para DD/MM/YYYY
      const dateFormat = `${dateadded.slice(8,10)}/${dateadded.slice(5,7)}/${dateadded.slice(0,4)}`

      setAnnouncement(data)
      setDate(dateFormat)
    })
  }, [])
  
  return (
    <Container fluid>
      <div className="mt-5">
        <h2>{announcement.name}</h2>
        <p className="small text-light"> Publicado em {date}</p>
      </div>
      <Button color="primary" onClick={()=> replace("/announcements")} className="my-4 p-3">
        <i className="far fa-angle-left mr-2"></i>
        Voltar
      </Button>
      <SanitizeHTML html={announcement.message}/>
    </Container>
  );
};
