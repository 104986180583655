import Cookies from 'js-cookie'
import axios from 'axios'
import Swal from 'sweetalert2'

import {COOKIE_AUTH_KEY} from "../../auth/constants/cookies";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
  },
});

api.interceptors.request.use(config => {
  const token = Cookies.get(COOKIE_AUTH_KEY)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
});

api.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, (error) => {
  if (error.response?.data?.detail === 'No fields were updated.') {
    return Swal.fire({
      title: 'Nenhum campo foi atualizado',
      icon: 'warning',
      confirmButtonText: 'Fechar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
        // closeButton: 'btn btn-primary',
        // denyButton: 'btn btn-danger',
        // cancelButton: 'btn btn-outline-default',
        // container: '...',
        // popup: '...',
        // header: '...',
        // title: '...',
        // icon: '...',
        // image: '...',
        // htmlContainer: '...',
        // input: '...',
        // inputLabel: '...',
        // validationMessage: '...',
        // actions: '...',
        // loader: '...',
        // footer: '....',
        // timerProgressBar: '....',
      }
    }).then(() => Promise.reject(error.response));
  }

  return Promise.reject(error);
});

export default api;
