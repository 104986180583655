import { matchPath, useParams } from "react-router";
import { useHistory } from "react-router-dom";

import DashboardHome from "modules/home/pages/home/Home";
import Simulation from "modules/simulation/pages/simulation/Simulation";
import Processes from "modules/processes/pages/processes/Processes";
import ProcessDetails from "modules/processes/pages/process_details/ProcessDetails";
import ProcessDirectLogin from "modules/processes/pages/process_details/ProcessDirectLogin";
import Tickets from "modules/tickets/pages/tickets/Tickets";
import TicketDetails from "modules/tickets/pages/ticketdetails/TicketDetails";
import Consultant from "modules/consultant/pages/consultant/Consultant";
import Report from "modules/report/pages/report/Report";
import Redirect from "modules/redirect/Redirect";
import { AnnouncementDetail } from "modules/announcements/announcementDetail";
import { Announcements } from "modules/announcements/announcements";

export const CONTACT_TYPE_ALL = "1";
export const CONTACT_TYPE_CONTACT = "2";
export const CONTACT_TYPE_ONLY_YOURS = "3";
export const CONTACT_ANALYSIS_READ_ONLY = "4";

const routeDict = {
    'dashboard': {
        path: "/dashboard",
        name: "Inicio",
        icon: "far fa-home",
        component: DashboardHome,
        layout: "",
    },
    'simulations': {
        path: "/simulations",
        name: "Simulador",
        icon: "far fa-calculator",
        // icon: "far fa-flask",
        component: Simulation,
        layout: "",
    },
    'consultant': {
        path: "/consultant",
        name: "Consultor",
        icon: "far fa-home",
        component: <></>,
        layout: "",
    },
    'redirect': {
      path: "/redirect",
      name: "",
      icon: "",
      views: [
        {
          path: "/redirect/:project/:task?",
          component: Redirect,
          layout: "",
        },
      ],
      // component: Redirect,
      layout: "",
    },
    'processes': {
        path: "/processes",
        name: "Processos",
        icon: "far fa-scroll",
        layout: "",
        views: [
          {
            path: [
              "/processes/:id/:tab?/:step?/:stepTab?",
            ],
            component: ProcessDetails,
            layout: "",
          },
          {
            path: "/processes",
            component: Processes,
            layout: "",
          },
          {
            path: "/direct/:id/:tab?/:step?/:stepTab?",
            component: ProcessDirectLogin,
            layout: "",
          },
        ]
    },
    // {
    //   path: "/invoices",
    //   name: "Resultados",
    //   icon: "far fa-chart-line",
    //   component: Invoice,
    //   layout: "",
    // },
    'tickets': {
        path: "/tickets",
        name: "Chamados",
        icon: "far fa-life-ring",
        layout: "",
        views: [
          {
            path: "/tickets/:id",
            component: TicketDetails,
            layout: "",
          },
          {
            path: "/tickets",
            component: Tickets,
            layout: "",
          },
        ],
    },
        'consultants': {
          path: "/consultants",
          name: "Consultores",
          icon: "far fa-user-tie",
          component: Consultant,
          layout: "",
        },
    'reports': {
        path: "/reports",
        name: "Relatórios",
        icon: "far fa-analytics",
        // icon: "far fa-flask",
        component: Report,
        layout: "",
    },
    'announcements': {
      path: "/announcements",
      name: "",
      icon: "",
      layout: "",
      views: [
        {
          path: "/announcements/:id",
          component: AnnouncementDetail,
          layout: "",
        },
        {
          path: "/announcements",
          component: Announcements,
          layout: "",
        },
      ],
    }
};

export const ROUTE_ACCESS_ADMIN = {
  [CONTACT_TYPE_ALL]: [
    "/dashboard",
    "/direct",
    "/processes",
    "/simulations",
    "/tickets",
    "/consultants",
    "/reports",
    "/redirect",
    "/announcements",
  ],
  [CONTACT_TYPE_CONTACT]: [
    "/dashboard",
    "/direct",
    "/processes",
    "/simulations",
    "/tickets",
    "/consultants",
    "/reports",
    "/redirect",
    "/announcements",
  ],
  [CONTACT_TYPE_ONLY_YOURS]: [
    "/direct",
    "/processes",
    "/tickets",
    "/redirect",
  ],
  [CONTACT_ANALYSIS_READ_ONLY]: [
    "/processes",
    "/redirect",
  ]
};

export const useMatchRoute = () => {
  const { location } = useHistory();
  return () => {
    for (let route in routeDict) {
      const currentRoute = routeDict[route];
      const { views, layout, path: rootPath } = currentRoute;
      if (typeof views === 'undefined') {
        const m = matchPath(location.pathname, {
          path: layout + rootPath,
          exact: true,
          strict: false
        });

        if (m) {
          return {
            match: m,
            route: currentRoute,
            view: undefined,
          };
        }

      } else {
        for (let i = 0; i < views.length; i++) {
          let paths = views[i].path;
          if (typeof(paths) === "string") {
            paths = [paths]
          }
          for (let j = 0; j < paths.length; j++) {
            const m = matchPath(location.pathname, {
                path: layout + paths[j],
                exact: true,
                strict: false
            });

            if (m) {
              return {
                match: m,
                route: currentRoute,
                view: views[i],
              };
            }
          }
        }
      }
    }

    return;
  }
};

const useReplaceParams = () => {
    const { push, replace, } = useHistory();
    const params = useParams();
    const matchRoute = useMatchRoute();

    const exec = (apply) => {
      return (newParams) => {
        const { match } = matchRoute();
        const params = {...match.params, ...newParams};

        let path = match.path
        for (const key of Object.keys(params)) {
            let value = "";
            if (key in params){
                value = params[key] ? `/${params[key]}` : "";
            }
            path = path.replace(new RegExp(`\\/:${key}\\??`), `${value}`);
        }

        apply(path);
      };
    }

    return {
        push: exec(push),
        replace: exec(replace),
        params,
    };
};

export const useRouteProcess = () => useReplaceParams();
export const useRoute = () => useReplaceParams();


export const routes = Object.values(routeDict);
