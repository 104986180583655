import React from 'react';
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'br', 'img'],
    allowedAttributes: {
        img: [ 'src', ]
    },
    allowedIframeHostnames: []
};

const sanitize = (dirty, options) => ({
    __html: sanitizeHtml(
        dirty, 
        { ...defaultOptions, ...options },
    )
});

export const SanitizeHTML = ({ html, options }) => {
    const sanitizedHtml = sanitize(html, options);
    return <div dangerouslySetInnerHTML={sanitizedHtml} />
};
