import React, { useState, useEffect } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Modal } from 'reactstrap';
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Row, Col } from "reactstrap";
import Select from 'modules/common/components/Select/Select'
import { loadAllConsultants, changeConsultant } from 'modules/common/hooks/use-consultant';
import formikValidateInput from 'modules/common/validations/formik-validate-input';
import FormFeedbackSelect from "modules/common/components/FormFeedbackSelect/FormFeedbackSelect";

export const ChangeConsultantModal = ({
  consultant,
  open,
  onConfirm,
  onReject,
}) => {
  const [ consultants, setConsultants ] = useState();
  const [ textRead, setTextRead ] = useState(false);

  const confirmationText = () => {
    setTextRead(true);
  };

  const messageError = () => {
    Swal.fire({
      title: 'Desculpe. Ocorreu um erro não foi possivel solicitar a alteração do consultor.',
      icon: 'error',
    }).then(onReject);
  };

  const handleConfirm = async (data) => {
    changeConsultant({
      consultant_id: consultant.id,
      new_consultant_id: data.consultant,
      message: data.observation,
    }).then((data) => {
      if (data) {
        onConfirm();
      } else {
        messageError()
      }
    }).catch(messageError);
  };

  const formik = useFormik({
    initialValues: {
      consultant: undefined,
      observation: undefined,
    },
    onSubmit: handleConfirm,
    validationSchema: Yup.object().shape({
      consultant: Yup.string().required('Campo obrigatório'),
    }),
  });

  useEffect(() => {
    if (open) {
      loadAllConsultants(consultant.id).then((data) => setConsultants(data));
      setTextRead(false);
      formik.resetForm();
    }
  }, [loadAllConsultants, open, consultant]);

  return (
    <Modal isOpen={open} toggle={onReject} size="lg">
      <div className="modal-body">
        <section className="container">
          <div>
            <h1 className='mt-4'>Troca discreta de consultor</h1>
          </div>

          <aside className="mt-4">
            {textRead && (
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col>
                    <FormGroup>
                      <label htmlFor="consultant">Novo consultor</label>
                      <Select
                        id="consultant"
                        className="form-control"
                        options={{ placeholder: "Selecione" }}
                        data={consultants?.map((consultant) => ({id: consultant.id, text: consultant.fullname }) )}
                        defaultValue={formik.values.consultant}
                        onChange={e => e.target.value && formik.handleChange(e)}
                        name="consultant"
                      />
                      <FormFeedbackSelect invalid={formikValidateInput(formik, 'consultant')}>
                        {formik.errors.consultant}
                      </FormFeedbackSelect>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label htmlFor="number">Quer deixar alguma observação?</label>
                      <Input
                        id="observation"
                        placeholder="Campo de preenchimento opcional"
                        name="observation"
                        defaultValue={formik.values.observation}
                        onChange={formik.handleChange}
                        invalid={formikValidateInput(formik, 'observation')}
                      />
                      <FormFeedback>
                        {formik.errors.observation}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={onReject}
                  >
                    Cancelar
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                  >
                    Confirmar
                  </Button>
                </div>
              </Form>
            )}

            {!textRead && (<>
              
              
                <p>Nosso objetivo é garantir sua satisfação e proporcionar uma <strong>experiência positiva</strong>.</p>
                <p>Caso não esteja satisfeito com seu consultor, você pode solicitar a troca a qualquer momento.</p>
              
              
                <p>
                  O processo de troca será discreto, anônimo e livre de riscos ou desconforto. Nosso time estará comprometido em realizar a transição de forma suave e eficiente, garantindo sigilo, segurança das informações e andamento das propostas que estiverem em curso.
                </p>
             
                <p>Estamos à disposição para assegurar a melhor experiência possível.</p>
                
              <div style={{marginBottom: "10px"}}>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={onReject}
                  >
                    Cancelar
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                    onClick={() => confirmationText()}
                  >
                    Continuar
                  </Button>
                </div>
              </div>
            </>)}

          </aside>
        </section>
      </div>
    </Modal>
  );
};
