import { Col, Row } from "reactstrap";
import classNames from "classnames";
import Swal from 'sweetalert2';

import { useDetectChange } from "modules/common/context";

export const ListeSteps = ({ wizardSteps, finishedWizards, setCurrentWizard, currentWizard }) => {
    const { canChange } = useDetectChange();
    return (
      <Col lg="3">
        <Row>
          {wizardSteps.map(step => (
            <Col key={step.index} lg="12" md="6">
              <div
                onClick={async () => {
                  if (finishedWizards.includes(step.index) || finishedWizards.includes(step.index-1)) {
                    if (await canChange()) {
                      setCurrentWizard(step.index);
                    }
                  } else {
                    Swal.fire({
                      // title: 'Sucesso!',
                      text: 'Para avançar, informe os dados e salve a etapa atual.',
                      icon: 'warning',
                    });
                  }
                }}
                className={classNames(
                  "rounded py-4 px-4 mb-3 btn-wizard font-weight-bold",
                  "d-flex justify-content-between align-items-center",
                  {
                    "text-primary": finishedWizards.includes(step.index-1),
                    "text-black-50": !finishedWizards.includes(step.index) && !finishedWizards.includes(step.index-1),
                    "border border-primary btn-wizard--selected": step.index === currentWizard,
                    "text-muted btn-wizard--filled": finishedWizards.includes(step.index),
                  }
                )}
                role="button"
              >
                {step.title}
                {finishedWizards.includes(step.index) && (
                  <i className="fa fa-check-circle text-success" />
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    );
};
