import classNames from "classnames";
import React from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";

const PagePagination = ({ pagination, page, onChange }) => {
  const nextPage = (e) => {
    e.preventDefault();
    onChange(Math.min(page + 1, pagination.last_page));
  };

  const prevChange = (e) => {
    e.preventDefault();
    onChange(Math.max(0, page - 1));
  };

  const changePage = (e, page) => {
    e.preventDefault();
    onChange(page);
  };

  if (!pagination) {
    return <></>;
  }

  const range = (start, end) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };


  const count = pagination.num_pages;
  const boundaryCount = 1;
  const siblingCount = 2;

  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);
  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1,
    ),
    // Greater than startPages
    boundaryCount + 2,
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2,
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1,
  );


  const items = [
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
      ? ['...']
      : boundaryCount + 1 < count - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < count - boundaryCount - 1
      ? ['...']
      : count - boundaryCount > boundaryCount
      ? [count - boundaryCount]
      : []),


    ...endPages,
  ];
  
  return pagination && (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem className={classNames({ "disabled": page === 1 })}>
        <PaginationLink
          onClick={prevChange}
          tabIndex="-1"
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      
      
      {items.map((item) => (
        <PaginationItem key={item} className={classNames({ "disabled": page === item || item === '...' })}>
            <PaginationLink
              onClick={(e) => changePage(e, item)}
            >
              {item}
            </PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem className={classNames({ "disabled": page === count })}>
        <PaginationLink
          onClick={nextPage}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
}

export default PagePagination;