import ClientReadCgv from "modules/processes/pages/process_details/steps/ClientReadCgv"
import ClientRead from "modules/processes/pages/process_details/steps/ClientRead"
import ClientReadCgi from "modules/processes/pages/process_details/steps/ClientReadCgi"

function ClientReaderSelect({ analysis }) {
  if(analysis?.product?.categorySlug === "cgv"){
    return(<ClientReadCgv analysis={analysis}/>)
  }
  if(analysis?.product?.categorySlug === "cgi"){
    return(<ClientReadCgi analysis={analysis}/>)
  }
  return (
    <ClientRead analysis={analysis}/>
  );
}
export default ClientReaderSelect