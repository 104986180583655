import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TagManager from 'react-gtm-module'

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
// import "@fullcalendar/common/main.min.css";
// import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
// import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/fontawesome5/css/all.min.css";
// plugins styles downloaded
// import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/credihabitar-app.scss";
import "react-chat-elements/dist/main.css"

import Admin from "modules/admin/pages/admin/Admin";
import Login from "modules/auth/pages/login/Login";
import AutomaticLogin from "modules/authomatic-login/AutomaticLogin";
import AuthRoute from "modules/common/components/AuthRoute/AuthRoute";
import AnalysisContextProvider from "modules/processes/contexts/AnalysisContext";
import SimulationContextProvider from "modules/simulation/contexts/SimulationContext";
import { DetectDataChangeProvider } from "modules/common/context/DetectDataChange";
import { LoadingProvider } from "modules/common/context/Loading";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <SimulationContextProvider>
      <AnalysisContextProvider>
        <BrowserRouter>
          <LoadingProvider>
            <DetectDataChangeProvider>
              <Switch location={window.location} key={window.location.pathname}>
                <Route path="/recover-password/:id/:token" render={(props) => <Login {...props} />} />
                <Route path={["/login/:redirect", "/login"]} render={(props) => <Login {...props} />} />

                <Route path="/consultant/processes/:id/:token" render={(props) => <AutomaticLogin type="consultant" {...props} />} />
                <Route path="/log-as-customer/:id/:token" render={(props) => <AutomaticLogin type="customer" {...props} />} />

                <AuthRoute path="/" render={(props) => <Admin {...props} />} />

                <Redirect from="*" to="/" />
              </Switch>
            </DetectDataChangeProvider>
            </LoadingProvider>
        </BrowserRouter>
      </AnalysisContextProvider>
    </SimulationContextProvider>,
  document.getElementById("root")
);
