import * as Yup from 'yup'
import formatCurrency from "../../common/utils/currency-formatter";
import moment from "moment";

export const getFinanceAmountCGVSchema = (products, isSimulation = false) => {
  return Yup.object().shape({
    productId: Yup.number().required('Campo obrigatório'),
    brandFipe: Yup.number().required('Marca é obrigatório'),
    brand: Yup.string().required('Marca é obrigatório'),
    modelFipe: Yup.number().required('Modelo é obrigatório'),
    model: Yup.string().required('Modelo é obrigatório'),
    year: Yup.string().required('Ano é obrigatório'),
    vehicletype: Yup.number().required("Tipo e obrigatorio"),
    mortgage: Yup
      .number()
      .test('minValue', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value >= validations?.minLoanValue) return true

        return this.createError({ message: `O valor mínimo é ${formatCurrency(validations?.minLoanValue, 'BRL')}`})
      })
      .test('maxValue', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        
        if (value <= validations.maxGoodPrice) return true
        return this.createError({ message: `O valor maximo é ${formatCurrency(validations.maxGoodPrice, 'BRL')}`})
      })
      .test('testValueWithPropertyPrice', function (value, {parent}) {
        const validations = products.find(product => product.id === parent?.productId)
        const maxLoanValue = parent?.propertyPrice * validations?.maxLoanPercent

        if(value <= maxLoanValue && value < parent?.propertyPrice) return true
        return this.createError({ message: `O valor máximo é ${formatCurrency(maxLoanValue, 'BRL')}`})
      }).required('Nome é obrigatório'),
    installments: Yup
      .number()
      .test('minInstallments', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value >= validations?.minTimeMonths) return true

        return this.createError({ message: `O valor mínimo é ${validations?.minTimeMonths}`})
      })
      .test('maxInstallments', function (value, { parent }) {
        const validations = products.find(product => product.id === parent?.productId)
        if (!validations) return true
        if (value <= validations?.maxTimeMonths) return true

        return this.createError({ message: `O valor máximo é ${validations?.maxTimeMonths}`})
      })
      .required('Campo obrigatório'),
    isFinanceDocumentation: Yup.boolean().default(false),
    financeAnalysisAndRatings: Yup.boolean().default(false),
    isUsingFgts: Yup.boolean().default(false),
    birthDate: Yup.string().test('required', function (value, { parent }) {
      if (!isSimulation) return true

      if (!value) return this.createError({ message: 'Campo obrigatório' })

      const validations = products.find(product => product.id === parent?.productId)
      if (!validations) return true

      if (moment().diff(moment(value), 'years') < validations?.minAge) {
        return this.createError({ message: `O cliente deve ter no mínimo ${validations?.minAge} anos`})
      }

      if (moment().diff(moment(value), 'years') > validations?.maxAge) {
        return this.createError({ message: `O cliente deve ter no máximo ${validations?.maxAge} anos`})
      }

      return true
    })
  })
}
