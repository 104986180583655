import React, { useEffect, useState } from "react";
import { Button, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { isEmpty } from "ramda";
import CurrencyInput from "modules/common/components/CurrencyInput/CurrencyInput";
import DateInput from "modules/common/components/DateInput/DateInput";
import useProducts from "modules/common/hooks/use-products";
import { useFormik } from "formik";
import getFinanceAmountSchema from "../../../processes/validators/finance-amount";
import formikValidateInput from "modules/common/validations/formik-validate-input";

function SimulationForm ({ onSubmit, productId=1 }) {
  const products = useProducts();
  const formik = useFormik({
    initialValues: {
      productId: productId,
      propertyPrice: "",
      mortgage: "",
      installments: "",
      isFinanceDocumentation: false,
      isFinanceAnalysisAndRatings: false,
      birthDate: "",
    },
    onSubmit,
    validationSchema: getFinanceAmountSchema(products, true),
  });

  useEffect(() => {
    if(productId != formik.initialValues.productId && !isEmpty(productId)){
      formik.setFieldValue('productId', productId)
    }
  }, [productId, formik.initialValues])

  let maxDate = new Date().toJSON().slice(0, 10);
  let minDate = new Date().toJSON().slice(0, 10);
  maxDate = Number(maxDate.slice(0, 4)) - 18 + maxDate.slice(4);
  minDate = Number(minDate.slice(0, 4)) - 80 + minDate.slice(4);
  
  return (
      <Form className="py-4" onSubmit={formik.handleSubmit}>
        
        <FormGroup>
          <label htmlFor="propertyPrice">Valor do imóvel</label>
          <CurrencyInput
            id="propertyPrice"
            placeholder="R$ digite aqui"
            name="propertyPrice"
            onChange={formik.handleChange}
            value={formik.values.propertyPrice}
            invalid={formikValidateInput(formik, "propertyPrice")}
          />
          <FormFeedback>{formik.errors.propertyPrice}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="mortgage">Valor financiado</label>
          <CurrencyInput
            id="mortgage"
            placeholder="R$ digite aqui"
            name="mortgage"
            onChange={formik.handleChange}
            value={formik.values.mortgage}
            invalid={formikValidateInput(formik, "mortgage")}
          />
          <FormFeedback>{formik.errors.mortgage}</FormFeedback>
        </FormGroup>
        
        <FormGroup>
          <label htmlFor="birthDate">Data de nascimento</label>
          <DateInput
            id="birthDate"
            name="birthDate"
            max={maxDate}
            min={minDate}
            onChange={formik.handleChange}
            value={formik.values.birthDate}
            invalid={formikValidateInput(formik, "birthDate")}
          />
          <FormFeedback>{formik.errors.birthDate}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="installments">Prazo (em meses)</label>
          <Input
            id="installments"
            placeholder="Digite..."
            type="number"
            min={formik.values.installments}
            name="installments"
            onChange={formik.handleChange}
            defaultValue={formik.values.installments}
            invalid={formikValidateInput(formik, "installments")}
          />
          <FormFeedback>{formik.errors.installments}</FormFeedback>
        </FormGroup>
        {/* <FormGroup className="custom-control custom-checkbox">
          <Input
            id="isFinanceAnalysisAndRatings"
            type="checkbox"
            className="custom-control-input"
            name="isFinanceAnalysisAndRatings"
            onChange={formik.handleChange}
            value={formik.values.isFinanceAnalysisAndRatings}
            invalid={formikValidateInput(formik, "isFinanceAnalysisAndRatings")}
          />
          <label
            htmlFor="isFinanceAnalysisAndRatings"
            className="custom-control-label"
          >
            Financiar análise e avaliação? (A depender da instituição)
          </label>
          <FormFeedback>
            {formik.errors.isFinanceAnalysisAndRatings}
          </FormFeedback>
        </FormGroup> */}

       
        <div className="custom-control custom-checkbox mb-5">
          <input
            id="isFinanceDocumentation"
            type="checkbox"
            className="custom-control-input"
            name="isFinanceDocumentation"
            onChange={formik.handleChange}
            value={formik.values.isFinanceDocumentation}
          />
          <label
            htmlFor="isFinanceDocumentation"
            className="custom-control-label"
          >
            Financiar ITBI/Cartório/IOF? (A depender da instituição e Produto)
          </label>
          <FormFeedback>{formik.errors.isFinanceDocumentation}</FormFeedback>
        </div>
        

        <Button block color="primary" type="submit">
          Simular
        </Button>
      </Form>
  );
}

export default SimulationForm;
