
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { zipObject } from "lodash";
import { useParams, useHistory } from 'react-router'
import Swal from "sweetalert2";

import { loadProducts } from 'modules/common/hooks/use-products';
import {
  loadJobTitles,
  loadJobTypes,
  loadResidenceTimeUnit,
  loadPurchaseEstimates,
  loadGenders,
  loadMarriageStatus,
  loadMarriageSystems,
  loadContractTypes,
  loadPropertyTypes,
  loadPropertyUseTypes,
  loadCountries,
} from 'modules/common/hooks/use-analyses-basic-data';

import { AnalysisContext } from "modules/processes/contexts/AnalysisContext";

import {
  BuyingDate,
  FinanceAmount,
  HomeAddress,
  Income,
  LeadInfo,
  RegisterData,
  Documents,
  PropertyInfo,
} from './wizard';
import { ListeSteps } from "./ListeSteps";

export const ClientCustomer = ({ currentWizard, setCurrentWizard, analysis, onFinished, createBidder,productSelected }) => {
  const { replace } = useHistory();
  const { id } = useParams();

  const { 
    submitAnalysis,
    submitCustomer,
    submitMarriageData,
    submitPurchaseEstimate,
    submitAddress,
    submitProperty,
    submitIncome,
    submitDocuments,
  } = useContext(AnalysisContext);
  
  const wizardSteps = [
    { 
      index: 0,
      title: 'Valores do financiamento',
      component: (props) => <FinanceAmount
        analysis={analysis}
        products={analysisBasicData.products}
        productSelected={productSelected}
        {...props}
      />,
      onSubmit: async (values, analysis, additionalData = {}) => {
        return await submitAnalysis(values, analysis, additionalData);
      },
      loadData: () => ({
        products: loadProducts(),
      }),
    },
    {
      index: 1,
      title: 'Dados pessoais 1/2',
      component: (props) => (
        <LeadInfo
          simulationBirthDate={analysis?.simulation?.birthDate}
          customer={analysis?.customer}
          genders={analysisBasicData.genders}
          isMain
          {...props}
        />
      ),
      onSubmit: submitCustomer,
      loadData: () => ({
        genders: loadGenders(),
      }),
    },
    { 
      index: 2,
      title: 'Dados pessoais 2/2',
      component: (props) => <RegisterData
        marriageStatus={analysisBasicData.marriageStatus}
        marriageSystems={analysisBasicData.marriageSystems}
        genders={analysisBasicData.genders}
        customer={analysis?.customer}
        {...props}
      />,
      onSubmit: submitMarriageData,
      loadData: () => ({
        marriageStatus: loadMarriageStatus(),
        marriageSystems: loadMarriageSystems(),
        genders: loadGenders(),
      }),
    },
    { 
      index: 3,
      title: 'Documentos',
      component: (props) => <Documents customer={analysis?.customer} {...props} />,
      onSubmit: submitDocuments
    },
    { 
      index: 4,
      title: 'Data de compra do imóvel',
      component: (props) => <BuyingDate
        purchaseEstimates={analysisBasicData.purchaseEstimates}
        analysis={analysis}
        {...props}
      />,
      onSubmit: submitPurchaseEstimate,
      loadData: () => ({
        purchaseEstimates: loadPurchaseEstimates(),
      }),
    },
    {
      index: 5,
      title: 'Endereço residencial',
      component: (props) => <HomeAddress
        residenceTimeUnits={analysisBasicData.residenceTimeUnits}
        customer={analysis?.customer}
        {...props}
      />,
      onSubmit: submitAddress,
      loadData: () => ({
        residenceTimeUnits: loadResidenceTimeUnit(),
      }),
    },
    {
      index: 6,
      title: 'Renda',
      component: (props) => <Income
        jobTitles={analysisBasicData.jobTitles}
        jobTypes={analysisBasicData.jobTypes}
        contractTypes={analysisBasicData.contractTypes}
        customer={analysis?.customer} {...props}
      />,
      onSubmit: submitIncome,
      loadData: () => ({
        jobTitles: loadJobTitles(),
        jobTypes: loadJobTypes(),
        contractTypes: loadContractTypes(),
      }),
    },
    {
      index: 7,
      title: 'Informações do imóvel',
      component: (props) => <PropertyInfo
        propertyTypes={analysisBasicData.propertyTypes}
        propertyUseTypes={analysisBasicData.propertyUseTypes}
        countries={analysisBasicData.countries}
        analysis={analysis}
        productSelected={productSelected}
        {...props}
      />,
      onSubmit: async (e) => {
        const first = !analysis.property;
        await submitProperty(e, analysis);
        if (first) {
          Swal.fire({
            title: 'Cadastro de proponente concluído!',
            html: 'O que deseja fazer agora?',
            icon: 'success',
            width: 800,

            showConfirmButton: true,
            showDenyButton: true,
            showCancelButton: true,
            
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-primary btn-block mb-2 mx-0',
              denyButton: 'btn btn-primary btn-block mb-2 mx-0',
              cancelButton: 'btn btn-outline-dark btn-block mb-2 mx-0',
              actions: 'w-75'
            },

            confirmButtonText: 'Incluir proponente',
            denyButtonText: `Escolher bancos p/ Pré-Análise`,
            cancelButtonText: 'Permanecer no cadastro',
          }).then(({ isConfirmed, isDenied }) => {
            if (isConfirmed) {
              createBidder();
            } else if (isDenied) {
              onFinished();
            }
          });
        }
      },
      loadData: () => ({
        propertyTypes: loadPropertyTypes(),
        propertyUseTypes: loadPropertyUseTypes(),
        countries: loadCountries(),
      }),
    },
  ];

  const [analysisBasicData, setAnalysisBasicData] = useState({
    products: [],
    genders: [],
    marriageStatus: [],
    marriageSystems: [],
    purchaseEstimates: [],
    residenceTimeUnits: [],
    jobTitles: [],
    jobTypes: [],
    contractTypes: [],
    propertyTypes: [],
    propertyUseTypes: [],
    countries: [],
  });

  const [ finishedWizards, setFinishedWizards ] = useState([]);

  const step = wizardSteps.find(step => step.index === currentWizard);

  const submitStep = async (event) => {
    event.preventDefault?.();
    const ret = await step.onSubmit?.(event, analysis);
    nextStep(ret);

    if (id  === "new") {
      replace(`/processes/${ret}`);
    }
  };

  const nextStep = (ret) => {
    const currentStep = step.index;
    setFinishedWizards(finished => [...finished, currentStep]);
    setCurrentWizard(current => {
      if (wizardSteps.length - 1 === current) {
        return current;
      }
      return currentStep + 1;
    });
  };

  const loadCustomerState = (analysis) => {
    const finishedWizards = []
  
    if (analysis.property) finishedWizards.push(7);
    if (analysis.customer?.income) finishedWizards.push(6);
    if (analysis.customer?.address) finishedWizards.push(5);
    if (analysis.purchaseEstimate) finishedWizards.push(4);
    if (analysis.customer?.documentNumber) finishedWizards.push(3);
    if (analysis.customer?.marriageStatus) finishedWizards.push(2);
    if (analysis.customer) finishedWizards.push(1);
    if (analysis.hash) finishedWizards.push(0);
  
    const currentWizard = Math.min(Math.max(...finishedWizards, 0) + 1, 7);

    return { finishedWizards, currentWizard };
  };

  useEffect(() => {
    if (analysis) {
      const { finishedWizards, currentWizard } = loadCustomerState(analysis);
      setFinishedWizards(finishedWizards);
      setCurrentWizard(currentWizard);
    }
  }, []);

  useEffect(() => {
    if (!analysis) {
      setFinishedWizards([]);
      return;
    }

    const { finishedWizards } = loadCustomerState(analysis);
    setFinishedWizards(finishedWizards);
  }, [analysis]);

  useEffect(() => {
    (async () => {
      const step = wizardSteps.find(step => step.index === currentWizard);
      if (step && step.loadData) {
        const data = step.loadData();
        const dataDone = await Promise.all(Object.values(data));

        setAnalysisBasicData(({
          ...analysisBasicData,
          ...zipObject(Object.keys(data), dataDone),
        }));
      }
    })();
  }, [currentWizard]);

  return (
    <Row>
      <ListeSteps
        wizardSteps={wizardSteps}
        finishedWizards={finishedWizards}
        currentWizard={currentWizard}
        setCurrentWizard={setCurrentWizard}
      />

      <Col lg="4" md="12">
        {step && <step.component onSubmit={submitStep} />}
      </Col>
    </Row>
  );
};