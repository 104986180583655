import camelcaseKeysDeep from "camelcase-keys-deep";
import { createContext } from "react";
import api from "modules/common/utils/api"

export const SimulationContext = createContext()

const SimulationContextProvider = ({ children }) => {
  const submitSimulation = async values => {
    const payload = {
      property_value: values.propertyPrice,
      loan_value: values.mortgage,
      category_id: Number(values.productId),
      time_months: Number(values.installments),
      birth_date: values.birthDate,
      is_finance_documentation: values.isFinanceDocumentation,
      is_finance_analysis_and_ratings: values.isFinanceAnalysisAndRatings,
    }
    const response = await api.post('/simulations/simulate', payload)

    return response.data.map(camelcaseKeysDeep)
  }

  return (
    <SimulationContext.Provider
      value={{
        submitSimulation
      }}
    >
      {children}
    </SimulationContext.Provider>
  )
}

export default SimulationContextProvider
