
import React from "react";
import NotificationAlert from "react-notification-alert";

export const useNotification = ({ ref }) => {
    const notify = ({ type, title, message }) => {
        let options = {
          place: "br",
          type: type,
          message: (
            <div className="alert-text">
              <span className="alert-title" data-notify="title">
                {" "}
                {title}
              </span>
              <span data-notify="message">
                {message}
              </span>
            </div>
          ),
          icon: "ni ni-bell-55",
          autoDismiss: 7,
        };

        ref.current.notificationAlert(options);
    }

    return { notify };
};

export const Notification = ({ innerRef }) => {
    return (
        <div className="rna-wrapper">
            <NotificationAlert ref={innerRef} />
        </div>
    );
};
