import React, { useContext, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { useHistory } from "react-router";
import { head } from 'ramda';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver'
import Swal from "sweetalert2";

import { AnalysisContext } from 'modules/processes/contexts/AnalysisContext';
import { SimulationContext } from 'modules/simulation/contexts/SimulationContext';
import Loading from "modules/common/components/Loading/Loading";

import EmptySimulation from './EmptySimulation';
import Form from './SimulationForm';
import SimulationResult from './SimulationResult';
import SimulationPdf from '../pdf/SimulationPdf';

function Simulation() {
  const [analysisValues, setAnalysisValues] = useState();
  const [simulationResults, setSimulationResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { submitAnalysis } = useContext(AnalysisContext);
  const { submitSimulation } = useContext(SimulationContext);
  const { replace } = useHistory();

  const handleSubmit = async values => {
    const simulationResults = await submitSimulation(values);

    setSimulationResults(simulationResults);
    setAnalysisValues(values);
  };

  const createAnalysisAndNavigateToProcessDetails = async () => {
    const analysis = await submitAnalysis(
      analysisValues,
      undefined,
      { simulationHash: head(simulationResults).pkHash }
    )
    replace(`/processes/${analysis}`)
  };

  const downloadSimulationPdf = async result => {
    try {
      setIsLoading(true)

      const blob = await pdf(
        <SimulationPdf
          result={result}
          propertyPrice={analysisValues?.propertyPrice}
        />
      ).toBlob();
      saveAs(blob, `${result.pkHash}.pdf`);
    } catch (e) {
      Swal.fire({
        title: 'Erro ao gerar PDF',
        text: 'Tente novamente mais tarde',
        icon: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Loading isOpen={isLoading} />

      <Container fluid className="pt-5">
        <Row>
          <Col lg="4" md="12">
            <Form onSubmit={handleSubmit} />
          </Col>

          <Col lg="8" md="12">
            {simulationResults ? (
              <SimulationResult
                data={simulationResults}
                onSubmit={createAnalysisAndNavigateToProcessDetails}
                downloadSimulationPdf={downloadSimulationPdf}
              />
            ) : <EmptySimulation />}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Simulation
