import React from 'react'
import classNames from "classnames";

function FormFeedbackSelect({ children, invalid }) {
  return (
    <span className={classNames('invalid-feedback', { 'd-block': invalid })}>
      {children}
    </span>
  )
}

export default FormFeedbackSelect
